import React from 'react';
import { IconProps } from '../IconsList';

const VisibleIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.6849 8.99994C11.6849 10.4849 10.4849 11.6849 8.99994 11.6849C7.51494 11.6849 6.31494 10.4849 6.31494 8.99994C6.31494 7.51494 7.51494 6.31494 8.99994 6.31494C10.4849 6.31494 11.6849 7.51494 11.6849 8.99994Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.99988 15.2023C11.6474 15.2023 14.1149 13.6423 15.8324 10.9423C16.5074 9.88479 16.5074 8.10729 15.8324 7.04979C14.1149 4.34979 11.6474 2.78979 8.99988 2.78979C6.35238 2.78979 3.88488 4.34979 2.16738 7.04979C1.49238 8.10729 1.49238 9.88479 2.16738 10.9423C3.88488 13.6423 6.35238 15.2023 8.99988 15.2023Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default VisibleIcon;