import { gql } from "@apollo/client";

export const ALL_PAYMENTS_FIELDS_FRAGMENT = gql`
	fragment AllPaymentFields on PaymentSearch {
		id
		paymentAmount
		createdAt
		updatedAt
		description
		affiliateId
		userId
		paymentRequestId
		affiliateTitle
		userFullName
	}
`;
