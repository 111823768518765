import React from 'react';
import { IconProps } from '../IconsList';

const ReviewLessonIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.75 5.25037V12.7504C15.75 15.0004 14.625 16.5004 12 16.5004H6C3.375 16.5004 2.25 15.0004 2.25 12.7504V5.25037C2.25 3.00037 3.375 1.50037 6 1.50037H12C14.625 1.50037 15.75 3.00037 15.75 5.25037Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.6246 1.50037V7.39536C11.6246 7.72536 11.2346 7.89035 10.9946 7.67285L9.25462 6.06789C9.11212 5.93289 8.88708 5.93289 8.74458 6.06789L7.00462 7.67285C6.76462 7.89035 6.3746 7.72536 6.3746 7.39536V1.50037H11.6246Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.9379 10.5004H13.1254" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.75 13.5H13.125" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ReviewLessonIcon;