import { gql } from '@apollo/client';

const IS_REQUEST_READY = gql`
    mutation IsRequestReady(
        $id: ID!
    ) {
        isRequestReady(
            id: $id
        ) {
            task {
                id
                status
            }
            errors
        }
    }
`;

export default IS_REQUEST_READY;