import React from 'react';
import { IconProps } from '../IconsList';

const LockForgetIcon = (props: IconProps) => {

  //doesn't support color for now
  //TODO we can add support for color and bg color
  const { color = "#263238", className, width = 260, height = 249, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 260 249"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M260 135.145C260 202.438 197.379 249 130 249C62.6213 249 0 194.448 0 127.156C0 59.8629 73.6213 0.317741 141 0.317741C208.379 0.317741 260 67.8527 260 135.145Z" fill="#E0F6E4" />
      <path d="M173.906 97.194V111.613C170.881 111.201 167.444 110.995 163.594 110.926V97.194C163.594 75.5653 157.475 61.1463 127.5 61.1463C97.525 61.1463 91.4062 75.5653 91.4062 97.194V110.926C87.5563 110.995 84.1188 111.201 81.0938 111.613V97.194C81.0938 77.2819 85.9062 50.8469 127.5 50.8469C169.094 50.8469 173.906 77.2819 173.906 97.194Z" fill="#82C695" />
      <g opacity="0.5" filter="url(#filter0_f_1729_10290)">
        <path d="M173.906 121.6C170.881 121.188 167.444 120.982 163.594 120.914H91.4062C87.5562 120.982 84.1187 121.188 81.0938 121.6C63.5625 123.729 58.75 132.312 58.75 155.245V168.977C58.75 196.442 65.625 203.308 93.125 203.308H161.875C189.375 203.308 196.25 196.442 196.25 168.977V155.245C196.25 132.312 191.438 123.729 173.906 121.6ZM104.881 166.986C103.575 168.222 101.787 168.977 100 168.977C99.1063 168.977 98.2125 168.771 97.3875 168.428C96.4937 168.085 95.8063 167.604 95.1188 166.986C93.8812 165.681 93.125 163.896 93.125 162.111C93.125 161.218 93.3312 160.326 93.675 159.502C94.0187 158.678 94.5 157.923 95.1188 157.236C95.8063 156.618 96.4937 156.137 97.3875 155.794C99.9312 154.696 102.956 155.313 104.881 157.236C105.5 157.923 105.981 158.678 106.325 159.502C106.669 160.326 106.875 161.218 106.875 162.111C106.875 163.896 106.119 165.681 104.881 166.986ZM133.825 164.72C133.481 165.544 133 166.299 132.381 166.986C131.075 168.222 129.287 168.977 127.5 168.977C125.644 168.977 123.925 168.222 122.619 166.986C122 166.299 121.519 165.544 121.175 164.72C120.831 163.896 120.625 163.004 120.625 162.111C120.625 160.257 121.381 158.541 122.619 157.236C125.162 154.696 129.769 154.696 132.381 157.236C133.619 158.541 134.375 160.257 134.375 162.111C134.375 163.004 134.169 163.896 133.825 164.72ZM159.881 166.986C158.575 168.222 156.787 168.977 155 168.977C153.213 168.977 151.425 168.222 150.119 166.986C148.881 165.681 148.125 163.965 148.125 162.111C148.125 160.257 148.881 158.541 150.119 157.236C152.731 154.696 157.337 154.696 159.881 157.236C160.156 157.579 160.431 157.923 160.706 158.335C160.981 158.678 161.188 159.09 161.325 159.502C161.531 159.914 161.669 160.326 161.737 160.738C161.806 161.218 161.875 161.699 161.875 162.111C161.875 163.896 161.119 165.681 159.881 166.986Z" fill="#8BD598" />
      </g>
      <rect x="62.0322" y="144.327" width="128.806" height="46.7789" rx="20" fill="white" />
      <path d="M173.906 111.613C170.881 111.201 167.444 110.995 163.594 110.926H91.4062C87.5562 110.995 84.1187 111.201 81.0938 111.613C63.5625 113.742 58.75 122.324 58.75 145.258V158.99C58.75 186.455 65.625 193.321 93.125 193.321H161.875C189.375 193.321 196.25 186.455 196.25 158.99V145.258C196.25 122.324 191.438 113.742 173.906 111.613ZM104.881 156.999C103.575 158.235 101.787 158.99 100 158.99C99.1063 158.99 98.2125 158.784 97.3875 158.441C96.4937 158.097 95.8063 157.617 95.1188 156.999C93.8812 155.694 93.125 153.909 93.125 152.124C93.125 151.231 93.3312 150.339 93.675 149.515C94.0187 148.691 94.5 147.935 95.1188 147.249C95.8063 146.631 96.4937 146.15 97.3875 145.807C99.9312 144.708 102.956 145.326 104.881 147.249C105.5 147.935 105.981 148.691 106.325 149.515C106.669 150.339 106.875 151.231 106.875 152.124C106.875 153.909 106.119 155.694 104.881 156.999ZM133.825 154.733C133.481 155.557 133 156.312 132.381 156.999C131.075 158.235 129.287 158.99 127.5 158.99C125.644 158.99 123.925 158.235 122.619 156.999C122 156.312 121.519 155.557 121.175 154.733C120.831 153.909 120.625 153.016 120.625 152.124C120.625 150.27 121.381 148.553 122.619 147.249C125.162 144.708 129.769 144.708 132.381 147.249C133.619 148.553 134.375 150.27 134.375 152.124C134.375 153.016 134.169 153.909 133.825 154.733ZM159.881 156.999C158.575 158.235 156.787 158.99 155 158.99C153.213 158.99 151.425 158.235 150.119 156.999C148.881 155.694 148.125 153.978 148.125 152.124C148.125 150.27 148.881 148.553 150.119 147.249C152.731 144.708 157.337 144.708 159.881 147.249C160.156 147.592 160.431 147.935 160.706 148.347C160.981 148.691 161.188 149.103 161.325 149.515C161.531 149.927 161.669 150.339 161.737 150.751C161.806 151.231 161.875 151.712 161.875 152.124C161.875 153.909 161.119 155.694 159.881 156.999Z" fill="#8BD598" />
      <rect x="188.235" y="14.8874" width="25.5294" height="25.4968" fill="white" />
      <path d="M220.718 11.5758L196.067 3.85861C188.672 1.54344 182.196 4.92383 179.878 12.3096L175.242 27.0811C172.924 34.4668 176.308 40.9341 183.704 43.2493L182.058 48.4932C181.44 50.4627 183.263 52.3312 185.237 51.7327L198.494 47.8796L208.354 50.9665C215.749 53.2817 222.225 49.9013 224.543 42.5155L229.179 27.744C231.497 20.3583 228.113 13.891 220.718 11.5758ZM198.988 37.6787C197.953 37.3546 197.402 36.2629 197.719 35.2535C198.036 34.2441 199.112 33.6618 200.147 33.9859C201.183 34.31 201.733 35.4017 201.417 36.4111C201.1 37.4205 200.024 38.0029 198.988 37.6787ZM205.301 28.4342C204.139 28.7733 203.712 29.0992 203.495 29.7885L203.333 30.3055C203.016 31.3149 201.915 31.8896 200.905 31.5732C199.894 31.2568 199.319 30.1574 199.635 29.148L199.798 28.631C200.694 25.7751 203.23 25.0278 204.189 24.7331C205.294 24.4032 205.721 24.0773 205.922 23.4372C206.308 22.2062 205.614 20.8804 204.382 20.4946C203.149 20.1087 201.822 20.8017 201.435 22.0326C201.119 23.042 200.018 23.6167 199.007 23.3003C197.996 22.9839 197.421 21.8844 197.738 20.8751C198.766 17.6007 202.262 15.7753 205.541 16.8017C208.819 17.8281 210.647 21.3204 209.619 24.5948C208.739 27.4013 206.228 28.1564 205.301 28.4342Z" fill="#263238" />
      <defs>
        <filter id="filter0_f_1729_10290" x="38.75" y="100.914" width="177.5" height="122.395" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_1729_10290" />
        </filter>
      </defs>
    </svg>
  );
};

export default LockForgetIcon;