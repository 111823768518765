import EventEmitter from "events";

const RNEvents = new EventEmitter();

export const registerRNHandler = (
	action: string,
	callback: (payload: any) => void
) => {
	RNEvents.on(action, callback);
	return () => RNEvents.off(action, callback);
};

const onMessageFromRN = (message: string) => {
	const { action, payload } = JSON.parse(message);
	RNEvents.emit(action, payload);
};

// Attach the handler to `window` so we can access it from
// scripts injected by React Native WebView.
(window as any).onMessageFromRN = onMessageFromRN;
