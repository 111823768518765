import React from 'react';
import { IconProps } from '../IconsList';

const UnlockIcon = (props: IconProps) => {

  const { color = "#263038", className, width = 16, height = 16, size, extraColors = ["#FFC849", "#FFB22C"] } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.2189 3.70826L11.3553 4.20164L12.5792 3.86302L12.4427 3.36964C11.7678 0.928329 9.23226 -0.508471 6.79066 0.166729C4.34935 0.841929 2.91255 3.37749 3.58775 5.8188L4.70717 9.86593L5.93102 9.52731L4.8116 5.48018C4.32317 3.71378 5.36259 1.87902 7.12928 1.39058C8.89568 0.902147 10.7304 1.94157 11.2189 3.70826Z" fill={color} />
      <path d="M14.184 6.95651H1.81599C1.53772 6.95651 1.31213 7.1821 1.31213 7.46037V15.4961C1.31213 15.7744 1.53772 16 1.81599 16H14.184C14.4623 16 14.6878 15.7744 14.6878 15.4961V7.46037C14.6878 7.1821 14.4623 6.95651 14.184 6.95651Z" fill={extraColors[0]} />
      <path d="M14.6879 15.4961V10.5722C10.6131 14.0198 4.7379 15.3152 1.40845 15.7905C1.50008 15.9171 1.64787 16 1.81572 16H14.184C14.4621 16 14.6876 15.7745 14.6876 15.4964L14.6879 15.4961Z" fill={extraColors[1]} />
      <path d="M9.10532 10.1164C9.10532 9.50604 8.61048 9.01091 7.99986 9.01091C7.38925 9.01091 6.89441 9.50575 6.89441 10.1164C6.89441 10.4803 7.07099 10.802 7.34241 11.0036L6.9907 13.5031H9.00874L8.65703 11.0036C8.92845 10.8023 9.10503 10.4803 9.10503 10.1164H9.10532Z" fill={color} />
      <path d="M6.99097 13.5028C6.99097 13.5028 8.48886 13.0796 8.65729 11.0033L9.009 13.5028H6.99097Z" fill={color} />
    </svg>
  );
};

export default UnlockIcon;