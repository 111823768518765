import React from "react";
import { IconProps } from "../IconsList";

const GoogleIcon = (props: IconProps) => {
	const {
		//color = "#575D62",
		className,
		width = 21,
		height = 20,
		size,
	} = props;

	return (
		<svg
			className={className}
			width={size || width}
			height={size || height}
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.7277 11.7883L5.13098 14.0159L2.94998 14.062C2.29818 12.8531 1.92847 11.4699 1.92847 10C1.92847 8.57868 2.27414 7.23832 2.88686 6.05811H2.88733L4.82904 6.41409L5.67962 8.34414C5.50159 8.86315 5.40456 9.42029 5.40456 10C5.40463 10.6292 5.5186 11.2321 5.7277 11.7883Z"
				fill="#FBBB00"
			/>
			<path
				d="M18.9215 8.3988C19.0199 8.91731 19.0712 9.45279 19.0712 10.0001C19.0712 10.6137 19.0067 11.2123 18.8838 11.7897C18.4666 13.7546 17.3763 15.4703 15.8659 16.6844L15.8655 16.6839L13.4198 16.5591L13.0736 14.3984C14.0758 13.8106 14.859 12.8908 15.2716 11.7897H10.6882V8.3988H15.3385H18.9215Z"
				fill="#518EF8"
			/>
			<path
				d="M15.8655 16.6839L15.866 16.6844C14.3971 17.8651 12.5311 18.5715 10.4999 18.5715C7.23568 18.5715 4.39769 16.7471 2.94995 14.0621L5.72767 11.7883C6.45153 13.7202 8.31511 15.0954 10.4999 15.0954C11.439 15.0954 12.3188 14.8415 13.0737 14.3984L15.8655 16.6839Z"
				fill="#28B446"
			/>
			<path
				d="M15.9712 3.4019L13.1944 5.67521C12.4131 5.18684 11.4895 4.90472 10.5 4.90472C8.26579 4.90472 6.36734 6.34301 5.67975 8.34415L2.88743 6.05811H2.88696C4.31351 3.30771 7.18729 1.42859 10.5 1.42859C12.5798 1.42859 14.4867 2.16942 15.9712 3.4019Z"
				fill="#F14336"
			/>
		</svg>
	);
};

export default GoogleIcon;
