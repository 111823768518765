import i18n from "i18n-js";

// if English isn't your default language, move Translations to the appropriate language file.
import en, { Translations } from "./en";
import fa from "./fa";
// import ko from "./ko"

i18n.fallbacks = true;
/**
 * we need always include "*-US" for some valid language codes because when you change the system language,
 * the language code is the suffixed with "-US". i.e. if a device is set to English ("en"),
 * if you change to another language and then return to English language code is now "en-US".
 */
i18n.translations = { en, "en-US": en, "fa-IR": fa };

// i18n.locale = "fa-IR"; //Localization.locale

/**
 * Builds up valid keypaths for translations.
 */
export type TxKeyPath = RecursiveKeyOf<Translations>;

// via: https://stackoverflow.com/a/65333050
type RecursiveKeyOf<TObj extends Record<string, unknown>> = {
	[TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<
		TObj[TKey],
		`${TKey}`
	>;
}[keyof TObj & (string | number)];

type RecursiveKeyOfInner<TObj extends Record<string, unknown>> = {
	[TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<
		TObj[TKey],
		`['${TKey}']` | `.${TKey}`
	>;
}[keyof TObj & (string | number)];

type RecursiveKeyOfHandleValue<
	TValue,
	Text extends string
> = TValue extends any[]
	? Text
	: TValue extends Record<string, unknown>
	? Text | `${Text}${RecursiveKeyOfInner<TValue>}`
	: Text;
