import React from 'react';
import { IconProps } from '../IconsList';

const MusicIcon = (props: IconProps) => {

  const { color = "#5774CD", className, width = 16, height = 16, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.9268 3.45333V10.9867C13.9268 12.3067 12.8534 13.38 11.5334 13.38C10.2201 13.38 9.14009 12.3067 9.14009 10.9867C9.14009 9.67333 10.2201 8.59999 11.5334 8.59999C12.0934 8.59999 12.5934 8.79333 13.0001 9.11333V5.14666L6.86009 6.89333V12.2733C6.86009 13.5933 5.78009 14.6667 4.46676 14.6667C3.14676 14.6667 2.07343 13.5933 2.07343 12.2733C2.07343 10.96 3.14676 9.88666 4.46676 9.88666C5.02009 9.88666 5.52009 10.08 5.92676 10.3933V4.5C5.92676 3.52 6.52009 2.76 7.46009 2.50666L11.3134 1.45333C12.0934 1.24 12.7534 1.31333 13.2201 1.67333C13.6934 2.02666 13.9268 2.62666 13.9268 3.45333Z" fill={color} />
    </svg>
  );
};

export default MusicIcon;