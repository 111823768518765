import React from 'react';
import { IconProps } from '../IconsList';

const CheckEmailBigIcon = (props: IconProps) => {

  //doesn't support color for now
  //TODO we can add support for color and bg color
  const { color = "#58B368", className, width = 260, height = 249, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 260 249"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M260 135C260 202.379 197.379 249 130 249C62.6213 249 0 194.379 0 127C0 59.6213 73.6213 0 141 0C208.379 0 260 67.6213 260 135Z" fill="#E0F6E4" />
      <path d="M62.16 119.537L130.08 160.794L198 119.591V112.576C198 106.796 195.704 101.252 191.617 97.165C187.529 93.0778 181.986 90.7816 176.206 90.7816H84.2145C78.3653 90.7816 72.7557 93.1052 68.6196 97.2412C64.4836 101.377 62.16 106.987 62.16 112.836V119.537Z" fill="#82C695" />
      <path d="M163.921 55H94.0707C81.9203 55 72.0704 64.8499 72.0704 77.0003V163.115C72.0704 175.265 81.9203 185.115 94.0707 185.115H163.921C176.071 185.115 185.921 175.265 185.921 163.115V77.0003C185.921 64.8499 176.071 55 163.921 55Z" fill="#FEFEFE" />
      <path d="M148.502 99.4559C148.502 97.6126 151.061 95.3898 150.584 93.655C150.107 91.9201 146.8 91.2478 145.9 89.6865C145 88.1251 146.084 84.9156 144.816 83.6253C143.547 82.335 140.359 83.4518 138.776 82.541C137.193 81.6302 136.607 78.3122 134.797 77.8352C132.986 77.3581 130.85 79.9387 128.985 79.9387C127.12 79.9387 124.875 77.3364 123.184 77.7701C121.492 78.2038 120.788 81.5543 119.215 82.4759C117.643 83.3976 114.455 82.3241 113.165 83.5602C111.875 84.7963 112.992 88.0166 112.081 89.6214C111.17 91.2261 107.863 91.79 107.386 93.5899C106.909 95.3898 109.468 97.5042 109.468 99.3908C109.468 101.278 106.92 103.446 107.386 105.181C107.852 106.916 111.17 107.588 112.081 109.16C112.992 110.733 111.929 113.92 113.165 115.211C114.401 116.501 117.622 115.395 119.215 116.295C120.809 117.195 121.384 120.513 123.184 120.99C124.984 121.467 127.109 118.973 128.985 118.973C130.861 118.973 133.04 121.521 134.775 121.055C136.51 120.589 137.171 117.26 138.754 116.36C140.337 115.46 143.504 116.544 144.794 115.276C146.084 114.007 144.978 110.819 145.878 109.225C146.778 107.631 150.085 107.057 150.595 105.246C151.104 103.435 148.502 101.31 148.502 99.4559Z" fill="#263238" />
      <path d="M128.703 107.252L120.289 98.3065C120.158 98.0937 120.104 97.8421 120.138 97.5943C120.171 97.3465 120.288 97.1178 120.471 96.9469C120.653 96.7759 120.889 96.6733 121.139 96.6563C121.388 96.6393 121.636 96.709 121.839 96.8536L128.454 103.88L136.803 91.7466C136.983 91.5764 137.217 91.4733 137.464 91.4544C137.711 91.4355 137.958 91.502 138.162 91.6428C138.366 91.7836 138.516 91.9903 138.586 92.2282C138.657 92.4661 138.643 92.7209 138.548 92.9502L128.703 107.252Z" fill="white" />
      <g filter="url(#filter0_d_1231_272)">
        <path d="M130.08 154.289L198 113.085V177.416C198.003 180.277 197.442 183.111 196.349 185.755C195.256 188.399 193.653 190.802 191.631 192.826C189.609 194.85 187.207 196.456 184.564 197.551C181.921 198.647 179.088 199.211 176.227 199.211H83.9001C81.0477 199.218 78.2219 198.662 75.5846 197.576C72.9473 196.489 70.5503 194.893 68.5308 192.878C66.5114 190.864 64.9091 188.471 63.8159 185.836C62.7227 183.202 62.16 180.377 62.16 177.525V113.031L130.08 154.289Z" fill="#8BD598" />
      </g>
      <defs>
        <filter id="filter0_d_1231_272" x="42.16" y="103.031" width="175.84" height="126.18" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.545098 0 0 0 0 0.835294 0 0 0 0 0.596078 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1231_272" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1231_272" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default CheckEmailBigIcon;