import React from 'react';
import { IconProps } from '../IconsList';

const ArrowLeftIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.74987 3.05945L11.6399 7.94945C12.2174 8.52695 12.2174 9.47195 11.6399 10.0495L6.74987 14.9395" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowLeftIcon;