import { useEffect } from "react";
import { registerRNHandler } from "./react-native.init";
import {
	FromNativeActionName,
	FromNativeActions,
	FromWebActionName,
	FromWebActions,
} from "./webview-message-actions";

export const useRNHandler = <T extends FromNativeActionName>(
	action: T,
	callback: (payload: FromNativeActions[T]) => void
) => {
	useEffect(() => {
		const deregister = registerRNHandler(action, callback);
		return () => deregister();
	}, [action, callback]);
};

export const postMessageToRN = <T extends FromWebActionName>(
	action: T,
	payload: FromWebActions[T]
) => {
	const message = JSON.stringify({ action, payload });
	// Stringify the message a second time to escape quotes etc.
	const safeString = JSON.stringify(message);

	(window as any)?.ReactNativeWebView?.postMessage(safeString);
};
