import React from 'react';
import { IconProps } from '../IconsList';

const CourseIcon = (props: IconProps) => {

  const { color = "#BCBCBC", className, width = 26, height = 27, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23.8334 18.135V5.05917C23.8334 3.75917 22.7717 2.79501 21.4825 2.90335H21.4175C21.1575 2.92501 20.8746 2.95098 20.5834 3.01172C16.9688 3.76562 14.4409 5.04834 13.7584 5.47084L13.5742 5.58999C13.2601 5.78499 12.74 5.78499 12.4258 5.58999L12.155 5.42753C10.2267 4.22503 6.78166 3.07667 4.50666 2.8925C3.21749 2.78417 2.16669 3.75916 2.16669 5.04832V18.135C2.16669 19.175 3.01168 20.15 4.05168 20.28L4.36589 20.3234C6.71672 20.6375 10.3458 21.8292 12.4258 22.9667L12.4692 22.9883C12.7617 23.1508 13.2275 23.1508 13.5092 22.9883C15.5892 21.84 19.2292 20.6375 21.5908 20.3234L21.9484 20.28C22.9884 20.15 23.8334 19.175 23.8334 18.135Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13 5.94748V22.1975" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.25004 7.55048C6.58254 7.76714 8.16421 8.22209 9.58337 8.78543" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.25004 11.5505C6.58254 11.7671 8.16421 12.2221 9.58337 12.7854" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.25004 15.5505C6.58254 15.7671 8.16421 16.2221 9.58337 16.7854" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.5833 7.55048C19.2508 7.76714 17.6692 8.22209 16.25 8.78543" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.5833 11.5505C19.2508 11.7671 17.6692 12.2221 16.25 12.7854" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.5833 15.5505C19.2508 15.7671 17.6692 16.2221 16.25 16.7854" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CourseIcon;