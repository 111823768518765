import React from 'react';
import { IconProps } from '../IconsList';

const AddImageIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 24, height = 25, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 10.5C10.1046 10.5 11 9.60457 11 8.5C11 7.39543 10.1046 6.5 9 6.5C7.89543 6.5 7 7.39543 7 8.5C7 9.60457 7.89543 10.5 9 10.5Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 20.5 4 22.5 9 22.5H15C20 22.5 22 20.5 22 15.5V10.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.75 5.5H21.25" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M18.5 8.25V2.75" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M2.66992 19.4501L7.59992 16.1401C8.38992 15.6101 9.52992 15.6701 10.2399 16.2801L10.5699 16.5701C11.3499 17.2401 12.6099 17.2401 13.3899 16.5701L17.5499 13.0001C18.3299 12.3301 19.5899 12.3301 20.3699 13.0001L21.9999 14.4001" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default AddImageIcon;