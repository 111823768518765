import React from "react";
import { IconProps } from "../IconsList";

const AppleIcon = (props: IconProps) => {
	const {
		color = "#263038",
		className,
		width = 21,
		height = 20,
		size,
	} = props;

	return (
		<svg
			className={className}
			width={size || width}
			height={size || height}
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_3936_17519)">
				<path
					d="M13.7989 1.4292C13.8388 1.4292 13.8787 1.4292 13.9209 1.4292C14.0187 2.63807 13.5573 3.54133 12.9965 4.19544C12.4463 4.84504 11.6928 5.47506 10.4742 5.37947C10.3929 4.18791 10.855 3.35164 11.4151 2.69904C11.9344 2.09084 12.8866 1.54963 13.7989 1.4292Z"
					fill={color}
				/>
				<path
					d="M17.4879 14.0116C17.4879 14.0237 17.4879 14.0342 17.4879 14.0455C17.1454 15.0828 16.6569 15.9717 16.0608 16.7967C15.5165 17.5457 14.8496 18.5535 13.6588 18.5535C12.6299 18.5535 11.9464 17.8919 10.8918 17.8738C9.77631 17.8558 9.16285 18.4271 8.14291 18.5709C8.02624 18.5709 7.90957 18.5709 7.79516 18.5709C7.0462 18.4625 6.44177 17.8693 6.00143 17.3349C4.70299 15.7557 3.69961 13.7158 3.51294 11.1054C3.51294 10.8495 3.51294 10.5943 3.51294 10.3384C3.59197 8.47012 4.49975 6.95114 5.70636 6.21498C6.34316 5.82356 7.21857 5.49011 8.19335 5.63915C8.6111 5.70388 9.0379 5.8469 9.412 5.98841C9.76653 6.12465 10.2099 6.36627 10.6299 6.35348C10.9144 6.3452 11.1974 6.19691 11.4842 6.09228C12.3243 5.78894 13.1477 5.44118 14.2332 5.60452C15.5376 5.80173 16.4635 6.38133 17.0355 7.27556C15.932 7.97784 15.0596 9.03617 15.2087 10.8434C15.3412 12.4851 16.2956 13.4456 17.4879 14.0116Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_3936_17519">
					<rect
						width="17.1417"
						height="17.1417"
						fill="white"
						transform="translate(1.9292 1.4292)"
					/>
				</clipPath>
			</defs>{" "}
		</svg>
	);
};

export default AppleIcon;
