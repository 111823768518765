import React from 'react';
import { IconProps } from '../IconsList';

const RefreshIcon = (props: IconProps) => {

  const { color = "#F1AB22", className, width = 12, height = 13, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.001 6.50089C11.001 9.26089 8.76098 11.5009 6.00098 11.5009C3.24098 11.5009 1.55598 8.72089 1.55598 8.72089M1.55598 8.72089H3.81598M1.55598 8.72089V11.2209M1.00098 6.50089C1.00098 3.74089 3.22098 1.50089 6.00098 1.50089C9.33598 1.50089 11.001 4.28089 11.001 4.28089M11.001 4.28089V1.78089M11.001 4.28089H8.78098" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default RefreshIcon;