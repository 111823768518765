import React from 'react';
import { IconProps } from '../IconsList';

const MenuIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 24, height = 24, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 4.00024H21" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M3 11H21" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M3 17.9998H16" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default MenuIcon;