import React from 'react';
import { IconProps } from '../IconsList';

const EducationIcon = (props: IconProps) => {

  const { color = "#5774CD", className, width = 16, height = 16, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.50001 4V13.2733C7.50001 13.7467 7.02001 14.0733 6.58668 13.8933C5.34668 13.3733 3.80668 12.92 2.71334 12.7733L2.50668 12.7467C1.86001 12.6667 1.33334 12.0733 1.33334 11.4267V3.5C1.33334 2.7 1.98001 2.05333 2.78001 2.05333H2.82668C4.08001 2.16 5.92668 2.74666 7.14668 3.40666C7.36668 3.52666 7.50001 3.74666 7.50001 4Z" fill={color} />
      <path d="M9.33333 5.42982V11.7117C9.33333 12.0324 9.65849 12.2537 9.95204 12.1317C10.792 11.7795 11.8353 11.4724 12.5759 11.373L12.7159 11.355C13.154 11.3008 13.5107 10.8988 13.5107 10.4608V5.09111C13.5107 4.54918 13.0727 4.11111 12.5307 4.11111H12.4991C11.6501 4.18337 10.3991 4.58079 9.57268 5.02789C9.42365 5.10918 9.33333 5.25821 9.33333 5.42982Z" fill={color} />
      <path d="M13.22 2.05333H13.18C13.02 2.06666 12.8467 2.08666 12.6667 2.12C11.8467 2.25333 10.8733 2.53333 10 2.88C9.58667 3.04666 9.2 3.22666 8.86 3.40666C8.64 3.52666 8.5 3.75333 8.5 4V13.2733C8.5 13.7467 8.98 14.0733 9.41333 13.8933C10.6533 13.3733 12.1933 12.92 13.2867 12.7733L13.4933 12.7467C14.14 12.6667 14.6667 12.0733 14.6667 11.4267V3.5C14.6667 2.7 14.02 2.05333 13.22 2.05333ZM12.8467 9.15333C12.8467 9.41333 12.66 9.52666 12.4333 9.4L11.7267 9.00666C11.6533 8.96666 11.5333 8.96666 11.4533 9.00666L10.7467 9.4C10.52 9.52666 10.3333 9.41333 10.3333 9.15333V7.10666C10.3333 6.8 10.5867 6.54666 10.8933 6.54666H12.2933C12.6 6.54666 12.8533 6.8 12.8533 7.10666V9.15333H12.8467Z" fill={color} />
    </svg>
  );
};

export default EducationIcon;