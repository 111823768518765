import React from 'react';
import { IconProps } from '../IconsList';

const BoldIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 26, height = 27, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.25391 8.49984C8.25391 7.7665 8.85391 7.1665 9.58724 7.1665H13.0006C14.7472 7.1665 16.1672 8.5865 16.1672 10.3332C16.1672 12.0798 14.7472 13.4998 13.0006 13.4998H8.25391V8.49984Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.25391 13.5H14.5872C16.3339 13.5 17.7539 14.92 17.7539 16.6667C17.7539 18.4133 16.3339 19.8333 14.5872 19.8333H9.58724C8.85391 19.8333 8.25391 19.2333 8.25391 18.5V13.5V13.5Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default BoldIcon;