import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ReactNativeSetCurrentUrl = () => {

  const location = useLocation();

  useEffect(() => {
    if ((window as any).ReactNativeSetCurrentPageUrl) {
      (window as any).ReactNativeSetCurrentPageUrl(
        window.location.href
      );
    }
  }, [location]);


  return null;
};

export default ReactNativeSetCurrentUrl;