import React from 'react';
import { IconProps } from '../IconsList';

const LampIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 16, height = 17, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.00004 5.26025L7.28671 6.50025C7.12671 6.77359 7.26004 7.00025 7.57337 7.00025H8.42004C8.74004 7.00025 8.86671 7.22692 8.70671 7.50025L8.00004 8.74025" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.53137 12.0271V11.2538C3.99804 10.3271 2.73804 8.52044 2.73804 6.60044C2.73804 3.30044 5.77137 0.713776 9.19804 1.46044C10.7047 1.79378 12.0247 2.79378 12.7114 4.17378C14.1047 6.97378 12.638 9.94711 10.4847 11.2471V12.0204C10.4847 12.2138 10.558 12.6604 9.8447 12.6604H6.17137C5.43804 12.6671 5.53137 12.3804 5.53137 12.0271Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.66797 15.6663C7.19464 15.233 8.80797 15.233 10.3346 15.6663" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default LampIcon;