import React from 'react';
import { IconProps } from '../IconsList';

const ArrowCircleIcon = (props: IconProps) => {

  const { color = "#587DEE", className, width = 26, height = 26, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5.41626" y="8.66675" width="16.25" height="8.66667" fill="white"/>
      <path d="M13 0C5.824 0 0 5.824 0 13C0 20.176 5.824 26 13 26C20.176 26 26 20.176 26 13C26 5.824 20.176 0 13 0ZM17.55 13.975H10.803L13.039 16.211C13.416 16.588 13.416 17.212 13.039 17.589C12.844 17.784 12.597 17.875 12.35 17.875C12.103 17.875 11.856 17.784 11.661 17.589L7.761 13.689C7.384 13.312 7.384 12.688 7.761 12.311L11.661 8.411C12.038 8.034 12.662 8.034 13.039 8.411C13.416 8.788 13.416 9.412 13.039 9.789L10.803 12.025H17.55C18.083 12.025 18.525 12.467 18.525 13C18.525 13.533 18.083 13.975 17.55 13.975Z" fill={color}/>
    </svg>
  );
};

export default ArrowCircleIcon;