import clsx from "clsx";
import React from "react";
import css from "./View.module.css";

type Props = {
    children?: React.ReactNode;
    className?: string;
    onClick?: () => void;
};

const View = (props: Props) => {

    const { children, className, onClick } = props;

    return (
        <section onClick={onClick} className={clsx(css.view, className)}>
            {children}
        </section>
    );
};

export default View;