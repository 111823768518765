import { useReactiveVar } from "@apollo/client";
import React, { useEffect, useMemo } from "react";
import { userCurrentTotalStarsVar, userInfoVar } from "../../App";
import { IconWrapper } from "../../theme/icons/Icon";
import Text from "../text/Text";
import View from "../view/View";
import css from "./UserAchievementsCount.module.css";
import dayjs from "dayjs";
import { getCurrentStreakCount } from "../../helper/utilities";

const UserAchievementsCount = () => {
	const userInfo = useReactiveVar(userInfoVar);

	const streakDate = userInfo?.lastDailyGoalReached;

	const streakVal = userInfo?.currentStreak;

	const currentStreak = useMemo(
		() => getCurrentStreakCount(streakVal, streakDate),
		[streakDate, streakVal]
	);

	const totalStars = useReactiveVar(userCurrentTotalStarsVar);

	useEffect(() => {
		if ((window as any).ReactNativeSetAchievemensCount) {
			(window as any).ReactNativeSetAchievemensCount(
				totalStars,
				currentStreak
			);
		}
	}, [totalStars, currentStreak]);

	return (
		<View className={css.root}>
			<View className={css.stats}>
				<IconWrapper
					name="star"
					color="#FEA419"
					width={19}
					height={18}
				/>
				<Text type="custom" className={css.countText}>
					{totalStars || 0}
				</Text>
			</View>
			<View className={css.stats}>
				<IconWrapper name="streak" width={15} height={20} />
				<Text type="custom" className={css.countText}>
					{currentStreak}
				</Text>
			</View>
			{/*<View className={css.stats}>
                <IconWrapper name="gem" width={21} height={22} />
                <Text type="custom" className={css.countText}>
                    0
                </Text>
            </View>*/}
		</View>
	);
};

export default UserAchievementsCount;
