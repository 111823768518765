import { useReactiveVar } from "@apollo/client";
import { isRTLVar, userInfoVar } from "../App";
import React, { useEffect } from "react";
import I18n from "i18n-js";

export const CheckLanguageChange = () => {
	const userInfo = useReactiveVar(userInfoVar);

	useEffect(() => {
		if (userInfo?.nativeLanguage === "fa") {
			I18n.locale = "fa-IR";
			isRTLVar(true);
			import("../rtl-style.css");
		} //else {
		//I18n.locale = "en-US";
		//}
	}, [userInfo?.nativeLanguage]);

	return null;
};
