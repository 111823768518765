import { useMutation, useReactiveVar } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';
import {
  lessonIsProcessingVar,
  lessonIsProcessingDoneVar,
  lessonIsUploadingCancelVar,
  lessonUploadingInfoVar,
  lessonUploadingProgressVar,
  resetLessonCreation,
  lessonIsProcessingFailedVar,
  lessonNeedToRetryVar,
  lessonMainInfoVar,
  lessonFromUrlInfoVar
} from '../../../../App';
import { UploadLessonMediaArgs, UploadLessonMediaData, YoutubeLessonMediaArgs, YoutubeLessonMediaData } from '../../../../graphQL/lesson/LessonTypes';
import UPLOAD_LESSON_MEDIA from '../../../../graphQL/lesson/UploadLessonMedia';
import YOUTUBE_LESSON_MEDIA from '../../../../graphQL/lesson/YoutubeLessonMedia';
import IS_REQUEST_READY from '../../../../graphQL/task/IsRequestReady';
import { IsRequestReadyArgs, IsRequestReadyData, Task } from '../../../../graphQL/task/TaskTypes';
import useErrorNotification from '../../../../helper/hooks/useErrorNotification';

let abort: any;

let timer: NodeJS.Timeout;

const AddLessonInBackground = () => {

  const lessonIsUploadingCancel = useReactiveVar(lessonIsUploadingCancelVar);
  const lessonUploadingInfo = useReactiveVar(lessonUploadingInfoVar);
  const lessonFromUrlInfo = useReactiveVar(lessonFromUrlInfoVar);
  const lessonNeedToRetry = useReactiveVar(lessonNeedToRetryVar);

  const [checkRequestReady, { loading: loadingCheckReq }] = useMutation<
    IsRequestReadyData,
    IsRequestReadyArgs
  >(IS_REQUEST_READY);

  //handle sending requests for checking the proccess of creating
  //subtitle and video convertion
  const renderRequestReady = (mediaResult: {
    task?: Task;
    errors?: string[];
  }) => {
    if (mediaResult?.task?.id) {

      const request = () => {
        if (mediaResult?.task?.id) {
          checkRequestReady({
            //fetchPolicy: 'network-only',
            variables: {
              id: mediaResult?.task?.id
            },
            onCompleted({ isRequestReady }) {
              console.log("----isRequestReady---", isRequestReady);
              if (isRequestReady.task?.id && ["starting", "processing"].includes(isRequestReady.task?.status)) {

                if(timer) clearTimeout(timer);
                timer = setTimeout(() => {
                  request();
                }, 10000);
              } else if(isRequestReady.task?.id){
                const status = isRequestReady.task?.status;
                if(status === "done") {
                  lessonIsProcessingDoneVar(true);
                  lessonIsProcessingVar(false);

                  lessonMainInfoVar({
                    id: isRequestReady?.task?.id,
                    status: isRequestReady?.task?.status
                  });
                } else {
                  lessonIsProcessingFailedVar(true);
                }

                if (lessonNeedToRetry) {
                  lessonNeedToRetryVar(false);
                }
              } else {
                lessonIsProcessingFailedVar(true);
                if (lessonNeedToRetry) {
                  lessonNeedToRetryVar(false);
                }
              }

            },
          });
        }
      };

      lessonIsProcessingVar(true);
      if(timer) clearTimeout(timer);
      timer = setTimeout(() => {
        request();
      }, 10000);

    } else {
      lessonIsProcessingFailedVar(true);
      if (lessonNeedToRetry) {
        lessonNeedToRetryVar(false);
      }
    }
  };

  //uploading request
  const [addNewMedia, { loading, data }] = useMutation<
    UploadLessonMediaData,
    UploadLessonMediaArgs
  >(UPLOAD_LESSON_MEDIA, {
    onCompleted({ uploadLessonMedia }) {
      renderRequestReady(uploadLessonMedia);
    },
    onError() {
      lessonIsProcessingFailedVar(true);
      if (lessonNeedToRetry) {
        lessonNeedToRetryVar(false);
      }
    },
    context: {
      fetchOptions: {
        useUpload: true,
        onProgress: (ev: ProgressEvent) => {
          lessonUploadingProgressVar(ev.loaded / ev.total);
        },
        onAbortPossible: (abortHandler: any) => {
          abort = abortHandler;
        }
      }
    }
  });

  const dataForNotify = useMemo(() => data ? {
    result: !!data?.uploadLessonMedia?.task,
    errors: data?.uploadLessonMedia?.errors
  } : undefined, [data]);

  useErrorNotification(dataForNotify);
  
  const [addYoutubeMedia, { loading: yLoading, data: yData }] = useMutation<
    YoutubeLessonMediaData,
    YoutubeLessonMediaArgs
  >(YOUTUBE_LESSON_MEDIA, {
    onCompleted({ youtubeLessonMedia }) {
      renderRequestReady(youtubeLessonMedia);
    },
    onError() {
      lessonIsProcessingFailedVar(true);
      if (lessonNeedToRetry) {
        lessonNeedToRetryVar(false);
      }
    },
  });

  const yDataForNotify = useMemo(() => yData ? {
    result: !!yData?.youtubeLessonMedia?.task,
    errors: yData?.youtubeLessonMedia?.errors
  } : undefined, [yData]);

  useErrorNotification(yDataForNotify);

  useEffect(() => () => {
    if(timer) clearTimeout(timer);
  },[]);

  //cancel uploading
  useEffect(() => {
    if (lessonIsUploadingCancel && abort) {
      abort();
      resetLessonCreation();
    }
  }, [lessonIsUploadingCancel]);

  //start uploading
  useEffect(() => {
    if (lessonUploadingInfo.isStarted) {
      addNewMedia({
        variables: {
          file: lessonUploadingInfo.file as File,
          spaceType: "mediaForLessons",
          fileSize: (lessonUploadingInfo.file as File).size,
          languages: lessonUploadingInfo.languages
        }
      });
    }
  }, [lessonUploadingInfo]);

  //start adding media from url
  useEffect(() => {
    if (lessonFromUrlInfo.isStarted && lessonFromUrlInfo.url) {
      lessonIsProcessingVar(true);
      addYoutubeMedia({
        variables: {
          url: lessonFromUrlInfo.url,
          start: lessonFromUrlInfo.start,
          end: lessonFromUrlInfo.end,
          languages: lessonFromUrlInfo.languages
        }
      });
    }
  }, [lessonFromUrlInfo]);

  //retry
  useEffect(() => {
    if (lessonNeedToRetry) {
      //retry uploading
      if(lessonUploadingInfo.file) {
        addNewMedia({
          variables: {
            file: lessonUploadingInfo.file as File,
            spaceType: "mediaForLessons",
            fileSize: (lessonUploadingInfo.file as File).size,
            languages: lessonUploadingInfo.languages
          }
        });
      //retry adding from url - youtube  
      } else if(lessonFromUrlInfo.url){
        lessonIsProcessingVar(true);
        addYoutubeMedia({
          variables: {
            url: lessonFromUrlInfo.url,
            start: lessonFromUrlInfo.start,
            end: lessonFromUrlInfo.end,
            languages: lessonFromUrlInfo.languages
          }
        });
      }
    }
  }, [lessonNeedToRetry]);

  return null;

};

export default AddLessonInBackground;