import { useReactiveVar } from "@apollo/client";
import { List } from "antd-mobile";
import React from "react";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import { isRTLVar, userInfoVar } from "../../App";
import { APP_VERSION } from "../../config";
import useSignout from "../../graphQL/user/hooks/useSignout";
import { IconWrapper } from "../../theme/icons/Icon";
import css from "./Menu.module.css";
import { translate } from "../../i18n";
import { HandPayCircleOutline, CalendarOutline } from "antd-mobile-icons";
import { USER_SUBSCRIPTION_PREMIUM } from "../../graphQL/user/UserConstants";
import { useGoPremium } from "../../helper/hooks/useGoPremium";
import i18n from "i18n-js";

const Menu = () => {
	const userInfo = useReactiveVar(userInfoVar);
	const isAdmin = userInfo?.role === "admin";

	const history = useHistory();

	const { logout, logoutAll } = useSignout();

	const isRTL = useReactiveVar(isRTLVar);

	const goPremium = useGoPremium();

	const externalTosLink = {
		pathname: "https://metaora.app/term-of-services.html",
	};

	const tosLink =
		i18n.locale !== "fa-IR" ? externalTosLink : "/terms-of-service";

	const externalPLink = {
		pathname: "https://metaora.app/privacy-policy.html",
	};

	const privacyLink =
		i18n.locale !== "fa-IR" ? externalPLink : "/privacy-policy";

	return (
		<section className={css.root}>
			<div className={css.user}>
				<div
					onClick={() => history.push("/profile")}
					className={clsx(css.noImage, isRTL && css.noImageRtl)}
				>
					{userInfo?.avatar ? (
						<img
							className={css.avatar}
							src={userInfo.avatar}
							alt={userInfo.fullname}
						/>
					) : (
						<IconWrapper
							name="noUserIcon"
							size={40}
							color="#CFCFCF"
						/>
					)}
				</div>
				<div onClick={() => history.push("/profile")}>
					<div className={css.fullname}>{userInfo?.fullname}</div>
					{userInfo?.email &&
					!userInfo?.email.includes("@without-email.com") ? (
						<div className={css.email}>{userInfo?.email}</div>
					) : userInfo?.phoneNumber ? (
						<div style={{ direction: "ltr" }} className={css.email}>
							{userInfo?.phoneNumber}
						</div>
					) : null}
				</div>
			</div>

			<div className={css.content}>
				<List
					className="side-menu-list"
					style={{ "--border-inner": "none" }}
				>
					{/*<List.Item
            prefix={<div className={clsx(
									css.iconWrapper,
									isRTL && css.iconWrapperRtl
								)}><IconWrapper name="starFlat" size={17} color="#575D62" /></div>}
          >
            <div className={css.title}>Rate the app</div>
          </List.Item>*/}

					{isAdmin && (
						<List.Item
							prefix={
								<div
									className={clsx(
										css.iconWrapper,
										isRTL && css.iconWrapperRtl
									)}
								>
									<IconWrapper
										name="user"
										size={17}
										color="#575D62"
									/>
								</div>
							}
						>
							<div className={clsx(css.title, css.titleLink)}>
								<Link className={css.link} to="/admin/users">
									{translate("sideMenu.goToAdmin")}
								</Link>
							</div>
						</List.Item>
					)}

					{/*<List.Item
						prefix={
							<div
								className={clsx(
									css.iconWrapper,
									isRTL && css.iconWrapperRtl
								)}
							>
								<IconWrapper
									name="language"
									size={17}
									color="#575D62"
								/>
							</div>
						}
					>
						<div className={clsx(css.title, css.titleLink)}>
							<Link className={css.link} to="/dictionary">
								{translate("sideMenu.dictionary")}
							</Link>
						</div>
					</List.Item>*/}
					{/**
 * /help-center
/student-planning
/report-bug
/send-feedback
/privacy-policy
/terms-of-service
 */}
					<List.Item
						prefix={
							<div
								className={clsx(
									css.iconWrapper,
									isRTL && css.iconWrapperRtl
								)}
							>
								<IconWrapper
									name="home"
									size={17}
									color="#575D62"
								/>
							</div>
						}
					>
						<div className={clsx(css.title, css.titleLink)}>
							<Link className={css.link} to="/">
								{translate("sideMenu.home")}
							</Link>
						</div>
					</List.Item>

					<List.Item
						prefix={
							<div
								className={clsx(
									css.iconWrapper,
									isRTL && css.iconWrapperRtl
								)}
							>
								<IconWrapper
									name="courseList"
									size={17}
									color="#575D62"
								/>
							</div>
						}
					>
						<div className={clsx(css.title, css.titleLink)}>
							<Link className={css.link} to="/courses">
								{translate("sideMenu.courses")}
							</Link>
						</div>
					</List.Item>

					{i18n.locale === "fa-IR" && (
						<List.Item
							prefix={
								<div
									className={clsx(
										css.iconWrapper,
										isRTL && css.iconWrapperRtl
									)}
								>
									<HandPayCircleOutline
										style={{ fontSize: 20 }}
										color="#587DEE"
									/>
								</div>
							}
						>
							<div className={clsx(css.title, css.titleLink)}>
								<Link
									style={{ color: "#587DEE" }}
									className={css.link}
									to="/affiliate"
								>
									{translate("sideMenu.affiliate")}
								</Link>
							</div>
						</List.Item>
					)}

					<List.Item
						prefix={
							<div
								className={clsx(
									css.iconWrapper,
									isRTL && css.iconWrapperRtl
								)}
							>
								<IconWrapper
									name="happy"
									size={17}
									color="#575D62"
								/>
							</div>
						}
					>
						<div className={clsx(css.title, css.titleLink)}>
							<Link className={css.link} to="/review">
								{translate("sideMenu.review")}
							</Link>
						</div>
					</List.Item>

					<List.Item
						prefix={
							<div
								className={clsx(
									css.iconWrapper,
									isRTL && css.iconWrapperRtl
								)}
							>
								<IconWrapper
									name="lock"
									size={17}
									color="#f55c47"
								/>
							</div>
						}
						onClick={goPremium}
						arrowIcon={null}
						className="no-arrow"
					>
						<div className={css.title}>
							{userInfo?.subscription !==
							USER_SUBSCRIPTION_PREMIUM
								? translate("profile.metaOraPlus")
								: translate("profile.metaOraPlusRenew")}
						</div>
					</List.Item>

					{/*<List.Item
						prefix={
							<div
								className={clsx(
									css.iconWrapper,
									isRTL && css.iconWrapperRtl
								)}
							>
								<CalendarOutline
									style={{ fontSize: 18 }}
									color="#575D62"
								/>
							</div>
						}
					>
						<div className={clsx(css.title, css.titleLink)}>
							<Link className={css.link} to="/student-planning">
								{translate("sideMenu.planning")}
							</Link>
						</div>
					</List.Item>*/}

					<List.Item
						prefix={
							<div
								className={clsx(
									css.iconWrapper,
									isRTL && css.iconWrapperRtl
								)}
							>
								<IconWrapper
									name="chat"
									size={17}
									color="#575D62"
								/>
							</div>
						}
					>
						<div className={clsx(css.title, css.titleLink)}>
							<Link className={css.link} to={"/help-center"}>
								{translate("sideMenu.helpCenter")}
							</Link>
						</div>
					</List.Item>
					<List.Item
						prefix={
							<div
								className={clsx(
									css.iconWrapper,
									isRTL && css.iconWrapperRtl
								)}
							>
								<IconWrapper
									name="bug"
									size={17}
									color="#575D62"
								/>
							</div>
						}
					>
						<div className={clsx(css.title, css.titleLink)}>
							<Link className={css.link} to={"/report-bug"}>
								{translate("sideMenu.reportBug")}
							</Link>
						</div>
					</List.Item>
					<List.Item
						prefix={
							<div
								className={clsx(
									css.iconWrapper,
									isRTL && css.iconWrapperRtl
								)}
							>
								<IconWrapper
									name="like"
									size={17}
									color="#575D62"
								/>
							</div>
						}
					>
						<div className={clsx(css.title, css.titleLink)}>
							<Link className={css.link} to={"/send-feedback"}>
								{translate("sideMenu.sendFeedback")}
							</Link>
						</div>
					</List.Item>
					<List.Item
						prefix={
							<div
								className={clsx(
									css.iconWrapper,
									isRTL && css.iconWrapperRtl
								)}
							>
								<IconWrapper
									name="privacy"
									size={17}
									color="#575D62"
								/>
							</div>
						}
					>
						<div className={clsx(css.title, css.titleLink)}>
							<Link
								className={css.link}
								to={privacyLink}
								target={
									i18n.locale !== "fa-IR" ? "_blank" : "_self"
								}
							>
								{translate("sideMenu.policy")}
							</Link>
						</div>
					</List.Item>
					<List.Item
						prefix={
							<div
								className={clsx(
									css.iconWrapper,
									isRTL && css.iconWrapperRtl
								)}
							>
								<IconWrapper
									name="general"
									size={17}
									color="#575D62"
								/>
							</div>
						}
					>
						<div className={clsx(css.title, css.titleLink)}>
							<Link
								className={css.link}
								to={tosLink}
								target={
									i18n.locale !== "fa-IR" ? "_blank" : "_self"
								}
							>
								{translate("sideMenu.terms")}
							</Link>
						</div>
					</List.Item>
					<List.Item
						prefix={
							<div
								className={clsx(
									css.iconWrapper,
									isRTL && css.iconWrapperRtl
								)}
							>
								<IconWrapper
									name="exit"
									size={18}
									color="#F55C47"
								/>
							</div>
						}
						onClick={logout}
						className={clsx(css.logout, "no-arrow")}
						arrowIcon={null}
					>
						<div className={css.title}>
							{translate("sideMenu.logout")}
						</div>
					</List.Item>
					<List.Item
						arrowIcon={null}
						prefix={
							<div
								className={clsx(
									css.iconWrapper,
									isRTL && css.iconWrapperRtl
								)}
							>
								<IconWrapper
									name="exit"
									size={18}
									color="#F55C47"
								/>
							</div>
						}
						onClick={logoutAll}
						className={clsx(css.logout, "no-arrow")}
					>
						<div className={css.title}>
							{translate("sideMenu.logoutAll")}
						</div>
					</List.Item>
				</List>
			</div>

			<div className={css.appInfo}>
				<div className={css.appTitle}>
					{translate("sideMenu.slogan")}
				</div>
				<div className={css.appVersion}>
					{translate("sideMenu.version")} {APP_VERSION}
				</div>
			</div>
		</section>
	);
};

export default Menu;
