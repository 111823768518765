import { gql } from "@apollo/client";

export const INVITED_USER_FIELDS_FRAGMENT = gql`
	fragment InvitedUserFields on InvitedUser {
		userId
		fullName
		email
		phoneNumber
		referralCount
	}
`;
