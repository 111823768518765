import { gql } from "@apollo/client";

export const COUPON_FIELDS_FRAGMENT = gql`
	fragment CouponFields on Coupon {
		id
		title
		code
		discount
		discountType
		startDate
		expiryDate
		status
		maxUsageCount
		currentUsageCount
		createdAt
		updatedAt
	}
`;
