import React, { useState } from "react";
import { Affix } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { NavBar, Popup } from "antd-mobile";
import clsx from "clsx";
import { IconWrapper } from "./theme/icons/Icon";
import css from "./Header.module.css";
import Menu from "./components/menu/Menu";
import UiElement from "./components/uiElement/UiElement";
import { ClassNames } from "./types/general";
import UserAchievementsCount from "./components/userAchievementsCount/UserAchievementsCount";
import { isRTLVar } from "./App";
import { useReactiveVar } from "@apollo/client";

type Props = {
	parentPath?: string;
	children?: React.ReactNode;
	left?: React.ReactNode;
	right?: React.ReactNode;
	className?: string;
	//isFixed?: boolean;
	showNavBar?: boolean;
	showTopHeader?: boolean;
	showBackArrow?: boolean;
	backArrowReplacement?: React.ReactNode;
	mode?: "gradient" | "normal";
	backCallback?: () => void;
	showLogo?: boolean;
	showAvatar?: boolean;
} & ClassNames<"navbar">;

const Header: React.FC<Props> = (props) => {
	const {
		parentPath,
		children,
		left,
		right,
		className,
		//isFixed,
		showNavBar = true,
		showTopHeader = false,
		showBackArrow = true,
		backArrowReplacement,
		mode = "normal",
		backCallback,
		classNames,
		showLogo,
		showAvatar,
	} = props;

	const history = useHistory();
	const location = useLocation();
	const { pathname } = location;

	const isRnApp = localStorage.getItem("isRnApp");

	const isRTL = useReactiveVar(isRTLVar);

	const handleClick = () => {
		if (backCallback) {
			backCallback();
			return;
		}

		if (parentPath) {
			history.push(parentPath);
			return;
		}

		if (pathname !== "/") {
			history.goBack();
		}
	};

	let gapClsx = "";
	if (showTopHeader && showNavBar) {
		gapClsx = css.gapBig;
	} else if (showTopHeader) {
		gapClsx = css.gap;
	} else if (showNavBar) {
		gapClsx = css.gapSm;
	}

	const [menuVisible, setMenuVisible] = useState(false);

	const toggleVisibility = () => {
		setMenuVisible((prev) => !prev);
	};

	const isNewRNArchitect = false;
	//((window as any).isReactNativeApp &&
	//	(window as any).isReactNativeNewArchitectFlag) ||
	//isRnApp;
	return (
		<>
			<Affix offsetTop={0}>
				<header className={clsx("App-header", className)}>
					{showTopHeader && !isNewRNArchitect && (
						<section className={css.topHeader}>
							<button
								className={clsx(
									css.menuHamburger,
									isRTL && css.menuHamburgerRtl,
									"no-style-button"
								)}
								onClick={toggleVisibility}
							>
								<IconWrapper
									name="menu"
									color="#263238"
									size={24}
								/>
							</button>
							{showLogo && (
								<div className={css.logo}>
									<Link to="/">
										<IconWrapper name="logo" />
									</Link>
								</div>
							)}
							{showAvatar && (
								<div className={css.avatar}>
									<Link to="/profile">
										<IconWrapper name="avatar" />
									</Link>
								</div>
							)}
							<UserAchievementsCount />
						</section>
					)}
					{showNavBar && (
						<NavBar
							backArrow={
								backArrowReplacement ? (
									backArrowReplacement
								) : showBackArrow ? (
									<button
										onClick={handleClick}
										className={clsx(
											css.arrow,
											isRTL && css.arrowRtl,
											"no-style-button"
										)}
									>
										<IconWrapper
											name={
												isRTL
													? "arrowLeft"
													: "arrowRight"
											}
											size={20}
										/>
									</button>
								) : (
									false
								)
							}
							left={left}
							right={right}
							style={{
								"--border-bottom": "none",
								"--height": mode === "normal" ? "60px" : "40px",
							}}
							className={clsx(
								css[`${mode}Nav`],
								classNames?.navbar
							)}
						>
							<div
								style={{ direction: "ltr" }}
								className={clsx("text-limit", css.title)}
							>
								{children}
							</div>
						</NavBar>
					)}
				</header>
			</Affix>
			<Popup
				visible={menuVisible}
				onMaskClick={toggleVisibility}
				position={isRTL ? "right" : "left"}
				bodyClassName={clsx(css.popup, isRTL && css.popupRtl)}
			>
				<Menu />
			</Popup>
		</>
	);
};

export default Header;
