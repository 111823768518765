import React from "react";
import Text from "../../../components/text/Text";
import View from "../../../components/view/View";
import Button from "../../../theme/button/Button";
import { IconWrapper } from "../../../theme/icons/Icon";
import css from "./HomePremiumBanner.module.css";
import { translate } from "../../../i18n";
import { useGoPremium } from "../../../helper/hooks/useGoPremium";

const HomePremiumBanner = () => {
	const goPremium = useGoPremium();

	return (
		<View className={css.root} onClick={goPremium}>
			<View className={css.text}>
				<Text type="title" className={css.title}>
					{translate("premiumBanner.title")}
				</Text>
				<Text className={css.desc}>
					{translate("premiumBanner.subtitle")}
				</Text>
			</View>
			<Button className={css.actionBtn}>
				<IconWrapper name="crown" />
				<span className={css.btnText}>
					{translate("premiumBanner.btn")}
				</span>
			</Button>
		</View>
	);
};

export default HomePremiumBanner;
