import React from "react";
import { TabBar } from "antd-mobile";
import { useHistory, useLocation } from "react-router-dom";
import css from "./Footer.module.css";
import { IconWrapper } from "../../theme/icons/Icon";

const Footer = () => {
	const history = useHistory();
	const location = useLocation();
	const { pathname } = location;

	const setRouteActive = (value: string) => {
		history.push(value);
	};

	//const isRnApp = localStorage.getItem("isRnApp");

	const tabs = [
		{
			key: "/",
			title: "Home",
			icon: (
				<IconWrapper
					name="home"
					color={pathname === "/" ? "#FEA419" : "#BCBCBC"}
				/>
			),
		},
		{
			key: "/courses",
			title: "Courses",
			icon: (
				<IconWrapper
					name="courseList"
					color={pathname === "/courses" ? "#FEA419" : "#BCBCBC"}
				/>
			),
		},
		/*{
        key: '',
        title: '',
        icon: <MessageOutline />,
      },*/
		{
			key: "/review",
			title: "Review",
			icon: (
				<IconWrapper
					name="happy"
					color={pathname === "/review" ? "#FEA419" : "#BCBCBC"}
				/>
			),
		},
		{
			key: "/profile",
			title: "Profile",
			icon: (
				<IconWrapper
					name="userNew"
					color={pathname === "/profile" ? "#FEA419" : "#BCBCBC"}
				/>
			),
		},
	];

	//if(((window as any).isReactNativeApp && (window as any).isReactNativeNewArchitectFlag) || isRnApp) {
	//  return null;
	//}

	return (
		<section className={css.root}>
			<TabBar
				activeKey={pathname}
				onChange={(value) => setRouteActive(value)}
			>
				{tabs.map((item) => (
					<TabBar.Item key={item.key} icon={item.icon} />
				))}
			</TabBar>
		</section>
	);
};

export default Footer;
