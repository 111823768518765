import React from 'react';
import { IconProps } from '../IconsList';

const ImageIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 20, height = 20, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.50008 18.3332H12.5001C16.6667 18.3332 18.3334 16.6665 18.3334 12.4998V7.49984C18.3334 3.33317 16.6667 1.6665 12.5001 1.6665H7.50008C3.33341 1.6665 1.66675 3.33317 1.66675 7.49984V12.4998C1.66675 16.6665 3.33341 18.3332 7.50008 18.3332Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.49992 8.33309C8.42039 8.33309 9.16658 7.5869 9.16658 6.66642C9.16658 5.74595 8.42039 4.99976 7.49992 4.99976C6.57944 4.99976 5.83325 5.74595 5.83325 6.66642C5.83325 7.5869 6.57944 8.33309 7.49992 8.33309Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.22485 15.7916L6.33319 13.0332C6.99152 12.5916 7.94152 12.6416 8.53319 13.1499L8.80819 13.3916C9.45819 13.9499 10.5082 13.9499 11.1582 13.3916L14.6249 10.4166C15.2749 9.85822 16.3249 9.85822 16.9749 10.4166L18.3332 11.5832" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ImageIcon;