import React from 'react';
import { IconProps } from '../IconsList';

const UploadIcon = (props: IconProps) => {

  const { color = "#ABABAB", className, width = 22, height = 22, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.0813 10.1936C0.7913 10.4961 0.7913 16.7386 5.0813 17.0411H6.84134" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.12706 10.1935C2.18456 2.00767 14.5962 -1.26483 16.017 7.33351C19.9862 7.83767 21.5904 13.1268 18.5837 15.7577C17.6671 16.5918 16.4845 17.0502 15.247 17.041H15.1645" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.5394 13.57L10.9694 11L8.39941 13.57" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.9697 18.1977V11.072" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default UploadIcon;