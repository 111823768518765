import React from 'react';
import { IconProps } from '../IconsList';

const CharacterIcon = (props: IconProps) => {

  const { color = "white", className, width = 14, height = 14, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.124 2.88763C13.4398 5.20346 13.399 8.98344 11.0073 11.2526C8.7965 13.3468 5.209 13.3468 2.99234 11.2526C0.594836 8.98344 0.553995 5.20346 2.87566 2.88763C5.15066 0.606794 8.849 0.606794 11.124 2.88763Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.23856 9.37451C8.00189 10.5412 5.99524 10.5412 4.7644 9.37451" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default CharacterIcon;