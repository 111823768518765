import React from "react";
import { IconProps } from "../IconsList";

const HumanitiesIcon = (props: IconProps) => {
	const {
		color = "#5774CD",
		className,
		width = 16,
		height = 16,
		size,
	} = props;

	return (
		<svg
			className={className}
			width={size || width}
			height={size || height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.92408 8.55279C5.52979 8.1585 4.89051 8.1585 4.49622 8.55279C4.10193 8.94708 4.10193 9.58637 4.49622 9.98066L5.41442 10.899C5.56316 11.0477 5.56316 11.2888 5.41442 11.4376C5.26568 11.5863 5.02452 11.5863 4.87581 11.4376L4.87578 11.4375L3.24405 9.80579C3.17263 9.73437 3.13249 9.63748 3.13249 9.53647V6.30001C3.13249 5.84925 2.76798 5.48413 2.31661 5.48413H1.88159C1.67126 5.48413 1.50073 5.65465 1.50073 5.86499V10.7603C1.50073 10.8613 1.54085 10.9582 1.6123 11.0296L3.94839 13.3657V14.4318C3.94839 14.6421 4.11891 14.8126 4.32925 14.8126H7.18483C7.39517 14.8126 7.56569 14.6421 7.56569 14.4318V11.1972C7.56569 10.5507 7.31392 9.9428 6.85681 9.48572L5.92408 8.55279Z"
				fill={color}
			/>
			<path
				d="M14.1185 5.48413H13.6834C13.2321 5.48413 12.8676 5.84927 12.8676 6.30001V9.53647C12.8676 9.63748 12.8274 9.73434 12.756 9.80579L11.1242 11.4375L11.1242 11.4376C10.9755 11.5863 10.7344 11.5863 10.5856 11.4376C10.4369 11.2888 10.4369 11.0477 10.5856 10.899L11.5038 9.98066C11.8981 9.58637 11.8981 8.94708 11.5038 8.55279C11.1095 8.1585 10.4702 8.1585 10.0759 8.55279L9.14321 9.48572C8.6861 9.9428 8.43433 10.5507 8.43433 11.1972V14.4318C8.43433 14.6421 8.60485 14.8126 8.81519 14.8126H11.6708C11.8811 14.8126 12.0516 14.6421 12.0516 14.4318V13.3657L14.3877 11.0296C14.4592 10.9582 14.4993 10.8613 14.4993 10.7603V5.86499C14.4993 5.65465 14.3288 5.48413 14.1185 5.48413Z"
				fill={color}
			/>
			<path
				d="M7.99555 4.19154C8.82516 4.19154 9.49769 3.519 9.49769 2.6894C9.49769 1.85979 8.82516 1.18726 7.99555 1.18726C7.16594 1.18726 6.49341 1.85979 6.49341 2.6894C6.49341 3.519 7.16594 4.19154 7.99555 4.19154Z"
				fill={color}
			/>
			<path
				d="M5.63086 6.44501C5.63086 5.20045 6.63978 4.19153 7.88434 4.19153H8.1156C9.36016 4.19153 10.3691 5.20045 10.3691 6.44501C10.3691 6.85986 10.0328 7.19617 9.61792 7.19617H6.38202C5.96717 7.19617 5.63086 6.85986 5.63086 6.44501Z"
				fill={color}
			/>
		</svg>
	);
};

export default HumanitiesIcon;
