import React from 'react';
import { IconProps } from '../IconsList';

const PauseIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.0874 14.3325V3.6675C7.0874 2.655 6.6599 2.25 5.5799 2.25H2.8574C1.7774 2.25 1.3499 2.655 1.3499 3.6675V14.3325C1.3499 15.345 1.7774 15.75 2.8574 15.75H5.5799C6.6599 15.75 7.0874 15.345 7.0874 14.3325Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.6499 14.3325V3.6675C16.6499 2.655 16.2224 2.25 15.1424 2.25H12.4199C11.3474 2.25 10.9124 2.655 10.9124 3.6675V14.3325C10.9124 15.345 11.3399 15.75 12.4199 15.75H15.1424C16.2224 15.75 16.6499 15.345 16.6499 14.3325Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PauseIcon;