import React from 'react';
import { IconProps } from '../IconsList';

const PlusIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 12, height = 13, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.42855 6.5H2.57141" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 9.92868V3.07153" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PlusIcon;