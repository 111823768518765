import { gql } from '@apollo/client';

const YOUTUBE_LESSON_MEDIA = gql`
    mutation YoutubeLessonMedia(
        $url: String!
        $start: String
        $end: String
        $languages: [String!]!
    ) {
        youtubeLessonMedia(
            url: $url
            start: $start
            end: $end
            languages: $languages
        ) {
            task {
                id
                status
            }
            errors
        }
    }
`;

export default YOUTUBE_LESSON_MEDIA;