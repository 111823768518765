import React from 'react';
import { IconProps } from '../IconsList';

const GemIcon = (props: IconProps) => {

  const { color = "#33B6FF", className, width = 26, height = 27, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M2.86772 7.53208C6.24678 12.6506 9.62585 17.7691 13.0049 22.8889C16.3803 17.7704 19.7556 12.6518 23.1322 7.53208C22.5947 6.39175 22.0585 5.25266 21.521 4.11234C15.8391 4.11603 10.1572 4.11973 4.47527 4.12466C3.93901 5.26006 3.40398 6.39669 2.86772 7.53208Z" fill={color} />
      <path d="M13.0047 22.8889L17.4513 7.5321H8.54694L13.0035 22.8889H13.0047Z" fill={color} />
      <path d="M13.005 22.8889L17.4516 7.5321H23.1311C19.7557 12.6506 16.3804 17.7691 13.0038 22.8889H13.005Z" fill="#55C2FF" />
      <path d="M13.0049 22.8889L8.54713 7.5321H2.86769C6.24675 12.6506 9.62582 17.7691 13.0049 22.8889Z" fill="#55C2FF" />
      <path d="M8.54716 7.53208C9.08835 6.39669 9.62831 5.26006 10.1695 4.12466C8.27225 4.11973 6.37499 4.11603 4.47774 4.11234L2.86772 7.53208H8.54716Z" fill="#7DD7FF" />
      <path d="M17.4516 7.53209C16.9104 6.39546 16.3704 5.26007 15.8292 4.12344C17.7265 4.11974 19.6237 4.11605 21.521 4.11111L23.131 7.53086H17.4516V7.53209Z" fill="#7DD7FF" />
      <path d="M8.54694 7.53211C9.08813 6.39672 9.62809 5.26009 10.1693 4.12469H15.829C16.3702 5.26132 16.9101 6.39672 17.4513 7.53334H8.54694V7.53211Z" fill="#5AC4FF" />
    </svg>
  );
};

export default GemIcon;