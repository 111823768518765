import React from 'react';
import { IconProps } from '../IconsList';

const ChatIcon = (props: IconProps) => {

  //doesn't support color for now
  //TODO we can add support for color and bg color
  const { color = "#292D32", className, width = 260, height = 249, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.8534 12.6223L14.1459 14.9922C14.2209 15.6147 13.5534 16.0497 13.0209 15.7272L9.87836 13.8597C9.53336 13.8597 9.19587 13.8373 8.86587 13.7923C9.42087 13.1398 9.75086 12.3147 9.75086 11.4222C9.75086 9.29224 7.90586 7.56728 5.62586 7.56728C4.75586 7.56728 3.95337 7.81476 3.28587 8.24976C3.26337 8.06226 3.25586 7.87475 3.25586 7.67975C3.25586 4.26725 6.21836 1.49976 9.87836 1.49976C13.5384 1.49976 16.5009 4.26725 16.5009 7.67975C16.5009 9.70475 15.4584 11.4973 13.8534 12.6223Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.75196 11.4226C9.75196 12.3151 9.42196 13.1401 8.86696 13.7926C8.12446 14.6926 6.94695 15.2701 5.62695 15.2701L3.66945 16.4326C3.33945 16.6351 2.91945 16.3576 2.96445 15.9751L3.15195 14.4976C2.14695 13.8001 1.50195 12.6826 1.50195 11.4226C1.50195 10.1026 2.20696 8.94011 3.28696 8.25011C3.95446 7.81511 4.75695 7.56763 5.62695 7.56763C7.90695 7.56763 9.75196 9.29259 9.75196 11.4226Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default ChatIcon;