import { ErrorBlock } from "antd-mobile";
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { onlyPremiumAccessVar } from "../../App";
import Header from "../../Header";
import Footer from "../footer/Footer";
import { translate } from "../../i18n";
import HomePremiumBanner from "../../containers/home/premium-banner/HomePremiumBanner";

const UserPremiumPage = () => {
	const history = useHistory();

	useEffect(() => {
		// To start listening for location changes...
		const unlisten = history.listen(() => {
			// The current location changed.
			onlyPremiumAccessVar(false);
		});

		return () => {
			// Later, when you are done listening for changes...
			unlisten();
		};
	}, []);

	return (
		<article>
			<Header>{translate("userPremiumPage.pageTitle")}</Header>
			<div style={{ padding: "0 20px" }}>
				<ErrorBlock
					title={translate("userPremiumPage.title")}
					description={translate("userPremiumPage.accessMsg")}
					fullPage
				/>
			</div>
			<section style={{ marginTop: "40px" }}>
				<HomePremiumBanner />
			</section>
			<div
				style={{
					paddingTop: "30px",
					textAlign: "center",
					fontSize: 16,
				}}
			>
				<Link to="/">{translate("userPremiumPage.backToHome")}</Link>
			</div>
			<Footer />
		</article>
	);
};

export default UserPremiumPage;
