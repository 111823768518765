import clsx from "clsx";
import React from "react";
import css from "./Text.module.css";

type Props = {
    children: React.ReactNode;
    className?: string;
    type?: "normal" | "custom" | "title"
};

const Text = (props: Props) => {

    const { children, className, type = "normal" } = props;

    return (
        <div className={clsx(
            className, 
            type === "normal" && css.text,
            type === "title" && css.title
        )}>
            {children}
        </div>
    );
};

export default Text;