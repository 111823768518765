import React from 'react';
import { IconProps } from '../IconsList';

const ArrowNextIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 22, height = 22, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.2275 5.43579L18.7917 11L13.2275 16.5641" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.20825 11H18.6358" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowNextIcon;