import { gql } from '@apollo/client';

const SIGN_OUT = gql`
    mutation SignOut {
        signOut {
            result
        }
    }
`;

export default SIGN_OUT;