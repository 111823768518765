import React from 'react';
import { IconProps } from '../IconsList';

const UserNewIcon = (props: IconProps) => {

  const { color = "#BCBCBC", className, width = 26, height = 26, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.9999 13C15.7959 13 18.0624 10.7335 18.0624 7.93751C18.0624 5.14156 15.7959 2.875 12.9999 2.875C10.204 2.875 7.93741 5.14156 7.93741 7.93751C7.93741 10.7335 10.204 13 12.9999 13Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.03125 21.0405C5.03125 18.2774 7.27115 16.0375 10.0342 16.0375H15.997C18.7601 16.0375 21 18.2774 21 21.0405V21.0405C21 22.1917 20.0667 23.125 18.9154 23.125H7.11582C5.96454 23.125 5.03125 22.1917 5.03125 21.0405V21.0405Z" stroke={color} strokeWidth="1.5" />
    </svg>
  );
};

export default UserNewIcon;