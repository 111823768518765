import { useQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { userCurrentTotalStarsVar } from "../../../App";
//import useErrorNotification from "../../../helper/hooks/useErrorNotification";
import { UserTotalStarsData } from "../UserLessonStatsTypes";
import USER_TOTAL_STARS from "../UserTotalStars";

const useUserTotalStars = () => {
	const { loading, data } = useQuery<UserTotalStarsData>(USER_TOTAL_STARS, {
		fetchPolicy: "cache-and-network",
	});

	/*const dataForNotify = useMemo(
		() =>
			data
				? {
						result: !!data?.userTotalStars.totalStars,
						errors: data?.userTotalStars.errors,
				  }
				: undefined,
		[data]
	);

	// useErrorNotification(dataForNotify);*/

	const totalStars = data?.userTotalStars.totalStars;

	useEffect(() => {
		userCurrentTotalStarsVar(totalStars);
	}, [totalStars]);

	return {
		loading,
		totalStars,
	};
};

export default useUserTotalStars;
