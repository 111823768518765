import React from 'react';
import { IconProps } from '../IconsList';

const AdsIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 20, height = 21, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.7746 4.612L17.0355 3.35119C17.2653 3.12133 17.2653 2.74869 17.0355 2.51884C16.8057 2.28899 16.433 2.28903 16.2031 2.51884L14.9423 3.77966C14.7124 4.00947 14.7124 4.38215 14.9423 4.612C15.1721 4.84182 15.5447 4.8419 15.7746 4.612Z" fill="#FF7396" />
      <path d="M18.5104 7.38975H17.2495C16.9245 7.38975 16.6609 7.65327 16.6609 7.97831C16.6609 8.30335 16.9245 8.56687 17.2495 8.56687H18.5104C18.8354 8.56687 19.0989 8.30335 19.0989 7.97831C19.0989 7.65327 18.8354 7.38975 18.5104 7.38975Z" fill="#FF7396" />
      <path d="M11.576 2.89313C11.901 2.89313 12.1645 2.62961 12.1645 2.30457V1.04375C12.1645 0.718704 11.901 0.455185 11.576 0.455185C11.2509 0.455185 10.9874 0.718704 10.9874 1.04375V2.30457C10.9874 2.62961 11.2509 2.89313 11.576 2.89313Z" fill="#FF7396" />
      <path d="M1.07315 15.1271C0.843492 15.3568 0.843296 15.7297 1.07315 15.9595L3.59483 18.4812C3.8246 18.711 4.19728 18.7111 4.42717 18.4812L5.41345 17.4949L2.05946 14.1409L1.07315 15.1271Z" fill="#FF7396" />
      <mask id="mask0_3070_7423" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="14" width="6" height="5">
        <path d="M1.07315 15.1271C0.843492 15.3568 0.843296 15.7297 1.07315 15.9595L3.59483 18.4812C3.8246 18.711 4.19728 18.7111 4.42717 18.4812L5.41345 17.4949L2.05946 14.1409L1.07315 15.1271Z" fill="#FB54F4" />
      </mask>
      <g mask="url(#mask0_3070_7423)">
        <rect x="2.18448" y="17.37" width="15.7662" height="6.51587" transform="rotate(-45 2.18448 17.37)" fill="#FF7396" />
      </g>
      <path d="M11.2632 15.6024L7.1304 17.5472L9.95562 20.3724C10.1854 20.6022 10.5581 20.6023 10.788 20.3724L12.9944 18.166C13.2243 17.9361 13.2243 17.5635 12.9944 17.3336L11.2632 15.6024Z" fill="#FF7396" />
      <path d="M8.83996 2.51884C8.70443 2.38331 8.51138 2.3223 8.32257 2.35522C8.13376 2.38818 7.97285 2.51099 7.89124 2.68442L2.89163 13.3085L6.24562 16.6625L16.8698 11.6629C17.0432 11.5813 17.166 11.4204 17.199 11.2316C17.2319 11.0428 17.1709 10.8497 17.0353 10.7142L8.83996 2.51884Z" fill="#FF7396" />
      <mask id="mask1_3070_7423" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="2" y="2" width="16" height="15">
        <path d="M8.83996 2.51886C8.70443 2.38334 8.51138 2.32232 8.32257 2.35524C8.13376 2.3882 7.97285 2.51101 7.89124 2.68444L2.89163 13.3085L6.24562 16.6626L16.8698 11.663C17.0432 11.5814 17.166 11.4204 17.199 11.2316C17.2319 11.0428 17.1709 10.8498 17.0353 10.7142L8.83996 2.51886Z" fill="#FB54F4" />
      </mask>
      <g mask="url(#mask1_3070_7423)">
        <rect x="2.18443" y="17.37" width="15.7662" height="6.51587" transform="rotate(-45 2.18443 17.37)" fill="#FF7396" />
      </g>
    </svg>
  );
};

export default AdsIcon;