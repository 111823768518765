import React from 'react';
import { IconProps } from '../IconsList';

const KeyIcon = (props: IconProps) => {

  const { color = "#575D62", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.75049 16.4998H11.2505C15.0005 16.4998 16.5005 14.9998 16.5005 11.2498V6.74982C16.5005 2.99982 15.0005 1.49982 11.2505 1.49982H6.75049C3.00049 1.49982 1.50049 2.99982 1.50049 6.74982V11.2498C1.50049 14.9998 3.00049 16.4998 6.75049 16.4998Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.2099 10.2074C11.3624 11.0549 10.1474 11.3174 9.0749 10.9799L7.1324 12.9149C6.9974 13.0574 6.7199 13.1474 6.5174 13.1174L5.6174 12.9974C5.3174 12.9599 5.0474 12.6749 5.0024 12.3824L4.8824 11.4824C4.8524 11.2874 4.9499 11.0099 5.0849 10.8674L7.0199 8.93239C6.6899 7.85989 6.9449 6.64489 7.7924 5.79739C9.0074 4.58239 10.9874 4.58239 12.2099 5.79739C13.4249 7.00489 13.4249 8.98489 12.2099 10.2074Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.83818 12.2102L7.20068 11.5652" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.0452 8.02472H10.0519" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default KeyIcon;