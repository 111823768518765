import React from 'react';
import { IconProps } from '../IconsList';

const PlayIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 14, height = 16, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00031 8.00029V5.33029C1.00031 2.01529 3.34781 0.657788 6.2203 2.31529L8.5378 3.65029L10.8553 4.98529C13.7278 6.64279 13.7278 9.35779 10.8553 11.0153L8.5378 12.3503L6.2203 13.6853C3.34781 15.3428 1.00031 13.9853 1.00031 10.6703V8.00029Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlayIcon;