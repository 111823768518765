import { gql } from "@apollo/client";

export const SUBSCRIPTION_PLAN_FIELDS_FRAGMENT = gql`
	fragment SubscriptionPlanFields on SubscriptionPlan {
		id
		name
		title
		subtitle
		amount
		duration
		unit
		description
		image {
			id
			filename
			filepath
			type
			mimetype
			title
		}
		createdAt
		updatedAt
		status
		order
		isTrialPlan
	}
`;
