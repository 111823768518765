import {
	ApolloCache,
	gql,
	NormalizedCacheObject,
	useQuery,
} from "@apollo/client";
import localforage from "localforage";
import {
	SingleVideoDownloadInfo,
	VideoDownloadCompleted,
	VideoDownloaderStatus,
	VideosDownloadInfo,
} from "./video-downloader.types";
import { useEffect } from "react";
import produce from "immer";

export const VIDEOS_DOWNLOAD_INFO = "videosDownloadInfo";

export const GET_VIDEOS_DOWNLOAD_INFO = gql`
	query GetVideosDownloadInfo {
		list @client
	}
`;

export type GetVideosDownloadInfoData = {
	list: VideosDownloadInfo;
};

export async function initializeVideosDownloadInfo(
	cache: ApolloCache<NormalizedCacheObject>
) {
	const value = await localforage.getItem<VideosDownloadInfo>(
		VIDEOS_DOWNLOAD_INFO
	);

	cache.writeQuery({
		query: GET_VIDEOS_DOWNLOAD_INFO,
		data: {
			list: [...(value || [])],
		},
	});
}

export function useVideosDownloadInfo() {
	const { data, client } = useQuery(GET_VIDEOS_DOWNLOAD_INFO);

	console.log("----data---", data);

	const saveInfoInCache = async (
		newVideosInfoData: GetVideosDownloadInfoData
	) => {
		console.log("---saveInfoInCache--", newVideosInfoData);

		try {
			client.cache.writeQuery({
				query: GET_VIDEOS_DOWNLOAD_INFO,
				data: newVideosInfoData,
			});

			// Persist the updated video information to localforage
			await localforage.setItem(
				VIDEOS_DOWNLOAD_INFO,
				newVideosInfoData.list
			);
		} catch (error) {
			console.log("we couldn't save video information");
			console.log(error);
		}
	};

	// when we start downloading
	const addVideoInfo = async (info: SingleVideoDownloadInfo) => {
		const currData = client.readQuery({
			query: GET_VIDEOS_DOWNLOAD_INFO,
		});

		const initialData = {
			list: [],
		};

		const newVideosInfoData = produce(
			currData || initialData,
			(draftData: GetVideosDownloadInfoData) => {
				draftData.list.push({
					...info,
				});
			}
		);

		saveInfoInCache(newVideosInfoData);
	};

	// when downloading the video is in progress, we need to update the video's progress
	const updateVideoProgress = async (id: string, progress: number) => {
		const currData = client.readQuery({
			query: GET_VIDEOS_DOWNLOAD_INFO,
		});

		const initialData = {
			list: [],
		};

		const newVideosInfoData = produce(
			currData || initialData,
			(draftData: GetVideosDownloadInfoData) => {
				draftData.list = draftData.list.map(
					(video: SingleVideoDownloadInfo) => {
						if (
							video.id === id &&
							video.status === VideoDownloaderStatus.InProgress
						) {
							video.progress = progress;
						}

						return video;
					}
				);
			}
		);

		saveInfoInCache(newVideosInfoData);
	};

	// when downloading the video is failed, we need to remove the video info
	const removeVideosInfo = async (ids: string[]) => {
		const currData = client.readQuery({
			query: GET_VIDEOS_DOWNLOAD_INFO,
		});

		const initialData = {
			list: [],
		};

		const newVideosInfoData = produce(
			currData || initialData,
			(draftData: GetVideosDownloadInfoData) => {
				draftData.list = draftData.list.filter(
					(video: SingleVideoDownloadInfo) => !ids.includes(video.id)
				);
			}
		);

		saveInfoInCache(newVideosInfoData);
	};

	// when the app is closed we remove the in progress ones
	const removeInProgressVideos = async () => {
		const currData = client.readQuery({
			query: GET_VIDEOS_DOWNLOAD_INFO,
		});

		const initialData = {
			list: [],
		};

		const newVideosInfoData = produce(
			currData || initialData,
			(draftData: GetVideosDownloadInfoData) => {
				draftData.list = draftData.list.filter(
					(video: SingleVideoDownloadInfo) =>
						video.status !== VideoDownloaderStatus.InProgress
				);
			}
		);

		saveInfoInCache(newVideosInfoData);
	};

	// when downloading the video is completed, we need to update the video's status
	const completeVideoDownload = async (id: string, uri: string) => {
		const currData = client.readQuery({
			query: GET_VIDEOS_DOWNLOAD_INFO,
		});

		const initialData = {
			list: [],
		};

		const newVideosInfoData = produce(
			currData || initialData,
			(draftData: GetVideosDownloadInfoData) => {
				draftData.list = draftData.list.map(
					(video: SingleVideoDownloadInfo) => {
						if (
							video.id === id &&
							video.status === VideoDownloaderStatus.InProgress
						) {
							const { progress, ...rest } = video;

							const newVideo: VideoDownloadCompleted = {
								...rest,
								uri: uri,
								status: VideoDownloaderStatus.Completed,
							};

							return newVideo;
						}

						return video;
					}
				);
			}
		);

		saveInfoInCache(newVideosInfoData);
	};

	return {
		videos: (data?.list || []) as VideosDownloadInfo,
		addVideoInfo,
		updateVideoProgress,
		removeVideosInfo,
		completeVideoDownload,
		removeInProgressVideos,
	};
}

export function useRemoveInProgressVideosOnAppClose() {
	const { removeInProgressVideos } = useVideosDownloadInfo();

	useEffect(() => {
		return () => {
			removeInProgressVideos();
		};
	}, []);
}
