import React from 'react';
import { IconProps } from '../IconsList';

const RotateIcon = (props: IconProps) => {

    const { color = "#263238", className, width = 16, height = 16, size } = props;

    return (
        <svg
            className={className}
            width={size || width}
            height={size || height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M6.07379 3.38754C6.65379 3.21421 7.29379 3.10088 8.00046 3.10088C11.1938 3.10088 13.7805 5.68754 13.7805 8.88088C13.7805 12.0742 11.1938 14.6609 8.00046 14.6609C4.80713 14.6609 2.22046 12.0742 2.22046 8.88088C2.22046 7.69421 2.58046 6.58754 3.19379 5.66754" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.24634 3.54605L7.173 1.33272" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.24634 3.54688L7.493 5.18687" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default RotateIcon;