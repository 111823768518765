import React from 'react';
import { IconProps } from '../IconsList';

const PlaybackSpeedIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.3019 14.3032C17.2344 11.3708 17.2344 6.62324 14.3019 3.69824" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.69742 3.69824C0.764922 6.63074 0.764922 11.3783 3.69742 14.3032" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.5246 16.0574C7.3271 16.3349 8.15959 16.4699 8.99959 16.4699C9.83959 16.4624 10.6721 16.3349 11.4746 16.0574" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.5246 1.94281C7.3271 1.66531 8.15959 1.53027 8.99959 1.53027C9.83959 1.53027 10.6721 1.66531 11.4746 1.94281" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.55457 8.99913V7.74664C6.55457 6.18664 7.65708 5.54913 9.00708 6.32913L10.0946 6.95915L11.1821 7.58912C12.5321 8.36912 12.5321 9.64415 11.1821 10.4241L10.0946 11.0541L9.00708 11.6841C7.65708 12.4641 6.55457 11.8266 6.55457 10.2666V8.99913Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PlaybackSpeedIcon;

