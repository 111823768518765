import React from 'react';
import { IconProps } from '../IconsList';

const BookFillIcon = (props: IconProps) => {

  const { color = "#58B368", className, width = 28, height = 28, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M25.6667 5.65844V19.5301C25.6667 20.6618 24.745 21.7001 23.6133 21.8401L23.2517 21.8868C21.3383 22.1434 18.6433 22.9368 16.4733 23.8468C15.715 24.1618 14.875 23.5901 14.875 22.7618V6.53344C14.875 6.10177 15.12 5.7051 15.505 5.4951C17.64 4.3401 20.8717 3.31344 23.065 3.12677H23.135C24.535 3.12677 25.6667 4.25844 25.6667 5.65844Z" fill={color} />
      <path d="M12.4956 5.4951C10.3606 4.3401 7.12893 3.31344 4.9356 3.12677H4.85393C3.45393 3.12677 2.32227 4.25844 2.32227 5.65844V19.5301C2.32227 20.6618 3.24393 21.7001 4.3756 21.8401L4.73727 21.8868C6.6506 22.1434 9.3456 22.9368 11.5156 23.8468C12.2739 24.1618 13.1139 23.5901 13.1139 22.7618V6.53344C13.1139 6.0901 12.8806 5.7051 12.4956 5.4951ZM5.83393 9.0301H8.45893C8.93727 9.0301 9.33393 9.42677 9.33393 9.9051C9.33393 10.3951 8.93727 10.7801 8.45893 10.7801H5.83393C5.3556 10.7801 4.95893 10.3951 4.95893 9.9051C4.95893 9.42677 5.3556 9.0301 5.83393 9.0301ZM9.33393 14.2801H5.83393C5.3556 14.2801 4.95893 13.8951 4.95893 13.4051C4.95893 12.9268 5.3556 12.5301 5.83393 12.5301H9.33393C9.81227 12.5301 10.2089 12.9268 10.2089 13.4051C10.2089 13.8951 9.81227 14.2801 9.33393 14.2801Z" fill={color} />
    </svg>
  );
};

export default BookFillIcon;