import React from 'react';
import { IconProps } from '../IconsList';

const MessageRemoveIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 20, height = 21, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.3325 6.69132V11.6913C18.3325 12.9413 17.9158 13.983 17.1825 14.7163C16.4575 15.4413 15.4158 15.858 14.1658 15.858V17.633C14.1658 18.2997 13.4242 18.6997 12.8742 18.333L9.16583 15.858H7.39917C7.46584 15.608 7.49916 15.3497 7.49916 15.083C7.49916 14.233 7.17417 13.4497 6.64083 12.858C6.04083 12.1747 5.14917 11.7497 4.16583 11.7497C3.2325 11.7497 2.3825 12.133 1.77417 12.758C1.69917 12.4247 1.66583 12.0663 1.66583 11.6913V6.69132C1.66583 4.19132 3.3325 2.52466 5.8325 2.52466H14.1658C16.6658 2.52466 18.3325 4.19132 18.3325 6.69132Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.50083 15.0833C7.50083 15.35 7.4675 15.6083 7.40084 15.8583C7.32584 16.1917 7.19251 16.5167 7.01751 16.8C6.44251 17.7667 5.38417 18.4167 4.1675 18.4167C3.30917 18.4167 2.53416 18.0916 1.95083 17.5583C1.70083 17.3416 1.48416 17.0833 1.31749 16.8C1.00916 16.3 0.834167 15.7083 0.834167 15.0833C0.834167 14.1833 1.1925 13.3584 1.77584 12.7584C2.38417 12.1334 3.23417 11.75 4.1675 11.75C5.15083 11.75 6.0425 12.175 6.6425 12.8583C7.17583 13.45 7.50083 14.2333 7.50083 15.0833Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.06104 15.95L3.29437 14.1917" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.04303 14.2168L3.27637 15.9751" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.08417 9.25H12.9175" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default MessageRemoveIcon;