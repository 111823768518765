import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const RouteWrapper = (props: { children: React.ReactNode }) => {
	const { children } = props;

	const history = useHistory();

	useEffect(() => {
		// To start listening for location changes...
		const unlisten = history.listen((location, action) => {
			// The current location changed.
			console.log("----location---", location);
			console.log("Route changed: " + location.pathname);
			if ((window as any).ReactNativeSetCurrentRoutePath) {
				(window as any).ReactNativeSetCurrentRoutePath(
					location.pathname
				);
			}
		});

		return () => {
			// Later, when you are done listening for changes...
			unlisten();
		};
	}, [history]);

	return <>{children}</>;
};

export default RouteWrapper;
