import { gql } from "@apollo/client";

export const AFFILIATE_BASIC_FIELDS_FRAGMENT = gql`
	fragment AffiliateBasicFields on AffiliateBasic {
		id
		user
		title
		code
		createdAt
	}
`;
