import React from 'react';
import { IconProps } from '../IconsList';

const NativeLanguageIcon = (props: IconProps) => {

  const { color = "#575D62", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.7428 6.72026H5.25781" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 5.45993V6.71992" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.874 6.705C10.874 9.93 8.35402 12.54 5.24902 12.54" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.7494 12.54C11.3994 12.54 10.1994 11.82 9.33691 10.6875" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.75098 16.4996H11.251C15.001 16.4996 16.501 14.9996 16.501 11.2496V6.74957C16.501 2.99957 15.001 1.49957 11.251 1.49957H6.75098C3.00098 1.49957 1.50098 2.99957 1.50098 6.74957V11.2496C1.50098 14.9996 3.00098 16.4996 6.75098 16.4996Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default NativeLanguageIcon;