import React from "react";
import { CurrentUser } from "../../graphQL/user/UserTypes";
import { userInfoVar } from "../../App";

let timeout: NodeJS.Timeout;

const useReactNativeUserSync = () => {
	const [rnNewUserInfoSync, setRnNewUserInfoSync] = React.useState<
		| (CurrentUser & {
				time: string;
		})
		| undefined
	>();

	React.useEffect(() => {
		if (timeout) {
			clearInterval(timeout);
		}

		timeout = setInterval(() => {
			if (
				(window as any).ReactNativeInjectedUser &&
				rnNewUserInfoSync?.time !==
					(window as any).ReactNativeInjectedUser?.time
			) {
				const currentUserInfo = {
					...((window as any).ReactNativeInjectedUser || {}),
				};

				localStorage.setItem(
					"currentUserInfo",
					JSON.stringify(currentUserInfo)
				);

				userInfoVar(currentUserInfo);

				setRnNewUserInfoSync(currentUserInfo);
			}
		}, 100);

		return () => {
			clearInterval(timeout);
		};
	}, [rnNewUserInfoSync?.time]);

	return null;
};

export default useReactNativeUserSync;
