import React from 'react';
import { IconProps } from '../IconsList';

const BookCloseIcon = (props: IconProps) => {

  const { color = "#575D62", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.62549 13.4998V5.2498C2.62549 2.2498 3.37549 1.4998 6.37549 1.4998H11.6255C14.6255 1.4998 15.3755 2.2498 15.3755 5.2498V12.7498C15.3755 12.8548 15.3755 12.9598 15.368 13.0648" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.76299 11.25H15.3755V13.875C15.3755 15.3225 14.198 16.5 12.7505 16.5H5.25049C3.80299 16.5 2.62549 15.3225 2.62549 13.875V13.3875C2.62549 12.21 3.58549 11.25 4.76299 11.25Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.00049 5.2502H12.0005" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.00049 7.875H9.75049" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default BookCloseIcon;