import React from 'react';
import { IconProps } from '../IconsList';

const ReviewIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 26, height = 27, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 23.5H16C21 23.5 23 21.5 23 16.5V10.5C23 5.5 21 3.5 16 3.5H10C5 3.5 3 5.5 3 10.5V16.5C3 21.5 5 23.5 10 23.5Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18 3.93994V13.9199C18 15.8899 16.59 16.6599 14.86 15.6199L13.54 14.8299C13.24 14.6499 12.76 14.6499 12.46 14.8299L11.14 15.6199C9.41 16.6499 8 15.8899 8 13.9199V3.93994" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 23.5H16C21 23.5 23 21.5 23 16.5V10.5C23 5.5 21 3.5 16 3.5H10C5 3.5 3 5.5 3 10.5V16.5C3 21.5 5 23.5 10 23.5Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18 3.93994V13.9199C18 15.8899 16.59 16.6599 14.86 15.6199L13.54 14.8299C13.24 14.6499 12.76 14.6499 12.46 14.8299L11.14 15.6199C9.41 16.6499 8 15.8899 8 13.9199V3.93994" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ReviewIcon;