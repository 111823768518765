import React from 'react';
import { IconProps } from '../IconsList';

const CrownIcon = (props: IconProps) => {

  const { color = "white", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M1.86837 3.55966L5.25831 6.04011C5.33632 6.09719 5.44412 6.0898 5.51363 6.02259L8.80942 2.83605C8.88582 2.76219 9.00681 2.76155 9.08398 2.83458L12.4557 6.02557C12.5251 6.09129 12.6315 6.09822 12.7088 6.04205L16.1322 3.55734C16.2635 3.46205 16.4475 3.55584 16.4475 3.71807V15.0212C16.4475 15.1309 16.3586 15.2198 16.2489 15.2198H1.75109C1.64141 15.2198 1.55249 15.1309 1.55249 15.0212V3.71993C1.55249 3.55735 1.73716 3.46365 1.86837 3.55966ZM4.43706 12.4934C4.16113 12.4934 3.93745 12.7171 3.93745 12.993C3.93745 13.2689 4.16113 13.4926 4.43706 13.4926H13.4927C13.7687 13.4926 13.9923 13.2689 13.9923 12.993C13.9923 12.7171 13.7687 12.4934 13.4927 12.4934H4.43706Z" fill={color} />
    </svg>
  );
};

export default CrownIcon;