/**
 * @PublicRoute Component
 * Only guests users access to this page. signed users will be redirect to dashboard.
 */
import { useReactiveVar } from '@apollo/client';
import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { userInfoVar } from '../App';
import RouteWrapper from './RouteWrapper';

type Props = {
    component: any;
    componentProps?: React.ComponentProps<any>;
} & RouteProps;

const PublicRoute = (props: Props) => {

    const {
        component: Component,
        componentProps,
        ...rest
    } = props;

    const userInfo = useReactiveVar(userInfoVar);

    const isAuth = !!(userInfo && userInfo.token && userInfo.id);

    const cmpProps = componentProps || {};

    return (
        <Route
            {...rest}
            render={props =>
                !isAuth ? (
                    <RouteWrapper>
                        <Component {...props} {...cmpProps} />
                    </RouteWrapper>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};

export default PublicRoute;
