import { gql } from "@apollo/client";

export const ALL_PAYMENT_REQUEST_FIELDS_FRAGMENT = gql`
	fragment AllPaymentRequestsFields on AllPaymentRequest {
		id
		affiliateTitle
		affiliateBankInfo {
			bankName
			IBANNumber
			cardNumber
			accountHolder
		}
		userFullName
		userId
		affiliateId
		requestedAmount
		status
		createdAt
		updatedAt
	}
`;
