import React from 'react';
import { IconProps } from '../IconsList';

const PronunciationFillIcon = (props: IconProps) => {

  const { color = "#58B368", className, width = 28, height = 28, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="7" y="7" width="13.125" height="10.5" fill={color} />
      <path d="M19.8323 2.33362H8.16561C4.94561 2.33362 2.33228 4.93528 2.33228 8.14362V15.1203V16.2869C2.33228 19.4953 4.94561 22.0969 8.16561 22.0969H9.91561C10.2306 22.0969 10.6506 22.3069 10.8489 22.5636L12.5989 24.8853C13.3689 25.9119 14.6289 25.9119 15.3989 24.8853L17.1489 22.5636C17.3706 22.2719 17.7206 22.0969 18.0823 22.0969H19.8323C23.0523 22.0969 25.6656 19.4953 25.6656 16.2869V8.14362C25.6656 4.93528 23.0523 2.33362 19.8323 2.33362ZM18.2689 16.9519C18.0939 17.1269 17.8723 17.2086 17.6506 17.2086C17.4289 17.2086 17.2073 17.1269 17.0323 16.9519L16.1689 16.0886C15.4923 16.5319 14.6756 16.8003 13.8006 16.8003C11.4206 16.8003 9.48394 14.8636 9.48394 12.4836C9.48394 10.1036 11.4089 8.16695 13.8006 8.16695C16.1923 8.16695 18.1173 10.1036 18.1173 12.4836C18.1173 13.3586 17.8489 14.1753 17.4056 14.8519L18.2689 15.7153C18.6073 16.0536 18.6073 16.6136 18.2689 16.9519Z" fill={color} />
      <path d="M16.6569 7.875C18.9477 10.842 18.9477 14.6992 16.6569 17.6662L16.6569 7.875Z" fill={color} />
      <path d="M16.6569 7.875C18.9477 10.842 18.9477 14.6992 16.6569 17.6662" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.2634 9.17236C14.947 11.3459 14.947 14.1818 13.2634 16.3554L13.2634 9.17236Z" fill={color} />
      <path d="M13.2634 9.17236C14.947 11.3459 14.947 14.1818 13.2634 16.3554" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.86156 10.9731C10.7034 12.0634 10.7034 13.4779 9.86156 14.5681L9.86156 10.9731Z" fill={color} />
      <path d="M9.86156 10.9731C10.7034 12.0634 10.7034 13.4779 9.86156 14.5681" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PronunciationFillIcon;