import React from 'react';
import { IconProps } from '../IconsList';

const NotificationIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 20, height = 21, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.0167 2.92493C7.25841 2.92493 5.01674 5.16659 5.01674 7.92493V10.3333C5.01674 10.8416 4.80007 11.6166 4.54174 12.0499L3.58341 13.6416C2.99174 14.6249 3.40007 15.7166 4.48341 16.0833C8.07507 17.2833 11.9501 17.2833 15.5417 16.0833C16.5501 15.7499 16.9917 14.5583 16.4417 13.6416L15.4834 12.0499C15.2334 11.6166 15.0167 10.8416 15.0167 10.3333V7.92493C15.0167 5.17493 12.7667 2.92493 10.0167 2.92493Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M11.5582 3.1667C11.2999 3.0917 11.0332 3.03337 10.7582 3.00003C9.95819 2.90003 9.19152 2.95837 8.47485 3.1667C8.71652 2.55003 9.31652 2.1167 10.0165 2.1167C10.7165 2.1167 11.3165 2.55003 11.5582 3.1667Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.5166 16.3833C12.5166 17.7583 11.3916 18.8833 10.0166 18.8833C9.33327 18.8833 8.69993 18.6 8.24993 18.15C7.79993 17.7 7.5166 17.0666 7.5166 16.3833" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" />
    </svg>
  );
};

export default NotificationIcon;