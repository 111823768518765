import React from 'react';
import { IconProps } from '../IconsList';

const LatestCoursesIcon = (props: IconProps) => {

  const { color = "#F7F7F7", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.66767 4.40936H3.83264C2.55014 4.40936 1.50015 5.45935 1.50015 6.74185V15.2619C1.50015 16.3494 2.28015 16.8144 3.23265 16.2819L6.18015 14.6394C6.49515 14.4669 7.00516 14.4669 7.31266 14.6394L10.2602 16.2819C11.2127 16.8144 11.9927 16.3494 11.9927 15.2619V6.74185C12.0002 5.45935 10.9502 4.40936 9.66767 4.40936Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.0002 6.74185V15.2619C12.0002 16.3494 11.2202 16.8069 10.2677 16.2819L7.32016 14.6394C7.00516 14.4669 6.49515 14.4669 6.18015 14.6394L3.23265 16.2819C2.28015 16.8069 1.50015 16.3494 1.50015 15.2619V6.74185C1.50015 5.45935 2.55014 4.40936 3.83264 4.40936H9.66767C10.9502 4.40936 12.0002 5.45935 12.0002 6.74185Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.5002 3.83267V12.3527C16.5002 13.4402 15.7202 13.8977 14.7677 13.3727L12.0002 11.8277V6.74268C12.0002 5.46018 10.9502 4.41019 9.66767 4.41019H6.00015V3.83267C6.00015 2.55017 7.05014 1.50018 8.33264 1.50018H14.1677C15.4502 1.50018 16.5002 2.55017 16.5002 3.83267Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default LatestCoursesIcon;