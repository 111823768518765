import React from 'react';
import { IconProps } from '../IconsList';

const EditIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 12, height = 13, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.63017 2.30011L2.52517 6.64511C2.37017 6.81011 2.22017 7.13511 2.19017 7.36011L2.00517 8.98011C1.94017 9.56511 2.36017 9.96511 2.94017 9.86511L4.55017 9.59011C4.77517 9.55011 5.09017 9.38511 5.24517 9.21511L9.35017 4.87011C10.0602 4.12011 10.3802 3.26511 9.27517 2.22011C8.17517 1.18511 7.34017 1.55011 6.63017 2.30011Z" stroke={color} strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.94507 3.02515C6.16007 4.40515 7.28007 5.46015 8.67007 5.60015" stroke={color} strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.5 11.5005H10.5" stroke={color} strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default EditIcon;