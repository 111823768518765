import React from 'react';
import { IconProps } from '../IconsList';

const RepeatIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.5002 2.25L12.3302 4.00502L6.36768 3.99C3.69018 3.99 1.49268 6.18752 1.49268 8.88002C1.49268 10.2225 2.04017 11.445 2.92517 12.33" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.49992 15.7502L5.66992 13.9951L11.6324 14.0102C14.3099 14.0102 16.5074 11.8126 16.5074 9.12015C16.5074 7.77765 15.9599 6.55517 15.0749 5.67017" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.75 9H11.25" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default RepeatIcon;