import React from "react";
import { IconProps } from "../IconsList";

const SMSIcon = (props: IconProps) => {
	const {
		color = "#292D32",
		className,
		width = 20,
		height = 20,
		size,
	} = props;

	return (
		<svg
			className={className}
			width={size || width}
			height={size || height}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.167 2.9165H5.83366C3.33366 2.9165 1.66699 4.1665 1.66699 7.08317V12.9165C1.66699 15.8332 3.33366 17.0832 5.83366 17.0832H14.167C16.667 17.0832 18.3337 15.8332 18.3337 12.9165V7.08317C18.3337 4.1665 16.667 2.9165 14.167 2.9165ZM14.5587 7.9915L11.9503 10.0748C11.4003 10.5165 10.7003 10.7332 10.0003 10.7332C9.30032 10.7332 8.59199 10.5165 8.05032 10.0748L5.44199 7.9915C5.17533 7.77484 5.13366 7.37484 5.34199 7.10817C5.55866 6.8415 5.95033 6.7915 6.21699 7.00817L8.82532 9.0915C9.45866 9.59984 10.5337 9.59984 11.167 9.0915L13.7753 7.00817C14.042 6.7915 14.442 6.83317 14.6503 7.10817C14.867 7.37484 14.8253 7.77484 14.5587 7.9915Z"
				fill={color}
			/>
		</svg>
	);
};

export default SMSIcon;
