import React from 'react';
import { IconProps } from '../IconsList';

const QuestionMarkIcon = (props: IconProps) => {

  const { color = "#BDC0C3", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="6.75" stroke={color} strokeWidth="1.5"/>
      <path d="M9.00381 10.1478V9.94496C9.00381 9.28796 9.40963 8.94013 9.81542 8.65994C10.2116 8.38941 10.6076 8.0416 10.6076 7.40393C10.6076 6.51505 9.89269 5.80005 9.00381 5.80005C8.11493 5.80005 7.39999 6.51505 7.39999 7.40393" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.9994 12.4572H9.00809" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default QuestionMarkIcon;