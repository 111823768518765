import { Button as AntdButton } from "antd-mobile";
import clsx from "clsx";
import React, { ComponentProps } from "react";
import { IconWrapper, IconName } from "../icons/Icon";
import css from "./Button.module.css";
import { useReactiveVar } from "@apollo/client";
import { isRTLVar } from "../../App";

type Props = {
	hasArrowRight?: boolean;
	emptySpan?: boolean;
	color?: "info" | "default" | "primary" | "success" | "warning" | "danger";
	hasShadow?: boolean;
} & Omit<ComponentProps<typeof AntdButton>, "color">;

const Button = (props: Props) => {
	const {
		emptySpan = true,
		children,
		color,
		size,
		hasArrowRight,
		className,
		hasShadow = true,
		...other
	} = props;

	const isRTL = useReactiveVar(isRTLVar);

	return (
		<AntdButton
			className={clsx(
				css.root,
				color && css[color],
				size && css[size],
				hasShadow && css.hasShadow,
				className
			)}
			color={color === "info" ? "default" : color}
			size={size}
			{...other}
		>
			{emptySpan ? <span /> : null}
			{children}
			{hasArrowRight ? (
				<div
					className={css.iconWrapper}
					style={isRTL ? { transform: "rotate(180deg)" } : {}}
				>
					<IconWrapper name="arrowNext" size={22} color="#ffffff" />
				</div>
			) : (
				<span />
			)}
		</AntdButton>
	);
};

export default Button;
