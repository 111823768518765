import React from 'react';
import { IconProps } from '../IconsList';

const CourseListIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 26, height = 27, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.3701 10.38H18.6201" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <circle cx="8.80005" cy="17.4" r="1.5" fill={color}/>
      <circle cx="8.80005" cy="10.4" r="1.5" fill={color}/>
      <path d="M13.3701 17.38H18.6201" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 23.5H16C21 23.5 23 21.5 23 16.5V10.5C23 5.5 21 3.5 16 3.5H10C5 3.5 3 5.5 3 10.5V16.5C3 21.5 5 23.5 10 23.5Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default CourseListIcon;