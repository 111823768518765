export enum VideoDownloaderType {
	LessonVideo = "lessonVideo",
	ShortVideo = "shortVideo",
}

export enum VideoDownloaderStatus {
	InProgress = "inProgress",
	Completed = "completed",
}

export interface VideoDownloadCompleted {
	id: string;
	uri: string; // local uri
	status: VideoDownloaderStatus.Completed;
}

export interface VideoDownloadInProgress {
	id: string;
	progress: number;
	status: VideoDownloaderStatus.InProgress;
}

export type VideoDownloadInfo =
	| VideoDownloadCompleted
	| VideoDownloadInProgress;

export type LessonVideoDownloadInfo = VideoDownloadInfo & {
	type: VideoDownloaderType.LessonVideo;
	subcourseId: string;
	chapterId: string;
	lessonId: string;
	courseId: string;
};

export type ShortVideoDownloadInfo = VideoDownloadInfo & {
	type: VideoDownloaderType.ShortVideo;
};

export type SingleVideoDownloadInfo =
	| LessonVideoDownloadInfo
	| ShortVideoDownloadInfo;

export type VideosDownloadInfo = SingleVideoDownloadInfo[];
