import { gql } from "@apollo/client";
import { USER_FIELDS_FRAGMENT } from "../@models";

const GET_CURRENT_USER = gql`
	${USER_FIELDS_FRAGMENT}
	query GetCurrentUser {
		getCurrentUser {
			...UserFields
		}
	}
`;

export default GET_CURRENT_USER;
