import React from 'react';
import { IconProps } from '../IconsList';

const StreakIcon = (props: IconProps) => {

  const { color = "#FD521B", className, width = 12, height = 16, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M6.00001 0.187408C5.81812 0.18741 5.63619 0.272254 5.52209 0.442016L3.36282 3.65415L3.36489 3.65078L2.97027 3.3172C2.65789 3.05315 2.17766 3.18974 2.05105 3.57868L0.556542 8.1705C0.348657 8.77146 0.241394 9.40644 0.241394 10.054C0.241394 13.2293 2.8247 15.8126 5.99998 15.8126C6.00959 15.8126 6.01919 15.8126 6.02879 15.8125C9.19089 15.7969 11.7586 13.2197 11.7586 10.054C11.7586 8.90483 11.421 7.79531 10.7824 6.84533L6.47794 0.442015C6.36384 0.27229 6.18191 0.187408 6.00001 0.187408Z" fill={color} />
      <path d="M8.18091 8.89774L6.36302 6.19345C6.18966 5.93556 5.8102 5.93556 5.63686 6.19345L3.81897 8.89774C3.52847 9.32978 3.37494 9.83515 3.37494 10.3591C3.37494 11.8065 4.55251 12.9841 5.99993 12.9841C7.44735 12.9841 8.62492 11.8065 8.62492 10.3591C8.62492 9.83515 8.47138 9.32978 8.18091 8.89774Z" fill="#FFDE66" />
    </svg>
  );
};

export default StreakIcon;