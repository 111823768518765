import React from 'react';
import { IconProps } from '../IconsList';

const PrivacyIcon = (props: IconProps) => {

  const { color = "#575D62", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.86762 1.67254L4.12512 3.08254C3.26262 3.40504 2.55762 4.42504 2.55762 5.34004V10.9125C2.55762 11.7975 3.14262 12.96 3.85512 13.4925L7.08012 15.9C8.13762 16.695 9.87762 16.695 10.9351 15.9L14.1601 13.4925C14.8726 12.96 15.4576 11.7975 15.4576 10.9125V5.34004C15.4576 4.41754 14.7526 3.39754 13.8901 3.07504L10.1476 1.67254C9.51012 1.44004 8.49012 1.44004 7.86762 1.67254Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.7876 8.90254L7.9951 10.11L11.2201 6.88504" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PrivacyIcon;