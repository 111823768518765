import React from 'react';
import { useHistory } from 'react-router-dom';

let timeout: NodeJS.Timeout;

const ReactNativeDeepLink = () => {

  const history = useHistory();
  // const location = useLocation();

  const [rnLink, setRnLink] = React.useState<string | undefined>();

  React.useEffect(() => {

    if (timeout) {
      clearInterval(timeout);
    }

    timeout = setInterval(() => {
      if (rnLink !== (window as any).ReactNativeDeepLink) {
        setRnLink((window as any).ReactNativeDeepLink);
      }
    }, 1);

    return () => {
      clearInterval(timeout);
    };
  }, [rnLink]);

  React.useEffect(() => {
    if (rnLink) {
      // console.log("-rnLink--", rnLink);
      // const timeIndex = rnLink.indexOf("?time");
      // const finalLink = timeIndex > -1 ? rnLink.substring(0, timeIndex) : rnLink;
      // if(location.pathname !== finalLink) {
        history.push(rnLink);
      // }
    }
  }, [rnLink]);

  console.log("-rnLink-render-", rnLink);

  return null;
};

export default ReactNativeDeepLink;