import React from 'react';
import { IconProps } from '../IconsList';

const LanguageEditIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 18, height = 19, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.5 9.50049C16.5 5.36049 13.14 2.00049 9 2.00049C4.86 2.00049 1.5 5.36049 1.5 9.50049C1.5 13.6405 4.86 17.0005 9 17.0005" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.0002 2.75H6.7502C5.2877 7.13 5.2877 11.87 6.7502 16.25H6.0002" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.25 2.75C11.9775 4.94 12.345 7.22 12.345 9.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.25 12.5V11.75C4.44 12.4775 6.72 12.845 9 12.845" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.25 7.25044C6.63 5.78794 11.37 5.78794 15.75 7.25044" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.4075 12.305L11.7525 14.96C11.6475 15.065 11.55 15.26 11.5275 15.4025L11.385 16.415C11.3325 16.7825 11.5875 17.0375 11.955 16.985L12.9675 16.8425C13.11 16.82 13.3125 16.7225 13.41 16.6175L16.065 13.9625C16.5225 13.505 16.74 12.9725 16.065 12.2975C15.3975 11.63 14.865 11.8475 14.4075 12.305Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.0249 12.688C14.2499 13.498 14.8799 14.128 15.6899 14.353" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default LanguageEditIcon;