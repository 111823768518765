import React from 'react';
import { IconProps } from '../IconsList';

const FilmIcon = (props: IconProps) => {

  const { color = "#5774CD", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.1 4.11332C13.8267 3.96666 13.2534 3.81332 12.4734 4.35999L11.4934 5.05332C11.42 2.97999 10.52 2.16666 8.33335 2.16666H4.33335C2.05335 2.16666 1.16669 3.05332 1.16669 5.33332V10.6667C1.16669 12.2 2.00002 13.8333 4.33335 13.8333H8.33335C10.52 13.8333 11.42 13.02 11.4934 10.9467L12.4734 11.64C12.8867 11.9333 13.2467 12.0267 13.5334 12.0267C13.78 12.0267 13.9734 11.9533 14.1 11.8867C14.3734 11.7467 14.8334 11.3667 14.8334 10.4133V5.58666C14.8334 4.63332 14.3734 4.25332 14.1 4.11332ZM7.33335 7.58666C6.64669 7.58666 6.08002 7.02666 6.08002 6.33332C6.08002 5.63999 6.64669 5.07999 7.33335 5.07999C8.02002 5.07999 8.58669 5.63999 8.58669 6.33332C8.58669 7.02666 8.02002 7.58666 7.33335 7.58666Z" fill={color} />
    </svg>
  );
};

export default FilmIcon;