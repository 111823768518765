import React from 'react';
import { IconProps } from '../IconsList';

const LikeIcon = (props: IconProps) => {

  //doesn't support color for now
  //TODO we can add support for color and bg color
  const { color = "#263238", className, width = 260, height = 249, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.61133 13.7624L7.93633 15.5624C8.23633 15.8624 8.91133 16.0124 9.36133 16.0124H12.2113C13.1113 16.0124 14.0863 15.3374 14.3113 14.4374L16.1113 8.96235C16.4863 7.91235 15.8113 7.01235 14.6863 7.01235H11.6863C11.2363 7.01235 10.8613 6.63735 10.9363 6.11235L11.3113 3.71235C11.4613 3.03735 11.0113 2.28735 10.3363 2.06235C9.73633 1.83735 8.98633 2.13735 8.68633 2.58735L5.61133 7.16235" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M1.78516 13.7623V6.4123C1.78516 5.3623 2.23516 4.9873 3.28516 4.9873H4.03516C5.08516 4.9873 5.53516 5.3623 5.53516 6.4123V13.7623C5.53516 14.8123 5.08516 15.1873 4.03516 15.1873H3.28516C2.23516 15.1873 1.78516 14.8123 1.78516 13.7623Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default LikeIcon;