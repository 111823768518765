import React from 'react';
import { IconProps } from '../IconsList';

const UserIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 26, height = 27, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.1401 23.12C18.2601 23.38 17.2201 23.5 16.0001 23.5H10.0001C8.78011 23.5 7.74011 23.38 6.86011 23.12C7.08011 20.52 9.75011 18.47 13.0001 18.47C16.2501 18.47 18.9201 20.52 19.1401 23.12Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 3.5H10C5 3.5 3 5.5 3 10.5V16.5C3 20.28 4.14 22.35 6.86 23.12C7.08 20.52 9.75 18.47 13 18.47C16.25 18.47 18.92 20.52 19.14 23.12C21.86 22.35 23 20.28 23 16.5V10.5C23 5.5 21 3.5 16 3.5ZM13 15.67C11.02 15.67 9.42 14.06 9.42 12.08C9.42 10.1 11.02 8.5 13 8.5C14.98 8.5 16.58 10.1 16.58 12.08C16.58 14.06 14.98 15.67 13 15.67Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.5799 12.08C16.5799 14.06 14.9799 15.67 12.9999 15.67C11.0199 15.67 9.41992 14.06 9.41992 12.08C9.41992 10.1 11.0199 8.5 12.9999 8.5C14.9799 8.5 16.5799 10.1 16.5799 12.08Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default UserIcon;