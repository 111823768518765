import React from 'react';
import { IconProps } from '../IconsList';

const NeedleIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 7, height = 12, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.900391" y="0.25" width="5.2" height="8" fill={color}/>
      <path d="M3.5 12L6.09808 8.25H0.901924L3.5 12Z" fill={color}/>
    </svg>
  );
};

export default NeedleIcon;