import React from 'react';
import { IconProps } from '../IconsList';

const WriteFillIcon = (props: IconProps) => {

  const { color = "#58B368", className, width = 28, height = 28, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2.33008" y="2.32996" width="23.32" height="23.32" rx="6.4" fill={color} />
      <rect x="7" y="7" width="14" height="14" fill="white" />
      <path d="M17.7774 5H10.2316C6.95397 5 5 6.953 5 10.229V17.762C5 21.047 6.95397 23 10.2316 23H17.7684C21.046 23 23 21.047 23 17.771V10.229C23.009 6.953 21.055 5 17.7774 5ZM13.059 18.959C12.7979 19.22 12.3026 19.472 11.9425 19.526L9.72736 19.841C9.64632 19.85 9.56527 19.859 9.48423 19.859C9.11505 19.859 8.77288 19.733 8.52976 19.49C8.23261 19.193 8.10655 18.761 8.17858 18.284L8.49374 16.07C8.54777 15.701 8.79089 15.215 9.06102 14.954L13.077 10.94C13.1491 11.129 13.2211 11.318 13.3201 11.534C13.4102 11.723 13.5092 11.921 13.6173 12.101C13.7073 12.254 13.8064 12.398 13.8874 12.506C13.9865 12.659 14.1035 12.803 14.1756 12.884C14.2206 12.947 14.2566 12.992 14.2746 13.01C14.4997 13.28 14.7609 13.532 14.986 13.721C15.049 13.784 15.085 13.82 15.103 13.829C15.2381 13.937 15.3732 14.045 15.4902 14.126C15.6343 14.234 15.7784 14.333 15.9314 14.414C16.1115 14.522 16.3096 14.621 16.5077 14.72C16.7148 14.81 16.9039 14.891 17.093 14.954L13.059 18.959ZM18.8399 13.181L18.0115 14.018C17.9575 14.072 17.8854 14.099 17.8134 14.099C17.7864 14.099 17.7504 14.099 17.7323 14.09C15.9044 13.568 14.4457 12.11 13.9234 10.283C13.8964 10.184 13.9234 10.076 13.9955 10.013L14.8329 9.176C16.2016 7.808 17.5072 7.835 18.8489 9.176C19.5332 9.86 19.8664 10.517 19.8664 11.201C19.8574 11.849 19.5242 12.497 18.8399 13.181Z" fill={color} />
    </svg>
  );
};

export default WriteFillIcon;