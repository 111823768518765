import React from 'react';
import { IconProps } from '../IconsList';

const CartoonIcon = (props: IconProps) => {

  const { color = "#5774CD", className, width = 16, height = 16, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.7933 1.33334H5.20665C2.77998 1.33334 1.33331 2.78001 1.33331 5.20668V10.7867C1.33331 13.22 2.77998 14.6667 5.20665 14.6667H10.7866C13.2133 14.6667 14.66 13.22 14.66 10.7933V5.20668C14.6666 2.78001 13.22 1.33334 10.7933 1.33334ZM9.77331 9.15334L8.91998 9.64668L8.06665 10.14C6.96665 10.7733 6.06665 10.2533 6.06665 8.98668V8.00001V7.01334C6.06665 5.74001 6.96665 5.22668 8.06665 5.86001L8.91998 6.35334L9.77331 6.84668C10.8733 7.48001 10.8733 8.52001 9.77331 9.15334Z" fill={color} />
    </svg>
  );
};

export default CartoonIcon;