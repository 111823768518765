import React from 'react';
import { IconProps } from '../IconsList';

const AddNoteIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 18, height = 19, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.99976 2.00049V4.25049" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.0002 2.00049V4.25049" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.75 6.87549V10.723C15.0825 10.1905 14.235 9.87549 13.3125 9.87549C12.39 9.87549 11.5275 10.198 10.8525 10.7455C9.945 11.458 9.375 12.5755 9.375 13.813C9.375 14.548 9.58501 15.253 9.94501 15.838C10.2225 16.2955 10.5825 16.693 11.01 17.0005H6C3.375 17.0005 2.25 15.5005 2.25 13.2505V6.87549C2.25 4.62549 3.375 3.12549 6 3.12549H12C14.625 3.12549 15.75 4.62549 15.75 6.87549Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.25024 8.75049H9.75024" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.25024 12.4995H7.21524" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.2498 13.813C17.2498 14.548 17.0397 15.253 16.6797 15.838C16.4697 16.198 16.2073 16.513 15.8998 16.768C15.2098 17.383 14.3098 17.7505 13.3123 17.7505C12.4498 17.7505 11.6548 17.473 11.0098 17.0005C10.5823 16.693 10.2223 16.2955 9.94476 15.838C9.58476 15.253 9.37476 14.548 9.37476 13.813C9.37476 12.5755 9.94476 11.458 10.8523 10.7455C11.5273 10.198 12.3898 9.87549 13.3123 9.87549C14.2348 9.87549 15.0823 10.1905 15.7498 10.723C16.6648 11.443 17.2498 12.5605 17.2498 13.813Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.3127 15.687C13.3127 14.652 14.1527 13.812 15.1877 13.812C14.1527 13.812 13.3127 12.972 13.3127 11.937C13.3127 12.972 12.4727 13.812 11.4377 13.812C12.4727 13.812 13.3127 14.652 13.3127 15.687Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default AddNoteIcon;