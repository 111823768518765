import React from 'react';
import { IconProps } from '../IconsList';

const LogoBigIcon = (props: IconProps) => {

  //doesn't support color for now
  //TODO we can add support for color and bg color
  const { color = "#58B368", className, width = 167, height = 148, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 167 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="29.7856" y="132.714" width="5" height="4.28571" fill="white"/>
      <path d="M40.6247 132.142C40.283 131.958 39.5663 131.767 38.5913 132.45L37.3663 133.317C37.2747 130.725 36.1497 129.708 33.4163 129.708H28.4163C25.5663 129.708 24.458 130.817 24.458 133.667V140.333C24.458 142.25 25.4997 144.292 28.4163 144.292H33.4163C36.1497 144.292 37.2747 143.275 37.3663 140.683L38.5913 141.55C39.108 141.917 39.558 142.033 39.9163 142.033C40.2247 142.033 40.4663 141.942 40.6247 141.858C40.9663 141.683 41.5413 141.208 41.5413 140.017V133.983C41.5413 132.792 40.9663 132.317 40.6247 132.142ZM32.1663 136.483C31.308 136.483 30.5997 135.783 30.5997 134.917C30.5997 134.05 31.308 133.35 32.1663 133.35C33.0247 133.35 33.733 134.05 33.733 134.917C33.733 135.783 33.0247 136.483 32.1663 136.483Z" fill="#58B368"/>
      <path d="M66.3333 131.042V140.95C66.3333 141.758 65.675 142.5 64.8667 142.6L64.6083 142.633C63.2417 142.817 61.3167 143.383 59.7667 144.033C59.225 144.258 58.625 143.85 58.625 143.258V131.667C58.625 131.358 58.8 131.075 59.075 130.925C60.6 130.1 62.9083 129.367 64.475 129.233H64.525C65.525 129.233 66.3333 130.042 66.3333 131.042Z" fill="#58B368"/>
      <path d="M56.9254 130.925C55.4004 130.1 53.0921 129.367 51.5254 129.233H51.4671C50.4671 129.233 49.6587 130.042 49.6587 131.042V140.95C49.6587 141.758 50.3171 142.5 51.1254 142.6L51.3837 142.633C52.7504 142.817 54.6754 143.383 56.2254 144.033C56.7671 144.258 57.3671 143.85 57.3671 143.258V131.667C57.3671 131.35 57.2004 131.075 56.9254 130.925ZM52.1671 133.45H54.0421C54.3837 133.45 54.6671 133.733 54.6671 134.075C54.6671 134.425 54.3837 134.7 54.0421 134.7H52.1671C51.8254 134.7 51.5421 134.425 51.5421 134.075C51.5421 133.733 51.8254 133.45 52.1671 133.45ZM54.6671 137.2H52.1671C51.8254 137.2 51.5421 136.925 51.5421 136.575C51.5421 136.233 51.8254 135.95 52.1671 135.95H54.6671C55.0087 135.95 55.2921 136.233 55.2921 136.575C55.2921 136.925 55.0087 137.2 54.6671 137.2Z" fill="#58B368"/>
      <rect x="76.9286" y="131.285" width="12.8571" height="10.7143" fill="white"/>
      <path d="M86.4916 128.667H79.5083C76.475 128.667 74.6666 130.475 74.6666 133.508V140.483C74.6666 143.525 76.475 145.333 79.5083 145.333H86.4833C89.5166 145.333 91.325 143.525 91.325 140.492V133.508C91.3333 130.475 89.525 128.667 86.4916 128.667ZM78.625 138.783C78.625 139.125 78.3416 139.408 78 139.408C77.6583 139.408 77.375 139.125 77.375 138.783V135.217C77.375 134.875 77.6583 134.592 78 134.592C78.3416 134.592 78.625 134.875 78.625 135.217V138.783ZM81.125 139.975C81.125 140.317 80.8416 140.6 80.5 140.6C80.1583 140.6 79.875 140.317 79.875 139.975V134.025C79.875 133.683 80.1583 133.4 80.5 133.4C80.8416 133.4 81.125 133.683 81.125 134.025V139.975ZM83.625 141.167C83.625 141.508 83.3416 141.792 83 141.792C82.6583 141.792 82.375 141.508 82.375 141.167V132.833C82.375 132.492 82.6583 132.208 83 132.208C83.3416 132.208 83.625 132.492 83.625 132.833V141.167ZM86.125 139.975C86.125 140.317 85.8416 140.6 85.5 140.6C85.1583 140.6 84.875 140.317 84.875 139.975V134.025C84.875 133.683 85.1583 133.4 85.5 133.4C85.8416 133.4 86.125 133.683 86.125 134.025V139.975ZM88.625 138.783C88.625 139.125 88.3416 139.408 88 139.408C87.6583 139.408 87.375 139.125 87.375 138.783V135.217C87.375 134.875 87.6583 134.592 88 134.592C88.3416 134.592 88.625 134.875 88.625 135.217V138.783Z" fill="#58B368"/>
      <path d="M111.492 128.667H104.509C101.475 128.667 99.6671 130.475 99.6671 133.508V140.483C99.6671 143.525 101.475 145.333 104.509 145.333H111.484C114.517 145.333 116.325 143.525 116.325 140.492V133.508C116.334 130.475 114.525 128.667 111.492 128.667ZM107.125 141.592C106.884 141.833 106.425 142.067 106.092 142.117L104.042 142.408C103.967 142.417 103.892 142.425 103.817 142.425C103.475 142.425 103.159 142.308 102.934 142.083C102.659 141.808 102.542 141.408 102.609 140.967L102.9 138.917C102.95 138.575 103.175 138.125 103.425 137.883L107.142 134.167C107.209 134.342 107.275 134.517 107.367 134.717C107.45 134.892 107.542 135.075 107.642 135.242C107.725 135.383 107.817 135.517 107.892 135.617C107.984 135.758 108.092 135.892 108.159 135.967C108.2 136.025 108.234 136.067 108.25 136.083C108.459 136.333 108.7 136.567 108.909 136.742C108.967 136.8 109 136.833 109.017 136.842C109.142 136.942 109.267 137.042 109.375 137.117C109.509 137.217 109.642 137.308 109.784 137.383C109.95 137.483 110.134 137.575 110.317 137.667C110.509 137.75 110.684 137.825 110.859 137.883L107.125 141.592ZM112.475 136.242L111.709 137.017C111.659 137.067 111.592 137.092 111.525 137.092C111.5 137.092 111.467 137.092 111.45 137.083C109.759 136.6 108.409 135.25 107.925 133.558C107.9 133.467 107.925 133.367 107.992 133.308L108.767 132.533C110.034 131.267 111.242 131.292 112.484 132.533C113.117 133.167 113.425 133.775 113.425 134.408C113.417 135.008 113.109 135.608 112.475 136.242Z" fill="#58B368"/>
      <path d="M136.492 128.667H129.509C126.476 128.667 124.667 130.475 124.667 133.508V140.483C124.667 143.525 126.476 145.333 129.509 145.333H136.484C139.517 145.333 141.326 143.525 141.326 140.492V133.508C141.334 130.475 139.526 128.667 136.492 128.667ZM134.109 141.167H131.884C131.542 141.167 131.259 140.883 131.259 140.542C131.259 140.2 131.542 139.917 131.884 139.917H134.109C134.451 139.917 134.734 140.2 134.734 140.542C134.734 140.883 134.459 141.167 134.109 141.167ZM136.334 137.625H129.667C129.326 137.625 129.042 137.342 129.042 137C129.042 136.658 129.326 136.375 129.667 136.375H136.334C136.676 136.375 136.959 136.658 136.959 137C136.959 137.342 136.676 137.625 136.334 137.625ZM138.001 134.083H128.001C127.659 134.083 127.376 133.8 127.376 133.458C127.376 133.117 127.659 132.833 128.001 132.833H138.001C138.342 132.833 138.626 133.117 138.626 133.458C138.626 133.8 138.342 134.083 138.001 134.083Z" fill="#58B368"/>
      <g opacity="0.4" filter="url(#filter0_f_2605_54788)">
      <path d="M69.2089 34.2887C60.0353 30.9064 53.4949 37.3891 51.3714 41.0533C39.8195 41.0533 34.9496 49.7908 33.9586 54.1596C22.067 71.9165 39.4797 93.0557 53.0702 92.2102C59.8654 105.316 81.1005 105.316 86.1969 106.162C89.2467 106.668 95.5403 118 102.76 118C107.432 118 108.706 110.39 109.556 102.78C114.652 104.471 119.748 102.78 121.872 101.511C147.779 82.9089 134.188 46.9723 126.544 44.8583C122.127 36.0644 113.944 34.1478 110.405 34.2887C102.59 28.2006 93.8416 30.6246 90.444 32.5975C79.5716 27.1859 71.7571 31.4701 69.2089 34.2887Z" fill="#2E933E"/>
      <path d="M69.2089 34.2887C60.0353 30.9064 53.4949 37.3891 51.3714 41.0533C39.8195 41.0533 34.9496 49.7908 33.9586 54.1596C22.067 71.9165 39.4797 93.0557 53.0702 92.2102C59.8654 105.316 81.1005 105.316 86.1969 106.162C89.2467 106.668 95.5403 118 102.76 118C107.432 118 108.706 110.39 109.556 102.78C114.652 104.471 119.748 102.78 121.872 101.511C147.779 82.9089 134.188 46.9723 126.544 44.8583C122.127 36.0644 113.944 34.1478 110.405 34.2887C102.59 28.2006 93.8416 30.6246 90.444 32.5975C79.5716 27.1859 71.7571 31.4701 69.2089 34.2887Z" fill="url(#paint0_linear_2605_54788)" fillOpacity="0.35"/>
      </g>
      <path d="M66.1712 17.0685C55.3687 13.0712 47.6669 20.7326 45.1663 25.0629C31.5631 25.0629 25.8285 35.3891 24.6616 40.5522C10.6583 61.5377 31.163 86.5204 47.1668 85.5211C55.1686 101.01 80.1744 101.01 86.1758 102.01C89.7671 102.608 97.1783 116 105.68 116C111.182 116 112.682 107.006 113.682 98.0125C119.684 100.011 125.685 98.0125 128.186 96.5135C158.693 74.5287 142.689 32.0581 133.687 29.5599C128.486 19.1671 118.85 16.902 114.683 17.0685C105.48 9.87349 95.178 12.7381 91.177 15.0698C78.3741 8.67426 69.1719 13.7374 66.1712 17.0685Z" fill="#58B368"/>
      <path d="M66.1712 17.0685C55.3687 13.0712 47.6669 20.7326 45.1663 25.0629C31.5631 25.0629 25.8285 35.3891 24.6616 40.5522C10.6583 61.5377 31.163 86.5204 47.1668 85.5211C55.1686 101.01 80.1744 101.01 86.1758 102.01C89.7671 102.608 97.1783 116 105.68 116C111.182 116 112.682 107.006 113.682 98.0125C119.684 100.011 125.685 98.0125 128.186 96.5135C158.693 74.5287 142.689 32.0581 133.687 29.5599C128.486 19.1671 118.85 16.902 114.683 17.0685C105.48 9.87349 95.178 12.7381 91.177 15.0698C78.3741 8.67426 69.1719 13.7374 66.1712 17.0685Z" fill="url(#paint1_linear_2605_54788)" fillOpacity="0.3"/>
      <path d="M73.2 66.5H60.2L54.2 72.5" stroke="white" strokeLinecap="round"/>
      <circle cx="55.5" cy="71.5" r="2.5" fill="white"/>
      <path d="M72 47.5H58" stroke="white" strokeLinecap="round"/>
      <circle r="2.5" transform="matrix(1 0 0 -1 56.5 47.5)" fill="white"/>
      <path d="M62.25 52.5L73.5 52.5" stroke="white" strokeLinecap="round"/>
      <path d="M93.5 65.05L93.5 78.8L99.5 84.8" stroke="white" strokeLinecap="round"/>
      <circle cx="98.5" cy="83.5" r="2.5" transform="rotate(-90 98.5 83.5)" fill="white"/>
      <path d="M79.5 67.25L79.5 78.5" stroke="white" strokeLinecap="round"/>
      <path d="M88.5 67.25L88.5 78.5" stroke="white" strokeLinecap="round"/>
      <path d="M74.5 68L74.5 83.25" stroke="white" strokeLinecap="round"/>
      <circle r="2.5" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 74.5 84.5)" fill="white"/>
      <path d="M94.8 47.5H107.8L113.8 41.5" stroke="white" strokeLinecap="round"/>
      <circle cx="113" cy="42.5" r="2.5" transform="rotate(-180 113 42.5)" fill="white"/>
      <path d="M95.5 66.5H109.5" stroke="white" strokeLinecap="round"/>
      <circle r="2.5" transform="matrix(-1 0 0 1 111.5 66.5)" fill="white"/>
      <path d="M105.25 61.5L94 61.5" stroke="white" strokeLinecap="round"/>
      <path d="M74.5 46.45L74.5 35.2L68.5 29.2" stroke="white" strokeLinecap="round"/>
      <circle cx="69.5" cy="29.5" r="2.5" transform="rotate(90 69.5 29.5)" fill="white"/>
      <path d="M88.5 46.25L88.5 35.5" stroke="white" strokeLinecap="round"/>
      <path d="M93.5 44.75L93.5 31" stroke="white" strokeLinecap="round"/>
      <circle r="2.5" transform="matrix(-4.37114e-08 1 1 4.37114e-08 93.5 29.5)" fill="white"/>
      <path d="M37.8 47.5H46.3L60.3 61.5H72.8" stroke="white" strokeLinecap="round"/>
      <path d="M37 61.5H47.5" stroke="white" strokeLinecap="round"/>
      <circle r="2.5" transform="matrix(1 8.74228e-08 8.74228e-08 -1 36.5 61.5)" fill="white"/>
      <circle r="2.5" transform="matrix(1 8.74228e-08 8.74228e-08 -1 47.5 61.5)" fill="white"/>
      <circle r="2.5" transform="matrix(1 8.74228e-08 8.74228e-08 -1 36.5 47.5)" fill="white"/>
      <path d="M130.3 66.5H121.8L107.8 52.5H95.3" stroke="white" strokeLinecap="round"/>
      <path d="M130.8 52.5H120.3" stroke="white" strokeLinecap="round"/>
      <circle r="2.5" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 131.5 52.5)" fill="white"/>
      <circle r="2.5" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 120.3 52.5)" fill="white"/>
      <circle r="2.5" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 131.5 66.5)" fill="white"/>
      <rect x="69" y="42" width="30" height="30" rx="4" fill="white"/>
      <rect x="69" y="42" width="30" height="30" rx="4" fill="url(#paint2_linear_2605_54788)" fillOpacity="0.3"/>
      <path d="M90.3039 52.9703H77.2266" stroke="#409C4F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M83.7654 50.7668V52.9705" stroke="#409C4F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M87.0413 52.9443C87.0413 58.5848 82.6385 63.1496 77.2136 63.1496" stroke="#409C4F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M90.3173 63.1497C87.9587 63.1497 85.8621 61.8905 84.3552 59.9098" stroke="#409C4F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <defs>
      <filter id="filter0_f_2605_54788" x="0" y="0" width="167" height="148" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_2605_54788"/>
      </filter>
      <linearGradient id="paint0_linear_2605_54788" x1="-2.59574" y1="5.24231" x2="104.163" y2="116.109" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="white" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint1_linear_2605_54788" x1="146" y1="137" x2="52" y2="12" gradientUnits="userSpaceOnUse">
      <stop/>
      <stop offset="1" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint2_linear_2605_54788" x1="25.5" y1="126" x2="3.90583" y2="59.9342" gradientUnits="userSpaceOnUse">
      <stop stopColor="#359745"/>
      <stop offset="1" stopColor="#359745" stopOpacity="0"/>
      </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoBigIcon;