import React, { useEffect, useRef, ReactNode, HTMLProps } from "react";

type Props = HTMLProps<HTMLDivElement> & {
	onClick?: () => void;
	children?: ReactNode;
	stopPropagation?: boolean;
};

const UiElement = (props: Props) => {
	const { onClick, children, stopPropagation, ...rest } = props;

	const addNoteEl = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const onlyTouch = (e: TouchEvent) => {
			console.log("Here a touchstart event is triggered"); // will be false
			if (stopPropagation) {
				e.stopPropagation();
			}
			e.preventDefault(); // does nothing since the listener is passive
			console.log(
				"After triggering an event and is event default Prevented : ",
				e.defaultPrevented
			);
			if (onClick) onClick();
		};

		addNoteEl.current?.addEventListener("touchstart", onlyTouch, false); //{passive: true}

		const onlyClick = (e: any) => {
			console.log("Here a click event is triggered");
			if (stopPropagation) {
				e.stopPropagation();
			}
			e.preventDefault();
			console.log("After triggering an event");
			if (onClick) onClick();
		};

		// click event handler
		addNoteEl.current?.addEventListener("click", onlyClick, false);

		return () => {
			addNoteEl.current?.removeEventListener("touchstart", onlyTouch);
			addNoteEl.current?.removeEventListener("click", onlyClick);
		};
	}, [onClick]);

	return (
		<div ref={addNoteEl} tabIndex={0} role="button" {...rest}>
			{children}
		</div>
	);
};

export default UiElement;

/**
 * 
 * 
 * import React, { useEffect, useRef, ReactNode, HTMLProps } from "react";
import clsx from "clsx";

type Props = HTMLProps<HTMLButtonElement> & {
	onClick?: () => void;
	children?: ReactNode;
	stopPropagation?: boolean;
};

const UiElement = (props: Props) => {
	const { onClick, children, stopPropagation, className, ...rest } = props;

	/*const addNoteEl = useRef<HTMLDivElement>(null);

  useEffect(() => {

    const onlyTouch = (e: TouchEvent) => {
      console.log("Here a touchstart event is triggered");  // will be false
      if(stopPropagation) {
        e.stopPropagation();
      }
      e.preventDefault();   // does nothing since the listener is passive
      console.log("After triggering an event and is event default Prevented : ", e.defaultPrevented);
      if(onClick) onClick();
    };

    addNoteEl.current?.addEventListener("touchstart", onlyTouch, false); //{passive: true}

    const onlyClick = (e: any) => {
      console.log("Here a click event is triggered");
      if(stopPropagation) {
        e.stopPropagation();
      }
      e.preventDefault();
      console.log("After triggering an event");
      if(onClick) onClick();
    };

    // click event handler
    addNoteEl.current?.addEventListener("click", onlyClick, false);

    return () => {
      addNoteEl.current?.removeEventListener("touchstart", onlyTouch);
      addNoteEl.current?.removeEventListener("click", onlyClick);
    };
  }, [onClick]);*

	return (
		<button
			// ref={addNoteEl}
			onClick={(e) => {
				if (stopPropagation) {
					e.stopPropagation();
				}
				onClick?.();
			}}
			className={clsx(className, "no-style-button")}
			{...rest}
			type="button"
		>
			{children}
		</button>
	);
};

export default UiElement;

 */
