import React from 'react';
import { IconProps } from '../IconsList';

const NotFoundIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 88, height = 64, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 88 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_1664_4263)">
        <ellipse cx="42.5042" cy="34.5" rx="32.5" ry="19.5" fill="#E0E0E0" />
      </g>
      <path d="M31.6967 9.78148L24.2081 48.1365L23.2346 53.1538L73.0913 52.9122C75.3263 52.9122 77.3137 50.9156 78.0338 47.9639L86.8703 11.755C87.4175 9.51681 86.2193 7.19226 84.52 7.19226L34.0585 7.24405C32.964 7.23254 31.9905 8.27398 31.6967 9.78148Z" fill="#CFCFCF" />
      <path d="M68.3273 45.5243L63.045 3.03227C62.9704 2.21766 62.5989 1.45879 62.001 0.899784C61.4032 0.340778 60.6206 0.0205772 59.8019 0L48.2061 0.0402768C47.7727 0.0400851 47.344 0.130025 46.9473 0.304373C46.5506 0.47872 46.1946 0.733655 45.9019 1.05295L40.441 6.90459L8.25164 7.07145C7.77359 7.0992 7.30653 7.22586 6.88008 7.44341C6.45363 7.66096 6.07708 7.96464 5.77433 8.33521C5.47157 8.70578 5.24921 9.13515 5.12135 9.59608C4.99348 10.057 4.9629 10.5395 5.03155 11.0128L9.93369 50.2827C10.0081 51.0963 10.3788 51.8544 10.9755 52.4133C11.5722 52.9722 12.3534 53.293 13.1711 53.315L69.2778 53.2056C75.5279 53.338 76.127 51.4795 76.127 51.4795C68.9437 53.079 68.3273 45.53 68.3273 45.5243Z" fill="#E8E8E8" />
      <path d="M44.1334 37.0201L42.2382 39.0512L27.6643 25.9555L29.5595 23.9244L44.1334 37.0201Z" fill="#B5B5B5" />
      <path d="M41.4491 23.4756L43.5401 25.3513L30.3545 39.5L28.2634 37.6242L41.4491 23.4756Z" fill="#B5B5B5" />
      <defs>
        <filter id="filter0_f_1664_4263" x="0.00415039" y="5" width="85" height="59" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_1664_4263" />
        </filter>
      </defs>    
    </svg>
  );
};

export default NotFoundIcon;