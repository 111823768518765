import React from 'react';
import { IconProps } from '../IconsList';

const HappyIcon = (props: IconProps) => {

  const { color = "#B2B2B2", className, width = 26, height = 26, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23.8334 13.0313L23.8334 9.75001C23.8334 4.33334 21.6667 2.16667 16.25 2.16667H9.75002C4.33335 2.16667 2.16669 4.33334 2.16669 9.75001V16.25C2.16669 21.6667 4.33335 23.8333 9.75002 23.8333L13.4844 23.8333" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.7917 10.5625C17.6891 10.5625 18.4167 9.83496 18.4167 8.9375C18.4167 8.04004 17.6891 7.3125 16.7917 7.3125C15.8942 7.3125 15.1667 8.04004 15.1667 8.9375C15.1667 9.83496 15.8942 10.5625 16.7917 10.5625Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.20831 10.5625C10.1058 10.5625 10.8333 9.83496 10.8333 8.9375C10.8333 8.04004 10.1058 7.3125 9.20831 7.3125C8.31085 7.3125 7.58331 8.04004 7.58331 8.9375C7.58331 9.83496 8.31085 10.5625 9.20831 10.5625Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.1 14.4083H16.9C17.4417 14.4083 17.875 14.8417 17.875 15.3833C17.875 18.0808 15.6975 20.2583 13 20.2583C10.3025 20.2583 8.125 18.0808 8.125 15.3833C8.125 14.8417 8.55833 14.4083 9.1 14.4083Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.8759 17.7097L17.7277 21.9749C17.5678 22.1393 17.416 22.4598 17.384 22.6899L17.1602 24.3171C17.0803 24.9088 17.4799 25.3197 18.0554 25.2375L19.6379 25.0074C19.8617 24.9745 20.1734 24.8184 20.3333 24.654L24.4815 20.3888C25.1928 19.6574 25.5365 18.8027 24.4815 17.7179C23.4264 16.6249 22.5952 16.97 21.8759 17.7097Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.2844 18.3178C21.636 19.6081 22.6191 20.6271 23.882 20.9887" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default HappyIcon;