import React from 'react';
import { IconProps } from '../IconsList';

const SortIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 20, height = 20, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.5 5.8335H17.5H2.5Z" fill={color} />
      <path d="M2.5 5.8335H17.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M5 10H15H5Z" fill={color} />
      <path d="M5 10H15" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M8.3338 14.1665H11.6671H8.3338Z" fill={color} />
      <path d="M8.3338 14.1665H11.6671" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export default SortIcon;