import React from 'react';
import { IconProps } from '../IconsList';

const SpeakFillIcon = (props: IconProps) => {

  const { color = "#58B368", className, width = 28, height = 28, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5.50024" y="6" width="18" height="15" fill="white" />
      <path d="M18.8883 2.33325H9.11158C4.86492 2.33325 2.33325 4.86492 2.33325 9.11159V18.8766C2.33325 23.1349 4.86492 25.6666 9.11158 25.6666H18.8766C23.1233 25.6666 25.6549 23.1349 25.6549 18.8883V9.11159C25.6666 4.86492 23.1349 2.33325 18.8883 2.33325ZM7.87492 16.4966C7.87492 16.9749 7.47825 17.3716 6.99992 17.3716C6.52159 17.3716 6.12492 16.9749 6.12492 16.4966V11.5033C6.12492 11.0249 6.52159 10.6283 6.99992 10.6283C7.47825 10.6283 7.87492 11.0249 7.87492 11.5033V16.4966ZM11.3749 18.1649C11.3749 18.6433 10.9783 19.0399 10.4999 19.0399C10.0216 19.0399 9.62492 18.6433 9.62492 18.1649V9.83492C9.62492 9.35659 10.0216 8.95992 10.4999 8.95992C10.9783 8.95992 11.3749 9.35659 11.3749 9.83492V18.1649ZM14.8749 19.8333C14.8749 20.3116 14.4783 20.7083 13.9999 20.7083C13.5216 20.7083 13.1249 20.3116 13.1249 19.8333V8.16659C13.1249 7.68825 13.5216 7.29159 13.9999 7.29159C14.4783 7.29159 14.8749 7.68825 14.8749 8.16659V19.8333ZM18.3749 18.1649C18.3749 18.6433 17.9783 19.0399 17.4999 19.0399C17.0216 19.0399 16.6249 18.6433 16.6249 18.1649V9.83492C16.6249 9.35659 17.0216 8.95992 17.4999 8.95992C17.9783 8.95992 18.3749 9.35659 18.3749 9.83492V18.1649ZM21.8749 16.4966C21.8749 16.9749 21.4783 17.3716 20.9999 17.3716C20.5216 17.3716 20.1249 16.9749 20.1249 16.4966V11.5033C20.1249 11.0249 20.5216 10.6283 20.9999 10.6283C21.4783 10.6283 21.8749 11.0249 21.8749 11.5033V16.4966Z" fill={color} />
    </svg>
  );
};

export default SpeakFillIcon;