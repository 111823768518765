import React from "react";
import { activeCourseTabVar, userInfoVar } from "../../App";
import useSignout from "../../graphQL/user/hooks/useSignout";
// import Loading from "../../components/loading/Loading";

let timeout: NodeJS.Timeout;

const ReactNativeTakeAction = () => {
	const [rnActionId, setRnActionId] = React.useState<string | undefined>();

	React.useEffect(() => {
		if (timeout) {
			clearInterval(timeout);
		}

		timeout = setInterval(() => {
			if (rnActionId !== (window as any).ReactNativeTakeActionId) {
				setRnActionId((window as any).ReactNativeTakeActionId);
			}
		}, 100);

		return () => {
			clearInterval(timeout);
		};
	}, [rnActionId]);

	const { logout, logoutAll, loading, client } = useSignout();

	function syncRNLogoutWithWebView() {
		userInfoVar(undefined);
		localStorage.removeItem("currentUserInfo");
		if ((window as any).ReactNativeWebAppLogout) {
			(window as any).ReactNativeWebAppLogout();
		}
		client.resetStore();
		//reset reactive vriables
		activeCourseTabVar(0);
	}

	React.useEffect(() => {
		if (rnActionId) {
			console.log("-rnActionId--", rnActionId);
			const [action] = rnActionId.split("###");

			switch (action) {
				case "signOut":
					if ((window as any).ReactNativeInjectedUser) {
						syncRNLogoutWithWebView();
					} else {
						// for backward compatibility
						logout();
					}
					break;
				case "signOutAll":
					if ((window as any).ReactNativeInjectedUser) {
						syncRNLogoutWithWebView();
					} else {
						// for backward compatibility
						logoutAll();
					}
					break;
			}
		}
	}, [rnActionId]);

	console.log("-rnActionId-render-", rnActionId);

	//if (loading) {
	//	return <Loading isLoading={loading} />;
	//}

	return null;
};

export default ReactNativeTakeAction;
