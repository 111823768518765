import React from 'react';
import { IconProps } from '../IconsList';

const RemoveIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 12, height = 13, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.5 3.48975C8.835 3.32475 7.16 3.23975 5.49 3.23975C4.5 3.23975 3.51 3.28975 2.52 3.38975L1.5 3.48975" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.25024 2.98451L4.36024 2.32951C4.44024 1.85451 4.50024 1.49951 5.34524 1.49951H6.65524C7.50025 1.49951 7.56524 1.87451 7.64024 2.33451L7.75024 2.98451" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.42471 5.07007L9.09971 10.1051C9.04471 10.8901 8.99971 11.5001 7.60471 11.5001H4.39471C2.99971 11.5001 2.95471 10.8901 2.89971 10.1051L2.57471 5.07007" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.16504 8.75H6.83004" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.74976 6.74951H7.24976" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default RemoveIcon;