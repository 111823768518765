import React from 'react';
import { IconProps } from '../IconsList';

const HomeIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 26, height = 27, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.02 4.34004L4.63 8.54004C3.73 9.24004 3 10.73 3 11.86V19.27C3 21.59 4.89 23.49 7.21 23.49H18.79C21.11 23.49 23 21.59 23 19.28V12C23 10.79 22.19 9.24004 21.2 8.55004L15.02 4.22004C13.62 3.24004 11.37 3.29004 10.02 4.34004Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13 19.49V16.49" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default HomeIcon;