import { useMutation } from "@apollo/client";
import { activeCourseTabVar, userInfoVar } from "../../../App";
import useErrorNotification from "../../../helper/hooks/useErrorNotification";
import SIGN_OUT from "../SignOut";
import SIGN_OUT_ALL from "../SignOutAll";
import { SignOutAllData, SignOutData } from "../UserTypes";

const useSignout = () => {
	const [signOutAction, { loading, data, client }] = useMutation<SignOutData>(
		SIGN_OUT,
		{
			onCompleted({ signOut }) {
				if (signOut?.result) {
					userInfoVar(undefined);
					localStorage.removeItem("currentUserInfo");
					if ((window as any).ReactNativeWebAppLogout) {
						(window as any).ReactNativeWebAppLogout();
					}
					client.resetStore();
					//reset reactive vriables
					activeCourseTabVar(0);
				}
			},
		}
	);

	useErrorNotification(data?.signOut);

	const [signOutAll, { loading: signOutAllLoading, data: signOutAllData }] =
		useMutation<SignOutAllData>(SIGN_OUT_ALL, {
			onCompleted({ signOutAll }) {
				if (signOutAll?.result) {
					userInfoVar(undefined);
					localStorage.removeItem("currentUserInfo");
					if ((window as any).ReactNativeWebAppLogout) {
						(window as any).ReactNativeWebAppLogout();
					}
					client.resetStore();
					//reset reative Variables
					activeCourseTabVar(0);
				}
			},
		});

	useErrorNotification(signOutAllData?.signOutAll);

	const logout = () => {
		signOutAction();
	};

	const logoutAll = () => {
		signOutAll();
	};

	return {
		logout,
		logoutAll,
		loading: loading || signOutAllLoading,
		client,
	};
};

export default useSignout;
