import React from 'react';
import { IconProps } from '../IconsList';

const NoteIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 20, height = 21, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.66699 2.16687V4.66687" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.333 2.16687V4.66687" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.5 7.58354V14.6669C17.5 17.1669 16.25 18.8335 13.3333 18.8335H6.66667C3.75 18.8335 2.5 17.1669 2.5 14.6669V7.58354C2.5 5.08354 3.75 3.41687 6.66667 3.41687H13.3333C16.25 3.41687 17.5 5.08354 17.5 7.58354Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.66699 9.66687H13.3337" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.66699 13.8331H10.0003" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />    </svg>
  );
};

export default NoteIcon;