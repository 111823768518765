import React from 'react';
import { IconProps } from '../IconsList';

const StarFlatIcon = (props: IconProps) => {

  //doesn't support color for now
  //TODO we can add support for color and bg color
  const { color = "#292D32", className, width = 260, height = 249, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.3006 2.63248L11.6206 5.27248C11.8006 5.63998 12.2806 5.99248 12.6856 6.05998L15.0781 6.45748C16.6081 6.71248 16.9681 7.82248 15.8656 8.91748L14.0056 10.7775C13.6906 11.0925 13.5181 11.7 13.6156 12.135L14.1481 14.4375C14.5681 16.26 13.6006 16.965 11.9881 16.0125L9.74561 14.685C9.34061 14.445 8.67311 14.445 8.26061 14.685L6.01811 16.0125C4.41311 16.965 3.43811 16.2525 3.85811 14.4375L4.39061 12.135C4.48811 11.7 4.31561 11.0925 4.00061 10.7775L2.14061 8.91748C1.04561 7.82248 1.39811 6.71248 2.92811 6.45748L5.32061 6.05998C5.71811 5.99248 6.19811 5.63998 6.37811 5.27248L7.69811 2.63248C8.41811 1.19998 9.58811 1.19998 10.3006 2.63248Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default StarFlatIcon;