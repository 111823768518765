import React from 'react';
import { IconProps } from '../IconsList';

const DoneIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 12, height = 13, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.75 6.49992L4.58 9.32992L10.25 3.66992" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default DoneIcon;