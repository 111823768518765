import * as React from "react"
import { IconProps } from '../IconsList';

const CloseModalIcon = (props: IconProps) => {

  const { color = "#292D32", className, width = 20, height = 20, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.2485 16.2491L3.74994 3.75049" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.2501 3.75049L3.75146 16.2491" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
};

export default CloseModalIcon;