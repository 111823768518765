import {
	postMessageToRN,
	useRNHandler,
} from "../@utility/react-native.utility";
import React, {
	CSSProperties,
	ReactNode,
	useCallback,
	useEffect,
	useState,
} from "react";
import {
	useRemoveInProgressVideosOnAppClose,
	useVideosDownloadInfo,
} from "./video-downloader.query";
import {
	VideoDownloaderStatus,
	VideoDownloaderType,
} from "./video-downloader.types";
import { NotificationPlacement } from "antd/lib/notification";
import { translate } from "../../i18n";
import { notification } from "antd";
import { CloseOutline } from "antd-mobile-icons";
import { useReactiveVar } from "@apollo/client";
import { isRTLVar } from "../../App";
import { useRemoveDownloadedVideo } from "./remove-downloaded-video";

export const useVideoDownloader = () => {
	const { addVideoInfo, videos } = useVideosDownloadInfo();

	const [currVideoForDelete, setCurrVideoForDelete] = useState("");

	const { requestForDelete } = useRemoveDownloadedVideo(
		currVideoForDelete,
		videos
	);

	useEffect(() => {
		if (currVideoForDelete) {
			requestForDelete();
		}
	}, [currVideoForDelete]);

	const startLessonVideoDownloading = ({
		url,
		courseId,
		subcourseId,
		chapterId,
		lessonId,
		// remove the video from user device if it exists before
		// just we should confirm to remove fisrt
		toggle = false,
	}: {
		url: string;
		courseId: string;
		subcourseId: string;
		chapterId: string;
		lessonId: string;
		toggle?: boolean;
	}) => {
		const hasAlready = videos.find((v) => v.id === lessonId);

		if (hasAlready) {
			if (
				toggle &&
				hasAlready.status === VideoDownloaderStatus.Completed
			) {
				setCurrVideoForDelete(hasAlready.id);
			} else {
				console.log(
					"video is already downloaded or in downloding process"
				);
			}

			return;
		}

		addVideoInfo({
			id: lessonId,
			progress: 0,
			status: VideoDownloaderStatus.InProgress,
			type: VideoDownloaderType.LessonVideo,
			subcourseId: subcourseId,
			chapterId: chapterId,
			lessonId: lessonId,
			courseId: courseId,
		});

		postMessageToRN("videoDownloader", {
			id: lessonId,
			type: VideoDownloaderType.LessonVideo,
			url,
		});
	};

	const getLessonVideoProgress = useCallback(
		(lessonId: string) => {
			const videoInfo = videos.find((video) => video.id === lessonId);

			console.log("---videos", videos);
			console.log("---lessonId", lessonId);
			console.log("---videoInfo", videoInfo);

			if (videoInfo?.status === VideoDownloaderStatus.InProgress) {
				return videoInfo.progress;
			}

			if (videoInfo) {
				return 100;
			}

			return 0;
		},
		[videos]
	);

	const getLessonVideoOfflinLink = useCallback(
		(lessonId: string) => {
			const videoInfo = videos.find((video) => video.id === lessonId);

			if (videoInfo?.status === VideoDownloaderStatus.Completed) {
				return "localfile://" + videoInfo.uri;
			}

			return null;
		},
		[videos]
	);

	return {
		startLessonVideoDownloading,
		videos,
		getLessonVideoProgress,
		getLessonVideoOfflinLink,
	};
};

export function useVideoDownloaderSubscribToRN(isRTL?: boolean) {
	const { updateVideoProgress, removeVideosInfo, completeVideoDownload } =
		useVideosDownloadInfo();

	const getVideoDownloadProgress = useCallback(
		(payload: { progress: number; id: string }) => {
			updateVideoProgress(payload.id, payload.progress);
		},
		[]
	);

	useRNHandler("videoDownloadProgress", getVideoDownloadProgress);

	const getVideoDownloadComplete = useCallback(
		(payload: { uri: string; id: string }) => {
			completeVideoDownload(payload.id, payload.uri);
		},
		[]
	);

	useRNHandler("videoDownloadComplete", getVideoDownloadComplete);

	const getVideoDownloadFail = useCallback((payload: { id: string }) => {
		removeVideosInfo([payload.id]);
		const args: {
			message: string;
			description: string;
			closeIcon: ReactNode;
			placement?: NotificationPlacement;
			style: CSSProperties;
			key?: string;
			rtl?: boolean;
			duration?: number;
		} = {
			message: translate("notification.error"), //{tran}"Error!",
			description: translate("videoDownloader.error"),
			closeIcon: <CloseOutline />,
			placement: "topRight",
			style: {
				backgroundColor: "#fcd5cf",
			},
			key: "video-download-error",
			rtl: isRTL,
			//duration: 60,
		};

		notification.error(args);
	}, []);

	useRNHandler("videoDownloadFail", getVideoDownloadFail);

	const removeDownloadedVideoSuccess = useCallback(
		(payload: { id: string }) => {
			removeVideosInfo([payload.id]);
		},
		[]
	);

	useRNHandler("removeDownloadedVideoSuccess", removeDownloadedVideoSuccess);

	const removeDownloadedVideoFail = useCallback((payload: { id: string }) => {
		// fail to remove video
		// no need to do anything
	}, []);

	useRNHandler("removeDownloadedVideoFail", removeDownloadedVideoFail);

	const videoLoadFail = useCallback((payload: { id: string }) => {
		removeVideosInfo([payload.id]);
	}, []);

	useRNHandler("videoLoadFail", videoLoadFail);
}

export const RNVideoDownloader = () => {
	const isRTL = useReactiveVar(isRTLVar);

	// video downloader
	useVideoDownloaderSubscribToRN(isRTL);
	useRemoveInProgressVideosOnAppClose();

	return null;
};
