import React from 'react';
import { IconProps } from '../IconsList';

const ForwardIcon = (props: IconProps) => {

  const { color = "#292D32", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.485 3.35248L9 1.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.3166 5.85005C15.1491 6.96005 15.6666 8.33255 15.6666 9.83255C15.6666 13.515 12.6816 16.5001 8.99905 16.5001C5.31655 16.5001 2.33154 13.515 2.33154 9.83255C2.33154 6.15005 5.31655 3.16504 8.99905 3.16504C9.50905 3.16504 10.004 3.23258 10.484 3.34508" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.876 8.53605C10.876 8.79205 10.828 9.04005 10.732 9.28005C10.6413 9.51472 10.524 9.73872 10.38 9.95205C10.236 10.16 10.0733 10.3574 9.892 10.544C9.716 10.7254 9.54 10.8907 9.364 11.04C9.19333 11.184 9.03333 11.312 8.884 11.424C8.73467 11.5307 8.62 11.616 8.54 11.68H10.572C10.604 11.68 10.6333 11.688 10.66 11.704C10.6867 11.72 10.7 11.7494 10.7 11.792L10.668 12.264C10.6573 12.424 10.612 12.5547 10.532 12.656C10.4573 12.752 10.3 12.8 10.06 12.8H7.436C7.164 12.8 6.972 12.7574 6.86 12.672C6.75333 12.5814 6.7 12.4294 6.7 12.216V11.736C6.7 11.7094 6.70533 11.6774 6.716 11.64C6.732 11.6027 6.74533 11.5734 6.756 11.552C6.804 11.4614 6.90267 11.3494 7.052 11.216C7.20667 11.0827 7.38 10.9387 7.572 10.784C7.76933 10.6294 7.97467 10.464 8.188 10.288C8.40667 10.112 8.604 9.93338 8.78 9.75205C8.96133 9.57072 9.11067 9.39205 9.228 9.21605C9.34533 9.03472 9.404 8.86138 9.404 8.69605C9.404 8.56272 9.35867 8.45072 9.268 8.36005C9.17733 8.26405 9.03067 8.21605 8.828 8.21605C8.7 8.21605 8.59333 8.25072 8.508 8.32005C8.428 8.38938 8.36133 8.47738 8.308 8.58405C8.26 8.68538 8.22267 8.80005 8.196 8.92805C8.17467 9.05072 8.15867 9.16538 8.148 9.27205C8.13733 9.32538 8.11867 9.36272 8.092 9.38405C8.07067 9.40538 8.004 9.41605 7.892 9.41605C7.3 9.41605 7.004 9.13072 7.004 8.56005C7.004 8.28272 7.06267 8.04805 7.18 7.85605C7.30267 7.66405 7.46267 7.50672 7.66 7.38405C7.85733 7.26138 8.076 7.17338 8.316 7.12005C8.56133 7.06138 8.80933 7.03205 9.06 7.03205C9.41733 7.03205 9.71333 7.08005 9.948 7.17605C10.1827 7.26672 10.3693 7.38672 10.508 7.53605C10.6467 7.68005 10.7427 7.84272 10.796 8.02405C10.8493 8.20005 10.876 8.37072 10.876 8.53605Z" fill={color}/>
    </svg>
  );
};

export default ForwardIcon;