import React from 'react';
import { IconProps } from '../IconsList';

const LockIcon = (props: IconProps) => {

  const { color = "#03CAE5", className, width = 18, height = 19, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path opacity="0.4" d="M0.833327 19V12C0.765495 11.5246 0.809263 11.0399 0.961168 10.5843C1.11307 10.1287 1.36894 9.71477 1.70852 9.37519C2.0481 9.03561 2.46206 8.77974 2.91764 8.62784C3.37322 8.47593 3.85791 8.43217 4.33333 8.5H13.6667C14.1421 8.43217 14.6268 8.47593 15.0823 8.62784C15.5379 8.77974 15.9519 9.03561 16.2915 9.37519C16.631 9.71477 16.8869 10.1287 17.0388 10.5843C17.1907 11.0399 17.2345 11.5246 17.1667 12V19C17.2345 19.4754 17.1907 19.9601 17.0388 20.4157C16.8869 20.8713 16.631 21.2852 16.2915 21.6248C15.9519 21.9644 15.5379 22.2203 15.0823 22.3722C14.6268 22.5241 14.1421 22.5678 13.6667 22.5H4.33333C3.85791 22.5678 3.37322 22.5241 2.91764 22.3722C2.46206 22.2203 2.0481 21.9644 1.70852 21.6248C1.36894 21.2852 1.11307 20.8713 0.961168 20.4157C0.809263 19.9601 0.765495 19.4754 0.833327 19Z" fill={color} />
      <path d="M5.20831 8.5V6.16667C5.20831 5.16105 5.60779 4.19663 6.31887 3.48555C7.02994 2.77448 7.99437 2.375 8.99998 2.375C10.0056 2.375 10.97 2.77448 11.6811 3.48555C12.3922 4.19663 12.7916 5.16105 12.7916 6.16667V8.5H13.6666C13.9596 8.49943 14.2522 8.52205 14.5416 8.56767V6.16667C14.5416 4.69693 13.9578 3.28738 12.9185 2.24812C11.8793 1.20885 10.4697 0.625 8.99998 0.625C7.53024 0.625 6.12069 1.20885 5.08143 2.24812C4.04217 3.28738 3.45831 4.69693 3.45831 6.16667V8.56767C3.74774 8.52205 4.04032 8.49943 4.33331 8.5H5.20831Z" fill={color} />
      <path d="M10.4817 14.3333C10.4817 13.9466 10.328 13.5756 10.0545 13.3021C9.78102 13.0286 9.41009 12.875 9.02332 12.875H9.01165C8.82046 12.8752 8.63117 12.913 8.45463 12.9864C8.2781 13.0598 8.11778 13.1674 7.98285 13.3028C7.84793 13.4383 7.74106 13.599 7.66835 13.7759C7.59565 13.9527 7.55854 14.1421 7.55915 14.3333C7.56178 14.5511 7.61412 14.7655 7.71218 14.96C7.81024 15.1545 7.95143 15.324 8.12498 15.4557V17.8333C8.12498 18.0654 8.21717 18.288 8.38127 18.4521C8.54536 18.6161 8.76792 18.7083 8.99998 18.7083C9.23205 18.7083 9.45461 18.6161 9.6187 18.4521C9.7828 18.288 9.87498 18.0654 9.87498 17.8333V15.4848C10.06 15.3546 10.2114 15.1823 10.3169 14.9821C10.4223 14.7819 10.4788 14.5596 10.4817 14.3333Z" fill={color} />
    </svg>
  );
};

export default LockIcon;