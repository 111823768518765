import React, { useMemo } from "react";
import { icons, IconName } from "./IconsList";

export type { IconName } from "./IconsList";

export type IconWrapperProps = {
  children?: never;
  name: IconName;
  className?: string;
  size?: number;
  width?: number;
  height?: number;
  color?: string;
  extraColors?: string[];
};

export const IconWrapper = (props: IconWrapperProps) => {
  const { className, size, width, height, name, color } = props;

  const IconComponent = icons[name];

  return (
    <IconComponent
      color={color}
      size={size}
      width={width}
      height={height}
      className={className}
    />
  );
};