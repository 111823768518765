import { gql } from '@apollo/client';

const UPGRADE_DB = gql`
    mutation UpgradeDB {
        upgradeDB {
            result
            errors
        }
    }
`;

export default UPGRADE_DB;