import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { userInfoVar } from '../../App';

let timeout: NodeJS.Timeout;

const ReactNativeReady = () => {

  const [isRnAppReady, setRnAppReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (timeout) {
      clearInterval(timeout);
    }

    timeout = setInterval(() => {
      if ((window as any).isReactNativeApp && !isRnAppReady) {
        setRnAppReady(true);
      }
    }, 100);

    return () => {
      clearInterval(timeout);
    };
  }, [isRnAppReady]);

  const userInfo = useReactiveVar(userInfoVar);

  React.useEffect(() => {
    if (isRnAppReady && (window as any).ReactNativeWebAppLogin) {
      (window as any).ReactNativeWebAppLogin(userInfo ? JSON.stringify({
        ...userInfo
    }) : undefined);
    }
  }, [isRnAppReady, userInfo]);

  return null;
};

export default ReactNativeReady;