import { useReactiveVar } from "@apollo/client";
import { notification } from "antd";
import { CloseOutline } from "antd-mobile-icons";
import { NotificationPlacement } from "antd/lib/notification";
import React, { CSSProperties, ReactNode, useEffect } from "react";
import { isRTLVar, onlyPremiumAccessVar, userInfoVar } from "../../App";
import { useHistory } from "react-router-dom";
import { updateUserInfo } from "../utilities";
import { CurrentUser } from "../../graphQL/user/UserTypes";
import { USER_SUBSCRIPTION_FREE } from "../../graphQL/user/UserConstants";
import { translate } from "../../i18n";

const useErrorNotification = (
	data?: {
		result?: boolean;
		errors?: (string | null)[] | null;
	},
	notifKey = `notif${Date.now()}`
) => {
	const isRTL = useReactiveVar(isRTLVar);

	const userInfo = useReactiveVar(userInfoVar);

	const openErrorNotification = (error: string) => {
		const args: {
			message: string;
			description: string;
			closeIcon: ReactNode;
			placement?: NotificationPlacement;
			style: CSSProperties;
			key?: string;
			rtl?: boolean;
			duration?: number;
		} = {
			message: translate("notification.error"), //{tran}"Error!",
			description: error,
			closeIcon: <CloseOutline />,
			placement: "topRight",
			style: {
				backgroundColor: "#fcd5cf",
			},
			key: notifKey,
			rtl: isRTL,
			//duration: 60,
		};

		notification.error(args);
	};

	const history = useHistory();

	useEffect(() => {
		if (data && !data.result) {
			if (data.errors) {
				for (const error of data.errors) {
					if (error === "Not_Access_Premuim") {
						const currentUserInfo = {
							...userInfo,
							subscription: USER_SUBSCRIPTION_FREE,
						} as CurrentUser;

						updateUserInfo({
							needToRnLogin: true,
							currentUserInfo,
							prevUserInfo: userInfo,
						});

						// history.push("/subscription-plans");
						onlyPremiumAccessVar(true);
						break;
					} else if (error) {
						openErrorNotification(error);
					}
				}
			} else {
				openErrorNotification(translate("commonErrors.tryAgain"));
			}
		}
	}, [data]);
};

export default useErrorNotification;
