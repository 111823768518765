import { gql } from "@apollo/client";

export const PAYMENT_BASIC_FIELDS_FRAGMENT = gql`
	fragment PaymentBasicFields on PaymentBasic {
		id
		affiliate
		paymentAmount
		paymentRequestId
		createdAt
		description
	}
`;
