import { useQuery, useReactiveVar } from "@apollo/client";
import { isEqual } from "lodash";
import { useEffect, useMemo } from "react";
import { userInfoVar } from "../../../App";
import useErrorNotification from "../../../helper/hooks/useErrorNotification";
import { updateUserInfo } from "../../../helper/utilities";
import GET_CURRENT_USER from "../GetCurrentUser";
import { GetCurrentUserData } from "../UserTypes";

const useUpdatedUser = () => {
	const authenticatedUser = useReactiveVar(userInfoVar);
	const token = authenticatedUser?.token;

	const { loading, data } = useQuery<GetCurrentUserData>(GET_CURRENT_USER, {
		fetchPolicy: "cache-and-network",
		//the user will be update when we open the app
		onCompleted({ getCurrentUser }) {
			if (getCurrentUser && token) {
				const updatedUser = {
					...getCurrentUser,
					token,
				};

				updateUserInfo({
					needToRnLogin: true,
					currentUserInfo: updatedUser,
					prevUserInfo: authenticatedUser,
				});
			}
		},
	});

	const dataForNotify = useMemo(() => {
		const res = {
			result: !!data?.getCurrentUser,
		};

		return data ? res : undefined;
	}, [data]);

	// useErrorNotification(dataForNotify);

	return {
		loading,
		currentUser: data?.getCurrentUser,
	};
};

export default useUpdatedUser;
