import React from 'react';
import { IconProps } from '../IconsList';

const ProgressIcon = (props: IconProps) => {

  const { color = "white", className, width = 30, height = 31, size, extraColors = ["#6995ED"] } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.000244141" y="0.307448" width="29.9998" height="30.0009" rx="14.9999" fill={extraColors[0]} />
      <path d="M6.80005 18.7853L10.9711 14.5459C11.0074 14.5089 11.067 14.5088 11.1035 14.5456L15.2779 18.7494C15.3142 18.786 15.3733 18.7861 15.4098 18.7497L22.3418 11.8306M22.3418 11.8306H17.8095M22.3418 11.8306V16.3368" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ProgressIcon;