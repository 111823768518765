import { gql } from '@apollo/client';

const SIGN_OUT_ALL = gql`
    mutation SignOutAll {
        signOutAll {
            result
            errors
        }
    }
`;

export default SIGN_OUT_ALL;