/**
 * @RestrictedRoute Component
 * Only users signed in, have access to these pages and other users are redirected to login page
 */
import { useReactiveVar } from "@apollo/client";
import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { userInfoVar } from "../App";
//import ReactNativeCheckSubscription from "../reactNative/checkSubscription/ReactNativeCheckSubscription";
import AthenticatedUser from "./AthenticatedUser";
import RouteWrapper from "./RouteWrapper";
import { AutoNavigate } from "./AutoNavigate";
import I18n from "i18n-js";

type Props = {
	component: any;
	componentProps?: React.ComponentProps<any>;
	access?: string;
} & RouteProps;

const RestrictedRoute = (props: Props) => {
	const { component: Component, componentProps, access, ...rest } = props;

	const userInfo = useReactiveVar(userInfoVar);

	const isAuth = !!(userInfo && userInfo.token && userInfo.id);

	return (
		<Route
			{...rest}
			render={(cmpProps) =>
				isAuth ? (
					<RouteWrapper>
						<AutoNavigate {...props} {...cmpProps} />
						{/*<ReactNativeCheckSubscription />*/}
						<AthenticatedUser />
					</RouteWrapper>
				) : (
					<Redirect
						to={{
							pathname:
								I18n.locale === "fa-IR"
									? "/phone-auth"
									: "/sign-up",
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
};

export default RestrictedRoute;
