import React from "react";
import useUpdatedUser from "../graphQL/user/hooks/useUpdatedUser";
import useUserTotalStars from "../graphQL/userLessonStats/hooks/useUserTotalStars";

const AthenticatedUser = () => {

    useUpdatedUser();

    useUserTotalStars();

    return null;
};

export default AthenticatedUser;