import React from 'react';
import { IconProps } from '../IconsList';

const BugIcon = (props: IconProps) => {

  //doesn't support color for now
  //TODO we can add support for color and bg color
  const { color = "#263238", className, width = 260, height = 249, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.06266 1.83773C8.58766 1.39523 9.43516 1.39523 9.94515 1.83773L11.1302 2.85023C11.3552 3.03773 11.7827 3.19523 12.0827 3.19523H13.3577C14.1527 3.19523 14.8052 3.84773 14.8052 4.64273V5.91773C14.8052 6.21773 14.9627 6.63773 15.1502 6.86273L16.1627 8.04773C16.6052 8.57273 16.6052 9.42023 16.1627 9.93023L15.1502 11.1152C14.9627 11.3402 14.8052 11.7602 14.8052 12.0602V13.3352C14.8052 14.1302 14.1527 14.7827 13.3577 14.7827H12.0827C11.7827 14.7827 11.3627 14.9402 11.1377 15.1277L9.95266 16.1402C9.42766 16.5827 8.58016 16.5827 8.07016 16.1402L6.88516 15.1277C6.66016 14.9402 6.23266 14.7827 5.94016 14.7827H4.62766C3.83266 14.7827 3.18016 14.1302 3.18016 13.3352V12.0527C3.18016 11.7602 3.03016 11.3327 2.84266 11.1152L1.83016 9.92273C1.39516 9.40523 1.39516 8.56523 1.83016 8.04773L2.84266 6.85523C3.03016 6.63023 3.18016 6.21023 3.18016 5.91773V4.65023C3.18016 3.85523 3.83266 3.20273 4.62766 3.20273H5.92516C6.22516 3.20273 6.64516 3.04523 6.87016 2.85773L8.06266 1.83773Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9 6.09766V9.72016" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.99414 12.0005H9.00088" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default BugIcon;