import React from 'react';
import { IconProps } from '../IconsList';

const NoUserIcon = (props: IconProps) => {

  const { color = "#CFCFCF", className, width = 32, height = 32, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2444_33913)">
        <rect width="32" height="32" rx="8" fill={color} />
        <path d="M16.0341 25.2436C19.7264 25.2436 22.7196 21.0978 22.7196 15.9837C22.7196 10.8696 19.7264 6.72375 16.0341 6.72375C12.3418 6.72375 9.34863 10.8696 9.34863 15.9837C9.34863 21.0978 12.3418 25.2436 16.0341 25.2436Z" fill="white" />
        <path d="M15.9283 26.6519C10.331 26.6519 5.32815 27.9948 2 30.1021C3.58051 32.3412 5.67388 34.1696 8.10523 35.4345C10.5366 36.6995 13.2353 37.3643 15.976 37.3733C18.7167 37.3824 21.4198 36.7356 23.8595 35.4868C26.2992 34.238 28.4047 32.4235 30 30.195C26.6825 28.032 21.608 26.6519 15.9283 26.6519Z" fill="white" />
        <path d="M17.0514 18.1812H15.0131C13.6031 18.1812 12.46 19.3242 12.46 20.7343V26.544C12.46 27.9541 13.6031 29.0972 15.0131 29.0972H17.0514C18.4615 29.0972 19.6046 27.9541 19.6046 26.544V20.7343C19.6046 19.3242 18.4615 18.1812 17.0514 18.1812Z" fill="white" />
        <path d="M10.4021 16.5094C10.6038 17.9956 10.2721 19.2828 9.66695 19.3863C9.06183 19.4898 8.40629 18.3698 8.20458 16.8836C8.00287 15.3973 8.33463 14.1128 8.9424 14.0093C9.55017 13.9058 10.2031 15.0258 10.4021 16.5094Z" fill="white" />
        <path d="M21.6635 16.5094C21.4645 17.9956 21.7936 19.2828 22.4013 19.3863C23.0091 19.4898 23.662 18.3698 23.8611 16.8836C24.0601 15.3973 23.731 14.1128 23.1259 14.0093C22.5208 13.9058 21.8652 15.0258 21.6635 16.5094Z" fill="white" />
        <path d="M9.58033 15.6201C9.35739 14.1374 9.13091 12.6546 8.9009 11.1719C8.81862 10.6411 8.74166 10.0652 8.93806 9.55827C9.13445 9.05135 9.71569 8.63998 10.2279 8.83638C10.0236 7.80662 11.1648 7.04491 12.16 6.71316C13.8195 6.10092 15.5975 5.87845 17.3566 6.06292C19.1254 6.26084 20.7665 7.08 21.9879 8.37458C22.3708 8.78233 22.667 9.26358 22.8584 9.78917C23.2008 10.8057 22.9832 11.9124 22.7629 12.974L22.2321 15.4768C22.2074 15.6693 22.1333 15.8521 22.0171 16.0076C21.8101 16.2385 21.4598 16.2544 21.1492 16.257L11.2046 16.2783C10.5809 16.2783 9.76611 16.1243 9.68384 15.506" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_2444_33913">
          <rect width="32" height="32" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoUserIcon;