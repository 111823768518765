import React from 'react';
import { IconProps } from '../IconsList';

const VolumeIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 19, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.49951 7.4996V10.4996C1.49951 11.9996 2.24951 12.7496 3.74951 12.7496H4.82201C5.09951 12.7496 5.37701 12.8321 5.61701 12.9746L7.80701 14.3471C9.69701 15.5321 11.2495 14.6696 11.2495 12.4421V5.5571C11.2495 3.3221 9.69701 2.4671 7.80701 3.6521L5.61701 5.0246C5.37701 5.1671 5.09951 5.2496 4.82201 5.2496H3.74951C2.24951 5.2496 1.49951 5.9996 1.49951 7.4996Z" stroke={color} strokeWidth="1.5" />
      <path d="M13.5 6.00024C14.835 7.77774 14.835 10.2227 13.5 12.0002" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.998 4.12476C18.1655 7.01226 18.1655 10.9873 15.998 13.8748" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default VolumeIcon;