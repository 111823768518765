import React from 'react';
import { IconProps } from '../IconsList';

const DocumentIcon = (props: IconProps) => {

  const { color = "#575D62", className, width = 14, height = 14, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.33333 1.16638H4.66667C2.625 1.16638 1.75 2.33305 1.75 4.08305V9.91638C1.75 11.6664 2.625 12.833 4.66667 12.833H9.33333C11.375 12.833 12.25 11.6664 12.25 9.91638V4.08305C12.25 2.33305 11.375 1.16638 9.33333 1.16638ZM4.66667 7.14555H7C7.23917 7.14555 7.4375 7.34388 7.4375 7.58305C7.4375 7.82222 7.23917 8.02055 7 8.02055H4.66667C4.4275 8.02055 4.22917 7.82222 4.22917 7.58305C4.22917 7.34388 4.4275 7.14555 4.66667 7.14555ZM9.33333 10.3539H4.66667C4.4275 10.3539 4.22917 10.1555 4.22917 9.91638C4.22917 9.67721 4.4275 9.47888 4.66667 9.47888H9.33333C9.5725 9.47888 9.77083 9.67721 9.77083 9.91638C9.77083 10.1555 9.5725 10.3539 9.33333 10.3539ZM10.7917 5.39555H9.625C8.73833 5.39555 8.02083 4.67805 8.02083 3.79138V2.62472C8.02083 2.38555 8.21917 2.18722 8.45833 2.18722C8.6975 2.18722 8.89583 2.38555 8.89583 2.62472V3.79138C8.89583 4.19388 9.2225 4.52055 9.625 4.52055H10.7917C11.0308 4.52055 11.2292 4.71888 11.2292 4.95805C11.2292 5.19722 11.0308 5.39555 10.7917 5.39555Z" fill={color}/>
    </svg>
  );
};

export default DocumentIcon;