import React from 'react';
import { IconProps } from '../IconsList';

const StarIcon = (props: IconProps) => {

  const { color = "#FEA419", className, width = 86, height = 83, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 86 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M70.7855 78.1174C71.2956 81.0896 68.1764 83.3567 65.5069 81.9537L44.6953 71.0156C43.6355 70.4586 42.3697 70.4586 41.3098 71.0156L20.4959 81.9548C17.827 83.3578 14.7079 81.0918 15.2171 78.12L19.187 54.9474C19.3891 53.7679 18.998 52.5639 18.1411 51.7282L1.30377 35.3081C-0.854969 33.2029 0.337051 29.5362 3.32106 29.1028L26.5946 25.7225C27.7797 25.5504 28.8043 24.806 29.3343 23.7321L39.7398 2.64738C41.0744 -0.056859 44.9307 -0.0568553 46.2653 2.64738L56.6711 23.7321C57.2012 24.806 58.2258 25.5504 59.4108 25.7225L82.6823 29.1025C85.6665 29.5359 86.8584 33.2034 84.6991 35.3085L67.8555 51.7278C66.9979 52.5639 66.6064 53.7682 66.8091 54.9485L70.7855 78.1174Z" fill={color} />
    </svg>
  );
};

export default StarIcon;