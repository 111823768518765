import React from 'react';
import { IconProps } from '../IconsList';

const ComingSoonIcon = (props: IconProps) => {

  const { color = "#E04918", className, width = 75, height = 75, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.88022 1.00853C8.88022 1.00853 6.2173 0.571964 2.78032 3.9119C1.58003 5.07823 1.52588e-05 7.16096 1.52588e-05 7.16096H16.6029C15.8386 6.00924 14.5371 4.36253 12.4903 2.91706C11.1667 1.98227 9.89714 1.38991 8.88022 1.00907V1.00853Z" fill={color} />
      <path d="M73.9848 66.1088C73.9848 66.1088 74.4213 68.772 71.0817 72.2093C69.9155 73.4097 67.8329 74.9899 67.8329 74.9899V59.2596C68.6248 59.4917 70.0355 60.3264 71.3711 61.5657C73.2113 63.273 73.7949 65.2914 73.9842 66.1088H73.9848Z" fill={color} />
      <path d="M8.87156 1.00814C10.2309 1.75955 11.4744 2.70353 12.5644 3.80928L70.0301 61.5636C71.4544 62.9956 72.7791 64.5233 73.9945 66.1375V48.7762L74.0032 48.7838V8.21505C74.0032 1.96402 70.0818 1.00814 67.7324 1.00814H8.87156Z" fill="#FD521B" />
      <path d="M44.6833 7.21055C45.9876 5.9061 47.7003 5.9124 48.8859 7.09765C49.3186 7.53036 49.6685 8.06969 49.8223 8.5204L49.0163 9.16048C48.809 8.68008 48.5895 8.25951 48.1568 7.82679C47.3509 7.02073 46.3429 7.2101 45.5131 8.04C44.6833 8.8699 44.4935 9.87793 45.2999 10.684C45.7326 11.1167 46.1536 11.3362 46.6335 11.5436L45.9935 12.3496C45.5428 12.1954 45.0035 11.8458 44.5709 11.4131C43.3853 10.2274 43.3794 8.51455 44.6833 7.2101L44.6833 7.21055Z" fill="white" />
      <path d="M48.24 10.7676C49.5443 9.46316 51.257 9.46946 52.4426 10.6547C53.6282 11.8404 53.634 13.5537 52.3297 14.8577C51.0258 16.1617 49.3127 16.1559 48.1271 14.9702C46.9415 13.7845 46.9357 12.0716 48.2395 10.7672L48.24 10.7676ZM49.0698 11.5975C48.24 12.4274 48.0502 13.435 48.8566 14.2415C49.6626 15.0476 50.6705 14.8582 51.5003 14.0283C52.3302 13.1984 52.52 12.1908 51.7135 11.3843C50.9076 10.5782 49.8996 10.7681 49.0698 11.598L49.0698 11.5975Z" fill="white" />
      <path d="M54.3159 20.9932L56.7402 17.8571L53.7704 18.705L54.606 15.7349L51.4765 18.1535L50.6881 17.365L55.412 13.7903L56.0345 14.4129L55.1574 17.3178L58.0557 16.4343L58.6782 17.0569L55.1039 21.7812L54.3155 20.9927L54.3159 20.9932Z" fill="white" />
      <path d="M55.792 22.4699L59.9411 18.3204L60.6999 19.0792L56.5508 23.2287L55.792 22.4699Z" fill="white" />
      <path d="M60.1193 26.7971L61.0674 22.0548L58.2222 24.9003L57.4635 24.1414L61.6126 19.9919L62.3596 20.7391L61.4529 25.1908L64.1322 22.5113L64.8909 23.2702L60.7418 27.4197L60.1193 26.7971Z" fill="white" />
      <path d="M63.4858 26.0032C64.7839 24.705 66.4912 24.705 67.706 25.92C68.109 26.323 68.5533 26.9572 68.7076 27.4079L67.9012 28.048C67.7114 27.5973 67.3619 27.0346 66.9945 26.6666C66.1291 25.8012 65.1333 26.0144 64.3269 26.8209C63.4795 27.6688 63.3136 28.7002 64.1726 29.5598C64.3444 29.7316 64.5639 29.9035 64.8068 30.0636L65.5476 29.3228L65.091 28.8662L65.7194 28.2378L66.9346 29.4532L65.0852 31.3028C64.6111 31.1724 63.8227 30.6686 63.3487 30.1945C62.1869 29.0326 62.1752 27.3135 63.4849 26.0036L63.4858 26.0032Z" fill="white" />
      <path d="M38.8391 19.3041L39.7935 18.5277C39.7993 18.9721 40.0363 19.6482 40.6588 20.2707C41.1032 20.7151 41.5125 20.8991 41.8502 20.5613C42.2712 20.1403 41.9568 19.5299 41.6249 18.8538C41.2395 18.0653 40.8068 17.2179 41.548 16.4766C42.0994 15.9251 43.1721 15.6111 44.2925 16.7316C44.8614 17.3006 45.223 18.0181 45.2941 18.3739L44.4404 19.0733C44.239 18.3856 43.9961 17.8818 43.5989 17.4846C43.2787 17.1643 42.757 16.809 42.4017 17.1643C42.0697 17.4963 42.3247 18.0122 42.6387 18.5988C43.0894 19.4408 43.6403 20.4659 42.7277 21.3791C42.2532 21.8536 41.311 22.3808 39.9122 20.9819C39.2839 20.3535 38.9223 19.6954 38.8395 19.3041L38.8391 19.3041Z" fill="white" />
      <path d="M44.055 20.8101C45.3593 19.5056 47.072 19.5119 48.2576 20.6972C49.4432 21.8829 49.449 23.5958 48.1447 24.9002C46.8408 26.2042 45.1277 26.1984 43.9421 25.0127C42.7565 23.827 42.7507 22.1141 44.0545 20.8096L44.055 20.8101ZM44.8848 21.64C44.055 22.4699 43.8652 23.4775 44.6712 24.284C45.4776 25.09 46.4851 24.9007 47.3149 24.0708C48.1447 23.2409 48.3345 22.2333 47.5281 21.4268C46.7221 20.6207 45.7142 20.8101 44.8844 21.64L44.8848 21.64Z" fill="white" />
      <path d="M48.5599 25.3155C49.8642 24.0111 51.5769 24.0174 52.7625 25.2026C53.9481 26.3883 53.954 28.1012 52.6496 29.4057C51.3458 30.7097 49.6326 30.7038 48.447 29.5181C47.2614 28.3324 47.2556 26.6195 48.5595 25.3151L48.5599 25.3155ZM49.3897 26.1454C48.5599 26.9753 48.3701 27.9829 49.1765 28.7894C49.9825 29.5955 50.9904 29.4061 51.8203 28.5762C52.6501 27.7463 52.8399 26.7387 52.0335 25.9322C51.2275 25.1262 50.2195 25.3155 49.3897 26.1454Z" fill="white" />
      <path d="M53.895 34.8001L54.8431 30.0578L51.9978 32.9033L51.2391 32.1444L55.3882 27.9949L56.1352 28.7421L55.2285 33.1938L57.9078 30.5143L58.6665 31.2731L54.5174 35.4226L53.895 34.8001Z" fill="white" />
    </svg>
  );
};

export default ComingSoonIcon;