import React from 'react';
import { IconProps } from '../IconsList';

const GeneralIcon = (props: IconProps) => {

  const { color = "#575D62", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.75293 1.87518V10.8527C2.75293 11.5877 3.09793 12.2852 3.69043 12.7277L7.59792 15.6527C8.43042 16.2752 9.57792 16.2752 10.4104 15.6527L14.3179 12.7277C14.9104 12.2852 15.2554 11.5877 15.2554 10.8527V1.87518H2.75293Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M1.50049 1.87518H16.5005" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
      <path d="M6.00049 5.99982H12.0005" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.00049 9.75018H12.0005" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default GeneralIcon;