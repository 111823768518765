import React from 'react';
import { IconProps } from '../IconsList';

const NotStartedIcon = (props: IconProps) => {

  const { color = "#575D62", className, width = 10, height = 10, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.02094 6.23746C1.46677 7.67079 2.66677 8.77496 4.15844 9.07912" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.854065 4.57503C1.06656 2.47086 2.84156 0.833359 4.9999 0.833359C7.15823 0.833359 8.93323 2.47503 9.14573 4.57503" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.8374 9.08332C7.3249 8.77915 8.52073 7.68748 8.9749 6.25832" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default NotStartedIcon;