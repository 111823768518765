import { gql } from '@apollo/client';

const USER_TOTAL_STARS = gql`
    query UserTotalStars {
        userTotalStars {
            totalStars
            errors
        }
    }
`;

export default USER_TOTAL_STARS;