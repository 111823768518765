import { useReactiveVar } from "@apollo/client";
import {
	IncompleteRegistrationData,
	incompleteRegistrationVar,
	lastVerifyRequestTimeVar,
} from "../../../App";
import { useMemo } from "react";

export const useIncompleteRegister = () => {
	const setIncompleteRegister = (data: IncompleteRegistrationData) => {
		incompleteRegistrationVar(data);

		const now = Date.now();

		lastVerifyRequestTimeVar(now);

		// we save the information in local storage
		localStorage.setItem("lastVerifyRequestTime", `${now}`);

		// we save the information in local storage
		localStorage.setItem("incompleteRegistration", JSON.stringify(data));
	};

	const removeIncompleteRegister = () => {
		localStorage.removeItem("incompleteRegistration");
		incompleteRegistrationVar(null);
	};

	const initIncompleteRegister = () => {
		const savedData = localStorage.getItem("incompleteRegistration");

		if (savedData) {
			incompleteRegistrationVar(JSON.parse(savedData));
		}
	};

	return {
		removeIncompleteRegister,
		setIncompleteRegister,
		initIncompleteRegister,
	};
};

export const useLastRequestForVerify = () => {
	const lastTime = useReactiveVar(lastVerifyRequestTimeVar);

	const remainedTime = useMemo(() => {
		if (lastTime) {
			let diff = Date.now() - lastTime;
			diff = diff / 1000;

			if (diff > 2 * 60) {
				return 0;
			}

			return Math.ceil(120 - diff);
		}

		return 0;
	}, [lastTime]);

	return remainedTime;
};
