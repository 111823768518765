import React from 'react';
import { IconProps } from '../IconsList';

const FullscreenIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.25 7.875C2.25 6 3.375 2.25 7.875 2.25" stroke={color} strokeWidth="1.5" />
      <path d="M10.125 2.25C12 2.25 15.75 3.375 15.75 7.875" stroke={color} strokeWidth="1.5" />
      <path d="M15.75 10.125C15.75 12 14.625 15.75 10.125 15.75" stroke={color} strokeWidth="1.5" />
      <path d="M7.875 15.75C6 15.75 2.25 14.625 2.25 10.125" stroke={color} strokeWidth="1.5" />
    </svg>
  );
};

export default FullscreenIcon;