import React from 'react';
import { IconProps } from '../IconsList';

const SubtitleIcon = (props: IconProps) => {

  const { color = "white", className, width = 20, height = 21, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.491 2.49669H6.50768C3.47435 2.49669 1.66602 4.30502 1.66602 7.33835V14.3134C1.66602 17.355 3.47435 19.1634 6.50768 19.1634H13.4827C16.516 19.1634 18.3243 17.355 18.3243 14.3217V7.33835C18.3327 4.30502 16.5243 2.49669 13.491 2.49669ZM5.41602 11.305H7.72435C8.06602 11.305 8.34935 11.5884 8.34935 11.93C8.34935 12.2717 8.06602 12.555 7.72435 12.555H5.41602C5.07435 12.555 4.79102 12.2717 4.79102 11.93C4.79102 11.5884 5.07435 11.305 5.41602 11.305ZM10.8077 15.6884H5.41602C5.07435 15.6884 4.79102 15.405 4.79102 15.0634C4.79102 14.7217 5.07435 14.4384 5.41602 14.4384H10.8077C11.1493 14.4384 11.4327 14.7217 11.4327 15.0634C11.4327 15.405 11.1577 15.6884 10.8077 15.6884ZM14.5827 15.6884H13.041C12.6993 15.6884 12.416 15.405 12.416 15.0634C12.416 14.7217 12.6993 14.4384 13.041 14.4384H14.5827C14.9243 14.4384 15.2077 14.7217 15.2077 15.0634C15.2077 15.405 14.9243 15.6884 14.5827 15.6884ZM14.5827 12.555H9.97435C9.63268 12.555 9.34935 12.2717 9.34935 11.93C9.34935 11.5884 9.63268 11.305 9.97435 11.305H14.5827C14.9243 11.305 15.2077 11.5884 15.2077 11.93C15.2077 12.2717 14.9243 12.555 14.5827 12.555Z" fill={color}/>
    </svg>
  );
};

export default SubtitleIcon;