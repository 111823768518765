import React from 'react';
import { IconProps } from '../IconsList';

const ConversationFillIcon = (props: IconProps) => {

  const { color = "#58B368", className, width = 28, height = 28, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.1873 14.4667V19.215C18.1873 19.635 18.1406 20.0317 18.0356 20.3933C17.6039 22.1083 16.1806 23.1817 14.2206 23.1817H11.0473L7.52394 25.5267C6.99894 25.8883 6.29894 25.5033 6.29894 24.8733V23.1817C5.10894 23.1817 4.11728 22.785 3.42894 22.0967C2.72894 21.3967 2.33228 20.405 2.33228 19.215V14.4667C2.33228 12.25 3.70894 10.7217 5.83228 10.5233C5.98394 10.5117 6.13561 10.5 6.29894 10.5H14.2206C16.6006 10.5 18.1873 12.0867 18.1873 14.4667Z" fill={color} />
      <path d="M20.7073 18.2003C22.1889 18.2003 23.4373 17.7103 24.3006 16.8353C25.1756 15.972 25.6656 14.7236 25.6656 13.242V7.29195C25.6656 4.55029 23.4489 2.33362 20.7073 2.33362H10.7906C8.04894 2.33362 5.83228 4.55029 5.83228 7.29195V8.16695C5.83228 8.49362 6.08894 8.75029 6.41561 8.75029H14.2206C17.3823 8.75029 19.9373 11.3053 19.9373 14.467V17.617C19.9373 17.9436 20.1939 18.2003 20.5206 18.2003H20.7073Z" fill={color} />
    </svg>
  );
};

export default ConversationFillIcon;