import React from 'react';
import { IconProps } from '../IconsList';

const ArrowRightIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.2501 14.9405L6.36013 10.0505C5.78263 9.47305 5.78263 8.52805 6.36013 7.95055L11.2501 3.06055" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowRightIcon;