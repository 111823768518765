import React from 'react';
import { IconProps } from '../IconsList';

const TagIcon = (props: IconProps) => {

  const { color = "#7ABE3E", className, width = 26, height = 27, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.4825 9.92509L16.575 5.01759C15.5458 3.98842 14.1267 3.43592 12.675 3.51176L7.25832 3.77176C5.09166 3.86926 3.36916 5.59176 3.26082 7.74759L3.00082 13.1643C2.93582 14.6159 3.47749 16.0351 4.50666 17.0643L9.41416 21.9718C11.4292 23.9868 14.7008 23.9868 16.7267 21.9718L21.4825 17.2159C23.5083 15.2118 23.5083 11.9401 21.4825 9.92509ZM10.2917 13.9118C8.56916 13.9118 7.17166 12.5143 7.17166 10.7918C7.17166 9.06926 8.56916 7.67176 10.2917 7.67176C12.0142 7.67176 13.4117 9.06926 13.4117 10.7918C13.4117 12.5143 12.0142 13.9118 10.2917 13.9118Z" fill={color} />
    </svg>
  );
};

export default TagIcon;