import { useReactiveVar } from "@apollo/client";
import { ErrorBlock } from "antd-mobile";
import React, { ReactElement } from "react";
import { userInfoVar } from "../../App";
import Footer from "../footer/Footer";
import { translate } from "../../i18n";

type Props = {
	can: string;
	children: ReactElement;
};

const UserAccessPage = (props: Props) => {
	const { can, children } = props;

	const userInfo = useReactiveVar(userInfoVar);

	const userCan = userInfo?.capabilities.includes(can);

	if (userCan) {
		return children;
	}

	return (
		<article>
			<ErrorBlock
				title={translate("accessPage.title")}
				description={translate("accessPage.message")}
				fullPage
			/>
			<Footer />
		</article>
	);
};

export default UserAccessPage;
