
export const CUSTOM_SIGNUP_EVENT = "metaora_signup";
export const CUSTOM_PURCHASE_SUBSCRIPTION_EVENT = "metaora_purchase_subscription";
export const CUSTOM_PURCHASE_GEM_EVENT = "metaora_purchase_gem";
export const CUSTOM_THREE_ADS_EVENT = "metaora_3ads";
export const CUSTOM_UNLOCK_LESSON_EVENT = "metaora_unlock_lesson";
export const CUSTOM_PURCHASE_COURSE_EVENT = "metaora_purchase_course";
export const CUSTOM_STREAK_EVENT = "metaora_streak";
export const CUSTOM_FINISH_UNIT_EVENT = "metaora_finish_unit";
export const CUSTOM_SHARE_REFERRAL_EVENT = "metaora_share_referral";
export const CUSTOM_GAIN_GEM_EVENT = "metaora_gain_gem";
export const CUSTOM_FINISH_LESSON_EVENT = "metaora_finish_lesson";
export const CUSTOM_5PAGE_VIEW_EVENT = "metaora_5page_view";
export const CUSTOM_5MIN_APP_USAGE_EVENT = "metaora_5min_app_usage";
export const CUSTOM_RANKUP_EVENT = "metaora_rankup";
export const CUSTOM_PREMIUM_PAGE_EVENT = "metaora_premium_page";
export const CUSTOM_DAILY_CHALLENGE_EVENT = "metaora_daily_challenge";
export const CUSTOM_GAIN_STARS_EVENT = "metaora_gain_stars";
export const CUSTOM_INTERSTITIAL_AD_EVENT = "metaora_Interstitial_ad";
export const CUSTOM_PREMIUM_BANNER_EVENT = "metaora_premium_banner";

export const GOOGLE_ANALYTICS_CUSTOM_EVENTS = [
    CUSTOM_SIGNUP_EVENT,
    CUSTOM_PURCHASE_SUBSCRIPTION_EVENT,
    CUSTOM_PURCHASE_GEM_EVENT,
    CUSTOM_THREE_ADS_EVENT,
    CUSTOM_UNLOCK_LESSON_EVENT,
    CUSTOM_PURCHASE_COURSE_EVENT,
    CUSTOM_STREAK_EVENT,
    CUSTOM_FINISH_UNIT_EVENT,
    CUSTOM_SHARE_REFERRAL_EVENT,
    CUSTOM_GAIN_GEM_EVENT,
    CUSTOM_FINISH_LESSON_EVENT,
    CUSTOM_5PAGE_VIEW_EVENT,
    CUSTOM_5MIN_APP_USAGE_EVENT,
    CUSTOM_RANKUP_EVENT,
    CUSTOM_PREMIUM_PAGE_EVENT,
    CUSTOM_DAILY_CHALLENGE_EVENT,
    CUSTOM_GAIN_STARS_EVENT,
    CUSTOM_INTERSTITIAL_AD_EVENT,
    CUSTOM_PREMIUM_BANNER_EVENT
] as const;

//this is just usefeul for webview
//we will remove when we remove webview
export const GOOGLE_ANALYTICS_PREDEFINED = [
    "logLogin",
    "logPurchase",
    "logScreenView",
    "logSearch",
    "logSelectContent",
    "logSelectItem",
    "logShare",
    "logSignUp",
    "logTutorialBegin",
    "logUnlockAchievement",
    "logLevelEnd",
    "logLevelStart",
    "logLevelUp"
] as const;
