import React from "react";
import { IconProps } from "../IconsList";

const PeopleIcon = (props: IconProps) => {
	const {
		color = "#5774CD",
		className,
		width = 16,
		height = 16,
		size,
	} = props;

	return (
		<svg
			className={className}
			width={size || width}
			height={size || height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.3113 5.91498C12.7886 5.09226 11.973 4.54345 11.0146 4.36968C9.92165 4.17149 8.84431 4.48944 7.99999 5.24637C7.15572 4.48947 6.07844 4.17135 4.98534 4.36968C4.027 4.54345 3.21137 5.09226 2.68864 5.91498C2.1877 6.70341 1.71602 8.11601 2.31365 10.3683C2.6818 11.756 3.32402 12.9795 4.12199 13.8136C4.86443 14.5896 5.7184 15 6.56931 15C6.72665 15 6.88398 14.986 7.04039 14.9576C7.11003 14.945 7.17987 14.9294 7.24795 14.9114C7.73992 14.7812 8.26005 14.7812 8.75202 14.9114C8.82011 14.9294 8.88992 14.945 8.95953 14.9576C9.96176 15.1393 10.9983 14.7331 11.878 13.8136C12.676 12.9795 13.3182 11.756 13.6864 10.3683C14.284 8.11601 13.8123 6.70341 13.3113 5.91498Z"
				fill={color}
			/>
			<path
				d="M11.2578 1.32252C11.2129 1.12865 11.0378 0.993437 10.8381 1.00025C10.7645 1.00268 9.02645 1.07339 8.15055 2.21417C7.75374 2.73097 7.61806 3.3865 7.58984 3.95583C7.72973 4.02804 7.86664 4.10819 8.00019 4.19623C8.71515 3.72496 9.52613 3.47884 10.3794 3.47884C10.6352 3.47884 10.8942 3.50203 11.1494 3.54748C11.5252 2.51301 11.2713 1.38082 11.2578 1.32252Z"
				fill={color}
			/>
			<path d="M11.1184 10.2265H13.7935H11.1184Z" fill={color} />
			<path
				d="M11.1184 10.2265H13.7935"
				stroke="white"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.20679 10.2265L4.66632 10.2265L2.20679 10.2265Z"
				fill={color}
			/>
			<path
				d="M2.20679 10.2265L4.66632 10.2265"
				stroke="white"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.6665 10.2265L5.9039 7.2803L4.6665 10.2265Z"
				fill={color}
			/>
			<path
				d="M4.6665 10.2265L5.9039 7.2803"
				stroke="white"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.14291 11.7L5.90381 7.2807L8.14291 11.7Z"
				fill={color}
			/>
			<path
				d="M8.14291 11.7L5.90381 7.2807"
				stroke="white"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.14331 11.7L9.99941 8.45921L8.14331 11.7Z"
				fill={color}
			/>
			<path
				d="M8.14331 11.7L9.99941 8.45921"
				stroke="white"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.1181 10.2265L9.99854 8.45877L11.1181 10.2265Z"
				fill={color}
			/>
			<path
				d="M11.1181 10.2265L9.99854 8.45877"
				stroke="white"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default PeopleIcon;
