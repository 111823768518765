import React from 'react';
import { IconProps } from '../IconsList';

const LanguageBigIcon = (props: IconProps) => {

  //doesn't support color for now
  //TODO we can add support for color and bg color
  const { color = "#58B368", className, width = 260, height = 249, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 260 249"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M260 135C260 202.379 197.379 249 130 249C62.6213 249 0 194.379 0 127C0 59.6213 73.6213 0 141 0C208.379 0 260 67.6213 260 135Z" fill="#E0F6E4" />
      <path d="M193.241 187.755V176.307C193.241 175.295 193.041 174.293 192.654 173.358C192.266 172.423 191.698 171.574 190.982 170.858C190.266 170.143 189.416 169.576 188.481 169.189C187.546 168.802 186.543 168.604 185.531 168.605H154.426C153.414 168.605 152.413 168.804 151.478 169.191C150.544 169.578 149.694 170.145 148.979 170.861C148.264 171.576 147.697 172.425 147.31 173.36C146.922 174.294 146.723 175.296 146.723 176.307V185.91C146.72 186.923 146.918 187.927 147.304 188.864C147.689 189.801 148.256 190.652 148.972 191.37C149.687 192.087 150.538 192.656 151.473 193.045C152.409 193.433 153.413 193.633 154.426 193.633H199.112L193.241 187.755Z" fill="white" />
      <g filter="url(#filter0_d_2478_50279)">
        <path d="M120.255 165.149V142.997C120.241 139.043 118.657 135.256 115.853 132.468C113.048 129.681 109.251 128.121 105.297 128.131H45.1442C41.1978 128.135 37.4132 129.701 34.6177 132.486C31.8222 135.272 30.2431 139.051 30.2256 142.997V161.579C30.2256 163.538 30.6115 165.478 31.3612 167.288C32.1109 169.098 33.2098 170.743 34.5952 172.128C35.9805 173.513 37.6251 174.612 39.4351 175.362C41.2451 176.112 43.1851 176.498 45.1442 176.498H131.63L120.255 165.149Z" fill="#8BD598" />
      </g>
      <g clipPath="url(#clip0_2478_50279)">
        <path d="M53.4083 152.714V160.214H50.738V142.52H53.4083V150.033H56.6313V142.52H59.3016V160.214H56.6313V152.714H53.4083Z" fill="#263238" />
        <path d="M64.9639 156.146L64.3149 160.214H61.5347L64.5459 142.52H68.2199L71.1872 160.214H68.3767L67.7524 156.146H64.9639ZM66.3664 146.574L65.3792 153.548H67.3564L66.3664 146.574Z" fill="#263238" />
        <path d="M80.7131 160.214H73.4338V142.52H76.1041V157.56H80.7131V160.214Z" fill="#263238" />
        <path d="M89.8266 160.214H82.5474V142.52H85.2176V157.56H89.8266V160.214Z" fill="#263238" />
        <path d="M95.8877 142.295C96.4467 142.285 97.0016 142.391 97.5182 142.604C98.0347 142.818 98.5019 143.136 98.8907 143.538C99.2899 143.926 99.6056 144.391 99.8184 144.906C100.031 145.42 100.137 145.973 100.128 146.53V156.188C100.128 157.31 99.6823 158.387 98.8886 159.18C98.0949 159.974 97.0184 160.42 95.896 160.42C94.7735 160.42 93.697 159.974 92.9033 159.18C92.1096 158.387 91.6637 157.31 91.6637 156.188V146.53C91.6539 145.971 91.7592 145.416 91.973 144.9C92.1869 144.384 92.5047 143.917 92.9067 143.529C93.2937 143.132 93.7576 142.817 94.2701 142.605C94.7826 142.393 95.333 142.287 95.8877 142.295ZM97.3425 146.475C97.3432 146.274 97.3042 146.075 97.2278 145.89C97.1513 145.704 97.0389 145.536 96.8971 145.394C96.7552 145.252 96.5866 145.139 96.4011 145.063C96.2156 144.987 96.0168 144.948 95.8162 144.948C95.6158 144.946 95.417 144.984 95.2317 145.061C95.0465 145.138 94.8786 145.251 94.7382 145.394C94.5919 145.533 94.476 145.701 94.3979 145.887C94.3198 146.073 94.2812 146.273 94.2845 146.475V156.16C94.2812 156.362 94.3198 156.562 94.3979 156.748C94.476 156.934 94.5919 157.102 94.7382 157.241C94.8779 157.385 95.0455 157.5 95.2308 157.578C95.416 157.656 95.6152 157.696 95.8162 157.695C96.0168 157.695 96.2156 157.656 96.4011 157.58C96.5866 157.503 96.7552 157.391 96.8971 157.249C97.0389 157.107 97.1513 156.939 97.2278 156.753C97.3042 156.568 97.3432 156.369 97.3425 156.168V146.475Z" fill="#263238" />
      </g>
      <path d="M139.895 145.577V129.723C139.893 128.321 140.168 126.933 140.704 125.637C141.24 124.341 142.025 123.163 143.016 122.172C144.007 121.18 145.184 120.393 146.479 119.856C147.775 119.319 149.163 119.043 150.565 119.043H193.645C196.477 119.043 199.194 120.168 201.197 122.171C203.2 124.174 204.325 126.891 204.325 129.723V143.036C204.325 145.869 203.2 148.586 201.197 150.589C199.194 152.592 196.477 153.717 193.645 153.717H131.744L139.895 145.577Z" fill="white" />
      <g clipPath="url(#clip1_2478_50279)">
        <path d="M158.668 134.94C158.201 135.77 157.672 136.564 157.083 137.313C156.884 137.025 156.666 136.75 156.429 136.491C157.861 134.787 159.23 131.898 160.098 129.083L161.361 129.463C161.3 129.616 161.163 129.645 160.996 129.637C160.585 130.863 160.103 132.065 159.553 133.236L159.995 133.357C159.964 133.449 159.888 133.531 159.69 133.541V143.215H158.668V134.94ZM169.097 135.304C169.218 134.742 169.369 133.89 169.506 133.069H166.476V141.952C166.476 143.047 166.081 143.245 163.948 143.245C163.869 142.882 163.741 142.532 163.568 142.203C163.948 142.219 164.314 142.219 164.609 142.219H165.218C165.416 142.219 165.477 142.158 165.477 141.96V133.074H163.023C162.625 134.094 162.1 135.06 161.461 135.95C161.227 135.713 160.966 135.503 160.684 135.325C161.772 133.905 162.676 131.513 163.197 129.128L164.46 129.463C164.429 129.571 164.323 129.637 164.125 129.616C163.928 130.46 163.684 131.293 163.395 132.109H170.113L170.646 132.125C170.493 133.255 170.265 134.373 169.964 135.472L169.097 135.304ZM164.224 135.807C164.179 135.913 164.073 135.981 163.889 135.981C163.401 137.844 162.563 139.842 161.605 141.056C161.354 140.857 161.084 140.683 160.799 140.536C161.727 139.38 162.518 137.412 162.99 135.471L164.224 135.807ZM169.749 140.933C169.459 139.503 168.576 137.313 167.667 135.686L168.564 135.365C169.523 136.992 170.422 139.137 170.726 140.571L169.749 140.933Z" fill="#263238" />
        <path d="M177.025 132.262L177.604 132.415L177.512 132.628C177.299 135.32 176.818 137.487 176.051 139.168C176.71 139.652 177.313 140.207 177.847 140.826L177.223 141.664C176.737 141.081 176.192 140.55 175.595 140.081C174.896 141.394 173.854 142.492 172.581 143.26C172.403 142.956 172.189 142.675 171.942 142.424C173.082 141.815 174.042 140.826 174.804 139.458C174.246 139.038 173.667 138.647 173.068 138.287L172.884 138.955L172.017 138.499C172.399 137.161 172.871 135.229 173.311 133.267H171.79V132.307H173.525C173.768 131.165 173.981 130.072 174.163 129.099L175.413 129.25C175.397 129.357 175.305 129.423 175.107 129.434C174.934 130.301 174.727 131.289 174.498 132.307H176.843L177.025 132.262ZM174.285 133.267C173.966 134.681 173.608 136.111 173.296 137.359C173.936 137.706 174.59 138.134 175.229 138.559C175.838 137.145 176.28 135.38 176.492 133.267H174.285ZM185.901 135.884V136.871H182.232V141.903C182.232 143.075 181.774 143.257 179.081 143.227C178.989 142.875 178.862 142.533 178.699 142.207C179.43 142.223 180.1 142.238 180.527 142.238C181.047 142.238 181.196 142.238 181.196 141.919V136.871H177.467V135.884H181.196V133.296L181.7 133.328C182.54 132.586 183.297 131.755 183.955 130.848H178.09V129.89H184.728L184.925 129.829L185.732 130.331C185.671 130.407 185.585 130.46 185.489 130.482C184.552 131.782 183.458 132.962 182.232 133.996V135.884H185.901Z" fill="#263238" />
      </g>
      <path d="M112.161 105.313V88.88C112.161 85.9475 110.996 83.1351 108.922 81.0615C106.849 78.988 104.036 77.823 101.104 77.823H56.4697C55.0168 77.8217 53.5779 78.1067 52.2352 78.6618C50.8926 79.2169 49.6725 80.0311 48.6447 81.0579C47.6169 82.0848 46.8015 83.3042 46.2452 84.6463C45.6889 85.9885 45.4026 87.4271 45.4026 88.88V102.66C45.4026 105.596 46.5686 108.411 48.6441 110.486C50.7195 112.562 53.5345 113.728 56.4697 113.728H120.606L112.161 105.313Z" fill="white" />
      <path d="M70.0469 97.5791C69.2338 93.1076 66.9981 94.6997 65.9818 96.0547L64.9656 95.5466C65.3043 94.5303 66.5916 92.1929 69.0306 93.0059C71.4696 93.8189 71.0631 98.0872 70.555 99.6116C68.929 98.7986 68.0143 98.426 67.5062 98.5954C64.9656 99.1035 65.6431 102.66 66.4899 104.693L64.9656 105.201C64.4575 104.185 63.6444 101.543 64.4575 99.1035C65.2705 96.6645 68.5225 97.071 70.0469 97.5791Z" fill="#263238" />
      <path d="M91.3883 93.0059L89.8639 93.5141C90.6769 95.9531 90.5414 97.2404 90.372 97.5791C90.372 98.0872 87.8314 98.5954 87.8314 97.5791C87.8314 96.7661 88.1701 95.8853 88.3395 95.5466L86.8151 95.0385C86.6457 95.716 86.307 97.1726 86.307 97.5791C86.307 98.0872 83.7663 98.5954 83.7663 97.5791C83.7663 96.7661 84.1051 95.8853 84.2745 95.5466L82.7501 95.0385L82.2419 97.5791C81.0224 98.7986 79.7013 98.426 79.1932 98.0872C78.7867 97.6807 78.685 91.4816 78.685 88.4328H77.1606C77.5671 94.9368 77.33 95.8853 77.1606 95.5466C76.6525 94.5303 74.4506 91.8203 73.0956 90.4653L72.0793 91.4816C73.7054 93.1076 75.8056 95.8853 76.6525 97.071C76.246 97.884 73.7731 98.0872 72.5875 98.0872L72.0793 99.1035C75.7379 99.9165 77.33 98.5954 77.6688 97.5791C77.6688 99.6116 78.685 99.6116 80.2094 99.6116C82.2419 99.6116 82.9194 99.1035 83.2582 98.5954C84.4777 100.628 86.4764 99.781 87.3232 99.1035C88.1362 100.323 90.2026 99.9504 90.8801 99.6116C93.3192 98.7986 92.2352 94.6997 91.3883 93.0059Z" fill="#263238" />
      <g filter="url(#filter1_d_2478_50279)">
        <path d="M139.325 91.0514V68.9831C139.325 67.0321 139.71 65.1001 140.457 63.2977C141.204 61.4953 142.299 59.8578 143.679 58.4788C145.06 57.0998 146.698 56.0063 148.501 55.2609C150.304 54.5154 152.236 54.1326 154.187 54.1344H214.151C216.101 54.1344 218.032 54.5184 219.834 55.2647C221.635 56.0109 223.272 57.1046 224.651 58.4835C226.03 59.8623 227.123 61.4992 227.87 63.3008C228.616 65.1023 229 67.0332 229 68.9831V87.4945C229.005 89.4479 228.625 91.383 227.881 93.1891C227.137 94.9952 226.044 96.6368 224.665 98.0199C223.286 99.4029 221.647 100.5 219.843 101.249C218.039 101.998 216.105 102.383 214.151 102.383H128.007L139.325 91.0514Z" fill="#8BD598" />
      </g>
      <g clipPath="url(#clip2_2478_50279)">
        <path d="M160.615 79.4613V88.3611H157.445V67.3592H160.615V76.269H164.44V67.3592H167.615V88.3611H164.44V79.4613H160.615Z" fill="#263238" />
        <path d="M170.445 88.3611V67.3394H179.472V70.5268H173.615V76.269H177.894V79.4439H173.615V85.1763H179.472V88.3611H170.445Z" fill="#263238" />
        <path d="M190.291 88.3611H181.652V67.3592H184.822V85.2184H190.279L190.291 88.3611Z" fill="#263238" />
        <path d="M201.101 88.3611H192.472V67.3592H195.644V85.2184H201.101V88.3611Z" fill="#263238" />
        <path d="M208.307 67.0913C208.971 67.0814 209.631 67.2084 210.244 67.4644C210.857 67.7205 211.411 68.1001 211.871 68.5796C212.345 69.0407 212.719 69.5935 212.971 70.2043C213.224 70.815 213.349 71.4708 213.34 72.1316V83.5838C213.354 84.253 213.235 84.9184 212.99 85.541C212.744 86.1635 212.376 86.7307 211.908 87.2092C211.44 87.6878 210.881 88.068 210.264 88.3275C209.647 88.5871 208.985 88.7208 208.315 88.7208C207.646 88.7208 206.983 88.5871 206.367 88.3275C205.75 88.068 205.191 87.6878 204.723 87.2092C204.255 86.7307 203.887 86.1635 203.641 85.541C203.395 84.9184 203.276 84.253 203.291 83.5838V72.1192C203.28 71.456 203.405 70.7975 203.659 70.1848C203.913 69.572 204.29 69.018 204.767 68.5573C205.226 68.0851 205.777 67.7116 206.386 67.4595C206.994 67.2075 207.648 67.0822 208.307 67.0913ZM210.043 72.0522C210.044 71.8142 209.998 71.5783 209.907 71.3581C209.817 71.138 209.683 70.938 209.515 70.7697C209.347 70.6013 209.147 70.468 208.926 70.3773C208.706 70.2867 208.47 70.2405 208.232 70.2415C207.992 70.2377 207.754 70.2826 207.532 70.3734C207.31 70.4642 207.109 70.599 206.94 70.7698C206.765 70.9343 206.627 71.1332 206.533 71.3539C206.439 71.5746 206.392 71.8124 206.394 72.0522V83.5516C206.39 83.7907 206.436 84.028 206.528 84.2487C206.62 84.4694 206.757 84.6686 206.93 84.834C207.099 85.0048 207.3 85.1396 207.522 85.2304C207.744 85.3212 207.982 85.3661 208.222 85.3623C208.46 85.3633 208.696 85.3171 208.917 85.2265C209.137 85.1358 209.337 85.0025 209.505 84.8341C209.673 84.6658 209.807 84.4658 209.897 84.2457C209.988 84.0255 210.034 83.7896 210.033 83.5516L210.043 72.0522Z" fill="#263238" />
      </g>
      <path d="M82.187 213.969V200.239C82.187 197.788 83.1784 195.439 84.9431 193.706C86.7077 191.973 89.1011 191 91.5968 191H129.582C130.818 190.999 132.043 191.237 133.185 191.701C134.328 192.165 135.366 192.845 136.241 193.703C137.116 194.561 137.809 195.58 138.283 196.701C138.756 197.823 139 199.025 139 200.239V211.753C139 214.205 138.008 216.557 136.241 218.292C134.475 220.026 132.08 221 129.582 221H75L82.187 213.969Z" fill="white" />
      <g filter="url(#filter2_d_2478_50279)">
        <path d="M69.5088 54.4845V39.8482C69.5088 38.5542 69.7639 37.2728 70.2595 36.0774C70.7551 34.882 71.4815 33.796 72.3971 32.8814C73.3128 31.9668 74.3998 31.2415 75.596 30.7471C76.7921 30.2527 78.074 29.9989 79.3684 30H119.149C120.443 30 121.724 30.2547 122.919 30.7497C124.114 31.2446 125.2 31.97 126.115 32.8845C127.029 33.799 127.755 34.8846 128.25 36.0794C128.745 37.2743 129 38.5549 129 39.8482V52.1255C129.003 53.421 128.751 54.7045 128.258 55.9023C127.764 57.1002 127.039 58.189 126.124 59.1062C125.209 60.0235 124.122 60.7513 122.925 61.2478C121.728 61.7444 120.445 62 119.149 62H62L69.5088 54.4845Z" fill="#8BD598" />
      </g>
      <defs>
        <filter id="filter0_d_2478_50279" x="10.2256" y="114.131" width="141.404" height="88.3661" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.545098 0 0 0 0 0.835294 0 0 0 0 0.596078 0 0 0 0.7 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2478_50279" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2478_50279" result="shape" />
        </filter>
        <filter id="filter1_d_2478_50279" x="108.007" y="40.1343" width="140.993" height="88.2487" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.545098 0 0 0 0 0.835294 0 0 0 0 0.596078 0 0 0 0.7 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2478_50279" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2478_50279" result="shape" />
        </filter>
        <filter id="filter2_d_2478_50279" x="42" y="16" width="107" height="72" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.545098 0 0 0 0 0.835294 0 0 0 0 0.596078 0 0 0 0.7 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2478_50279" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2478_50279" result="shape" />
        </filter>
        <clipPath id="clip0_2478_50279">
          <rect width="49.3902" height="18.1308" fill="white" transform="translate(50.738 142.295)" />
        </clipPath>
        <clipPath id="clip1_2478_50279">
          <rect width="29.4715" height="14.177" fill="white" transform="translate(156.429 129.083)" />
        </clipPath>
        <clipPath id="clip2_2478_50279">
          <rect width="55.8943" height="21.5228" fill="white" transform="translate(157.445 67.0913)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LanguageBigIcon;