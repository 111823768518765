import React from 'react';
import { IconProps } from '../IconsList';

const GrammerFillIcon = (props: IconProps) => {

  const { color = "#58B368", className, width = 28, height = 28, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="8.75" y="10.5" width="11.375" height="3.5" fill={color} />
      <path d="M19.8323 2.83459H8.16561C4.66561 2.83459 2.33228 5.16793 2.33228 8.66793V15.6679C2.33228 19.1679 4.66561 21.5013 8.16561 21.5013H12.8323L18.0239 24.9546C18.7939 25.4679 19.8323 24.9196 19.8323 23.9863V21.5013C23.3323 21.5013 25.6656 19.1679 25.6656 15.6679V8.66793C25.6656 5.16793 23.3323 2.83459 19.8323 2.83459ZM18.0823 13.1246H9.91561C9.43728 13.1246 9.04061 12.7279 9.04061 12.2496C9.04061 11.7713 9.43728 11.3746 9.91561 11.3746H18.0823C18.5606 11.3746 18.9573 11.7713 18.9573 12.2496C18.9573 12.7279 18.5606 13.1246 18.0823 13.1246Z" fill={color} />
      <path d="M8.21207 10.1751C6.64313 10.1751 5.38428 11.4391 5.38428 13.0061C5.38428 14.5731 6.64313 15.8371 8.21207 15.8371C9.17548 15.8371 9.55208 15.1569 9.55208 15.1569V15.7757H11.0399V13.006C11.0399 11.439 9.78101 10.175 8.21207 10.175V10.1751ZM8.21207 11.6553C8.96008 11.6553 9.5597 12.2584 9.5597 13.0061C9.5597 13.7539 8.96008 14.357 8.21207 14.357C7.46405 14.357 6.86443 13.7539 6.86443 13.0061C6.86443 12.2584 7.46405 11.6553 8.21207 11.6553Z" fill="white" />
      <path d="M11.3999 8.2258V13.0064C11.3999 14.5734 12.6597 15.8383 14.2286 15.8383C15.7976 15.8383 17.0541 14.5734 17.0541 13.0064C17.0541 11.4393 15.7976 10.1744 14.2286 10.1744C13.2652 10.1744 12.8873 10.8564 12.8873 10.8564V8.22571L11.3999 8.2258ZM14.2286 11.6554C14.9766 11.6554 15.5764 12.2587 15.5764 13.0064C15.5764 13.7542 14.9767 14.3575 14.2286 14.3575C13.4806 14.3575 12.8808 13.7542 12.8808 13.0064C12.8808 12.2587 13.4806 11.6554 14.2286 11.6554Z" fill="white" />
      <path d="M20.1252 10.1751C18.5562 10.1751 17.2974 11.4391 17.2974 13.0061C17.2974 14.5731 18.5562 15.8371 20.1252 15.8371C21.4794 15.8371 22.603 14.8953 22.8851 13.6298H21.3209C21.0966 14.0613 20.6472 14.3569 20.1252 14.3569C19.3771 14.3569 18.7775 13.7538 18.7775 13.006C18.7775 12.2583 19.3771 11.6552 20.1252 11.6552C20.6488 11.6552 21.1005 11.9519 21.3241 12.3855H22.885C22.6042 11.1185 21.4805 10.175 20.1251 10.175L20.1252 10.1751Z" fill="white" />
    </svg>
  );
};

export default GrammerFillIcon;