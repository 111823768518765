import React from 'react';
import { IconProps } from '../IconsList';

const MuteIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 18, height = 18, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.49921 7.61947V10.6195C1.49921 12.1195 2.24921 12.8695 3.74921 12.8695H4.82171C5.09921 12.8695 5.37671 12.952 5.61671 13.0945L7.80671 14.467C9.69671 15.652 11.2492 14.7895 11.2492 12.562V5.67697C11.2492 3.44197 9.69671 2.58697 7.80671 3.77197L5.61671 5.14447C5.37671 5.28697 5.09921 5.36947 4.82171 5.36947H3.74921C2.24921 5.36947 1.49921 6.11947 1.49921 7.61947Z" stroke={color} strokeWidth="1.5" />
      <path d="M16.5005 10.5894L13.5305 7.61938" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.47 7.64917L13.5 10.6192" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default MuteIcon;