import React from 'react';
import { IconProps } from '../IconsList';

const OrderFillIcon = (props: IconProps) => {

  const { color = "#58B368", className, width = 28, height = 28, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.8892 2.33325H9.11256C4.86589 2.33325 2.33423 4.86492 2.33423 9.11159V18.8766C2.33423 23.1349 4.86589 25.6666 9.11256 25.6666H18.8776C23.1242 25.6666 25.6559 23.1349 25.6559 18.8883V9.11159C25.6676 4.86492 23.1359 2.33325 18.8892 2.33325ZM15.5526 19.8333H12.4376C11.9592 19.8333 11.5626 19.4366 11.5626 18.9583C11.5626 18.4799 11.9592 18.0833 12.4376 18.0833H15.5526C16.0309 18.0833 16.4276 18.4799 16.4276 18.9583C16.4276 19.4366 16.0426 19.8333 15.5526 19.8333ZM18.6676 14.8749H9.33423C8.8559 14.8749 8.45923 14.4783 8.45923 13.9999C8.45923 13.5216 8.8559 13.1249 9.33423 13.1249H18.6676C19.1459 13.1249 19.5426 13.5216 19.5426 13.9999C19.5426 14.4783 19.1459 14.8749 18.6676 14.8749ZM21.0009 9.91659H7.0009C6.52256 9.91659 6.1259 9.51992 6.1259 9.04159C6.1259 8.56325 6.52256 8.16659 7.0009 8.16659H21.0009C21.4792 8.16659 21.8759 8.56325 21.8759 9.04159C21.8759 9.51992 21.4792 9.91659 21.0009 9.91659Z" fill={color} />
    </svg>
  );
};

export default OrderFillIcon;