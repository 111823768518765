import React from 'react';
import { IconProps } from '../IconsList';

const LanguageIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 16, height = 17, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.99998 15.1663C11.6819 15.1663 14.6666 12.1816 14.6666 8.49967C14.6666 4.81778 11.6819 1.83301 7.99998 1.83301C4.31808 1.83301 1.33331 4.81778 1.33331 8.49967C1.33331 12.1816 4.31808 15.1663 7.99998 15.1663Z" stroke={color} strokeOpacity="0.9" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.33336 2.5H6.00002C4.70002 6.39333 4.70002 10.6067 6.00002 14.5H5.33336" stroke={color} strokeOpacity="0.9" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 2.5C11.3 6.39333 11.3 10.6067 10 14.5" stroke={color} strokeOpacity="0.9" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2 11.1667V10.5C5.89333 11.8 10.1067 11.8 14 10.5V11.1667" stroke={color} strokeOpacity="0.9" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2 6.50039C5.89333 5.20039 10.1067 5.20039 14 6.50039" stroke={color} strokeOpacity="0.9" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default LanguageIcon;