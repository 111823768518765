import React from 'react';
import { IconProps } from '../IconsList';

const CompleteIcon = (props: IconProps) => {

  const { color = "#58B368", className, width = 12, height = 13, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.5765 5.76503L10.7832 4.84336C10.6315 4.66836 10.509 4.3417 10.509 4.10836V3.1167C10.509 2.49836 10.0015 1.99086 9.38318 1.99086H8.39152C8.16402 1.99086 7.83152 1.86836 7.65652 1.7167L6.73485 0.923362C6.33235 0.579196 5.67318 0.579196 5.26485 0.923362L4.34902 1.72253C4.17402 1.86836 3.84152 1.99086 3.61402 1.99086H2.60485C1.98652 1.99086 1.47902 2.49836 1.47902 3.1167V4.1142C1.47902 4.3417 1.35652 4.66836 1.21068 4.84336L0.423184 5.77086C0.0848503 6.17336 0.0848503 6.8267 0.423184 7.2292L1.21068 8.1567C1.35652 8.3317 1.47902 8.65836 1.47902 8.88586V9.88336C1.47902 10.5017 1.98652 11.0092 2.60485 11.0092H3.61402C3.84152 11.0092 4.17402 11.1317 4.34902 11.2834L5.27068 12.0767C5.67318 12.4209 6.33235 12.4209 6.74068 12.0767L7.66235 11.2834C7.83735 11.1317 8.16402 11.0092 8.39735 11.0092H9.38902C10.0073 11.0092 10.5149 10.5017 10.5149 9.88336V8.8917C10.5149 8.6642 10.6374 8.3317 10.789 8.1567L11.5823 7.23503C11.9207 6.83253 11.9207 6.16753 11.5765 5.76503ZM8.42652 5.39753L5.60902 8.21503C5.52735 8.2967 5.41652 8.34336 5.29985 8.34336C5.18318 8.34336 5.07235 8.2967 4.99068 8.21503L3.57902 6.80336C3.40985 6.6342 3.40985 6.3542 3.57902 6.18503C3.74818 6.01586 4.02818 6.01586 4.19735 6.18503L5.29985 7.28753L7.80818 4.7792C7.97735 4.61003 8.25735 4.61003 8.42652 4.7792C8.59568 4.94836 8.59568 5.22836 8.42652 5.39753Z" fill={color}/>
    </svg>
  );
};

export default CompleteIcon;