import React from 'react';
import { IconProps } from '../IconsList';

const AdjustIcon = (props: IconProps) => {

  const { color = "#BDC0C3", className, width = 18, height = 19, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.7998 11.8999H13.1998" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <rect x="4.75" y="10.75" width="2.5" height="2.5" rx="1.25" stroke={color} strokeWidth="1.5"/>
      <path d="M10.2002 6.69995H4.8002" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <rect x="-0.75" y="0.75" width="2.5" height="2.5" rx="1.25" transform="matrix(-1 0 0 1 12.5 4.80005)" stroke={color} strokeWidth="1.5"/>
      <path d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default AdjustIcon;