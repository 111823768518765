import clsx from "clsx";
import { CloseOutline } from "antd-mobile-icons";
import React, { useEffect, useRef, useState, forwardRef } from "react";
import css from "./ActionSheet.module.css";
import UiElement from "../uiElement/UiElement";
import { isRTLVar, pullToRefreshDisabledVar } from "../../App";
import { useReactiveVar } from "@apollo/client";

type Props = {
	children: React.ReactNode;
	visible: boolean;
	hasOverlay?: boolean;
	onCancel?: () => void;
	showCloseIcon?: boolean;
	title?: React.ReactNode;
	className?: string;
};

const ActionSheet = forwardRef<HTMLDivElement, Props>((props, ref) => {
	const {
		children,
		visible,
		hasOverlay,
		onCancel,
		showCloseIcon,
		title,
		className,
	} = props;

	const isRTL = useReactiveVar(isRTLVar);

	const container = useRef<HTMLDivElement>(null);

	const height = container.current?.offsetHeight;

	const [visibility, setVisibility] = useState(false);

	useEffect(() => {
		setVisibility(visible);
		pullToRefreshDisabledVar(visible);
	}, [visible]);

	useEffect(() => {
		if (!hasOverlay) {
			const appRoot = document.getElementById("main-app-element");

			if (height && appRoot && visibility) {
				appRoot.style.paddingBottom = `${height}px`;
				window.scrollTo({
					top: window.scrollY + height,
					behavior: "smooth",
				});
			} else if (appRoot) {
				appRoot.style.paddingBottom = "";
			}
		} else {
			document.body.style.overflow = visibility ? "hidden" : "";
		}
	}, [visibility, height, hasOverlay]);

	const [isOverlayClickable, setOverlayClickable] = useState(false);

	const handleOverlayClick = () => {
		if (hasOverlay && isOverlayClickable) {
			if (!onCancel) {
				setVisibility(false);
			} else {
				document.body.style.overflow = "";
				onCancel();
			}
		}
	};

	const handleCloseClick = () => {
		if (!onCancel) {
			setVisibility(false);
		} else {
			document.body.style.overflow = "";
			onCancel();
		}
	};

	useEffect(
		() => () => {
			const appRoot = document.getElementById("main-app-element");

			if (appRoot) {
				appRoot.style.paddingBottom = "";
			}
			document.body.style.overflow = "";
		},
		[]
	);

	const handleTransitionEnd = () => {
		console.log("transition end");
		setOverlayClickable(visible);
	};

	return (
		<div
			onTouchStart={(e) => {
				e.stopPropagation();
				e.preventDefault();
			}}
			onClick={handleOverlayClick}
			className={clsx(hasOverlay && visibility && css.overlay)}
			tabIndex={0}
			role="button"
		>
			<div
				ref={container}
				className={clsx(
					css.actionSheetRoot,
					visibility && css.visible,
					!!(title || showCloseIcon) && css.hasHeading
				)}
				onClick={(e) => e.stopPropagation()}
				tabIndex={0}
				role="button"
				onTransitionEnd={handleTransitionEnd}
				style={isRTL ? { direction: "rtl" } : {}}
			>
				<div className={css.inner}>
					{!!(title || showCloseIcon) && (
						<div
							className={clsx(
								css.heading,
								isRTL && css.headingRtl
							)}
						>
							<div className={css.title}>{title}</div>
							{showCloseIcon && (
								<UiElement
									className={css.closeIconRoot}
									onClick={handleCloseClick}
								>
									<CloseOutline />
								</UiElement>
							)}
						</div>
					)}
					<div ref={ref} className={clsx(css.content, className)}>
						{children}
					</div>
				</div>
			</div>
		</div>
	);
});

ActionSheet.displayName = "ActionSheet";

export default ActionSheet;
