import { gql } from '@apollo/client';

const UPLOAD_LESSON_MEDIA = gql`
    mutation UploadLessonMedia(
        $file: Upload!
        $spaceType: String!
        $fileSize: Int!
        $languages: [String!]!
    ) {
        uploadLessonMedia(
            file: $file
            spaceType: $spaceType
            fileSize: $fileSize
            languages: $languages
        ) {
            task {
                id
                status
            }
            errors
        }
    }
`;

export default UPLOAD_LESSON_MEDIA;