import React, { useCallback, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import {
	removeItemConfirmedVar,
	removeItemIdVar,
	//removeItemLoadingVar,
	removeItemSheetVisibleVar,
	downloadedVideoIsRemovingVar,
} from "../../App";
import { postMessageToRN } from "../@utility/react-native.utility";
import {
	VideoDownloaderStatus,
	VideosDownloadInfo,
} from "./video-downloader.types";

export const useRemoveDownloadedVideo = (
	id: string,
	videos: VideosDownloadInfo
) => {
	const removeConfirmed = useReactiveVar(removeItemConfirmedVar);
	const removeItemId = useReactiveVar(removeItemIdVar);
	const removeItemSheetVisible = useReactiveVar(removeItemSheetVisibleVar);

	const remove = () => {
		const videoInfo = videos.find((video) => video.id === id);

		if (videoInfo?.status === VideoDownloaderStatus.Completed) {
			postMessageToRN("removeDownloadedVideo", {
				uri: videoInfo.uri,
				id: id,
			});
		}
	};

	useEffect(() => {
		if (removeConfirmed && id && removeItemId === id) {
			remove();
		}
	}, [removeConfirmed, removeItemId, id]);

	const requestForDelete = useCallback(() => {
		removeItemIdVar(id);
		removeItemSheetVisibleVar(true);
		downloadedVideoIsRemovingVar(true);
	}, [id]);

	useEffect(() => {
		if (!removeItemSheetVisible) {
			downloadedVideoIsRemovingVar(false);
		}
	}, [removeItemSheetVisible]);

	return {
		requestForDelete,
	};
};
