import { Alert as AlertAnt } from 'antd';
import clsx from 'clsx';
import React, { ComponentProps } from 'react';
import { IconWrapper } from '../icons/Icon';
import css from "./Alert.module.css";

const Alert = (props: ComponentProps<typeof AlertAnt>) => {

  const { type = "info", className, description, ...other } = props;

  let message = undefined;
  let icon: "done" | "close" | undefined = undefined;

  switch (type) {
    case "success":
      message = "Success!";
      icon="done";
      break;
    case "warning":
      message = "Warning!";
      break;
    case "error":
      message = "Error!";
      icon="close";       
  }

  return (
    <AlertAnt
      className={clsx(css.root, css[type], className)}
      message={message ? <div className={css.title}>{message}</div> : message}
      type={type}
      closable
      closeIcon={<IconWrapper name="close" size={14} />}
      showIcon
      description={description ? <div className={css.description}>{description}</div> : description}
      icon={icon ? <div className={css.iconWrapper}><IconWrapper name={icon} color="#FFFFFF" size={14} /></div> : undefined}
      {...other}
    />
  );
};

export default Alert;