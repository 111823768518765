import { useMutation, useReactiveVar } from '@apollo/client';
import React, { useMemo } from 'react';
import { userInfoVar } from '../App';
import { UpgradeDBData } from '../graphQL/lesson/LessonTypes';
import UPGRADE_DB from '../graphQL/lesson/UpgradeDB';
import useErrorNotification from '../helper/hooks/useErrorNotification';
import Alert from '../theme/alert/Alert';
import Button from '../theme/button/Button';

const DatabaseMigration = () => {

  const [upgradeDBAction, { data, loading }] = useMutation<
    UpgradeDBData
  >(UPGRADE_DB);

  const dataForNotify = useMemo(() => data ? {
    result: !!data?.upgradeDB?.result,
    errors: data?.upgradeDB?.errors
  } : undefined, [data]);

  useErrorNotification(dataForNotify);

  const startUpgrading = () => {
    upgradeDBAction();
  };

  const userInfo = useReactiveVar(userInfoVar);

  const isAdmin = userInfo?.role === 'admin';

  if(!isAdmin) {
    return null;
  }

  return (
    <section>
      {data?.upgradeDB?.result && <Alert type="success">
        Database Migration is done successfully!
      </Alert>} 
      <Button disabled={!!(data?.upgradeDB?.result)} loading={loading} color="primary" onClick={startUpgrading}>
        Upgrade Database!
      </Button>
    </section>
  );

};

export default DatabaseMigration;