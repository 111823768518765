import React from 'react';
import { useHistory } from 'react-router-dom';

let timeout: NodeJS.Timeout;

const ReactNativeBackButton = () => {

  const history = useHistory();

  const [rnBackId, setRnBackId] = React.useState<string | undefined>();

  React.useEffect(() => {

    if (timeout) {
      clearInterval(timeout);
    }

    timeout = setInterval(() => {
      if (rnBackId !== (window as any).ReactNativePressBackButtonId) {
        setRnBackId((window as any).ReactNativePressBackButtonId);
      }
    }, 100);

    return () => {
      clearInterval(timeout);
    };
  }, [rnBackId]);

  React.useEffect(() => {
    if (rnBackId) {
      console.log("-rnBackId--", rnBackId);
      history.goBack();
    }
  }, [rnBackId]);

  console.log("-rnBackId-render-", rnBackId);

  return null;
};

export default ReactNativeBackButton;