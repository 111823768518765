import React from 'react';
import { IconProps } from '../IconsList';

const LogoIcon = (props: IconProps) => {

  const { color = "#58B368", className, width = 58, height = 49, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 58 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.2534 2.8966C16.2808 1.0566 12.7356 4.58326 11.5845 6.57659C5.32272 6.57659 2.68296 11.3299 2.1458 13.7066C-4.30016 23.3666 5.13853 34.8665 12.5054 34.4065C16.1887 41.5365 27.6993 41.5365 30.4619 41.9965C32.115 42.2718 35.5265 48.4365 39.4402 48.4365C41.9725 48.4365 42.6631 44.2965 43.1236 40.1565C45.8861 41.0765 48.6486 40.1565 49.7997 39.4665C63.8427 29.3466 56.4759 9.7966 52.3321 8.6466C49.9379 3.86261 45.5025 2.81995 43.584 2.89662C39.3481 -0.415376 34.6057 0.903267 32.764 1.97659C26.8706 -0.967405 22.6347 1.36326 21.2534 2.8966Z" fill="#58B368"/>
      <path d="M21.2534 2.8966C16.2808 1.0566 12.7356 4.58326 11.5845 6.57659C5.32272 6.57659 2.68296 11.3299 2.1458 13.7066C-4.30016 23.3666 5.13853 34.8665 12.5054 34.4065C16.1887 41.5365 27.6993 41.5365 30.4619 41.9965C32.115 42.2718 35.5265 48.4365 39.4402 48.4365C41.9725 48.4365 42.6631 44.2965 43.1236 40.1565C45.8861 41.0765 48.6486 40.1565 49.7997 39.4665C63.8427 29.3466 56.4759 9.7966 52.3321 8.6466C49.9379 3.86261 45.5025 2.81995 43.584 2.89662C39.3481 -0.415376 34.6057 0.903267 32.764 1.97659C26.8706 -0.967405 22.6347 1.36326 21.2534 2.8966Z" fill="url(#paint0_linear_3524_21623)" fillOpacity="0.3"/>
      <path d="M24.4892 25.6507H18.5051L15.7432 28.4126" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <circle cx="16.3412" cy="27.9523" r="1.15079" fill="white"/>
      <path d="M23.9366 16.9048H17.4922" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <circle r="1.15079" transform="matrix(1 0 0 -1 16.8017 16.9048)" fill="white"/>
      <path d="M19.4484 19.2064L24.627 19.2064" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <path d="M33.8335 24.9832L33.8335 31.3126L36.5954 34.0745" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <circle cx="36.1347" cy="33.476" r="1.15079" transform="rotate(-90 36.1347 33.476)" fill="white"/>
      <path d="M27.3887 25.9958L27.3887 31.1743" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <path d="M31.5317 25.9958L31.5317 31.1743" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <path d="M25.0874 26.341L25.0874 33.3608" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <circle r="1.15079" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 25.0875 33.9362)" fill="white"/>
      <path d="M34.4317 16.9048H40.4158L43.1777 14.1428" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <circle cx="42.8097" cy="14.6032" r="1.15079" transform="rotate(-180 42.8097 14.6032)" fill="white"/>
      <path d="M34.7538 25.6509H41.1982" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <circle r="1.15079" transform="matrix(-1 0 0 1 42.1192 25.6509)" fill="white"/>
      <path d="M39.242 23.3493L34.0635 23.3493" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <path d="M25.0874 16.4213L25.0874 11.2428L22.3255 8.48085" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <circle cx="22.7857" cy="8.61898" r="1.15079" transform="rotate(90 22.7857 8.61898)" fill="white"/>
      <path d="M31.5317 16.3293L31.5317 11.3809" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <path d="M33.8335 15.6388L33.8335 9.30945" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <circle r="1.15079" transform="matrix(-4.37114e-08 1 1 4.37114e-08 33.8334 8.61898)" fill="white"/>
      <path d="M8.19358 16.9048H12.1063L18.5507 23.3492H24.3047" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <path d="M7.8252 23.3492H12.6585" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <circle r="1.15079" transform="matrix(1 8.74228e-08 8.74228e-08 -1 7.59513 23.3492)" fill="white"/>
      <circle r="1.15079" transform="matrix(1 8.74228e-08 8.74228e-08 -1 12.6586 23.3492)" fill="white"/>
      <circle r="1.15079" transform="matrix(1 8.74228e-08 8.74228e-08 -1 7.59513 16.9048)" fill="white"/>
      <path d="M50.7727 25.6509H46.86L40.4156 19.2065H34.6616" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <path d="M51.0029 19.2065H46.1696" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <circle r="1.15079" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 51.3253 19.2065)" fill="white"/>
      <circle r="1.15079" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 46.1695 19.2065)" fill="white"/>
      <circle r="1.15079" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 51.3253 25.6509)" fill="white"/>
      <rect x="21.5557" y="13.3731" width="15.81" height="15.81" rx="4" fill="white"/>
      <rect x="21.5557" y="13.3731" width="15.81" height="15.81" rx="4" fill="url(#paint1_linear_3524_21623)" fillOpacity="0.3"/>
      <path d="M32.7828 19.1544H25.8911" stroke="#409C4F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M29.3369 17.9932V19.1545" stroke="#409C4F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.0634 19.1407C31.0634 22.1133 28.7432 24.5189 25.8843 24.5189" stroke="#409C4F" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M32.79 24.519C31.547 24.519 30.4421 23.8554 29.6479 22.8115" stroke="#409C4F" strokeLinecap="round" strokeLinejoin="round"/>
      <defs>
      <linearGradient id="paint0_linear_3524_21623" x1="58" y1="58.1032" x2="14.7302" y2="0.563494" gradientUnits="userSpaceOnUse">
      <stop/>
      <stop offset="1" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint1_linear_3524_21623" x1="-1.36884" y1="57.6411" x2="-12.749" y2="22.8244" gradientUnits="userSpaceOnUse">
      <stop stopColor="#359745"/>
      <stop offset="1" stopColor="#359745" stopOpacity="0"/>
      </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoIcon;