import { gql } from "@apollo/client";

export const ALL_REFERRALS_FIELDS_FRAGMENT = gql`
	fragment AllReferralFields on ReferralForAdmin {
		id
		productPrice
		commissionPercentage
		orderId
		createdAt
		affiliate {
			id
			title
			code
			status
			user {
				id
				fullname
				email
				phoneNumber
			}
		}
		referredUser {
			id
			fullname
			email
			phoneNumber
		}
	}
`;
