import React from 'react';
import { IconProps } from '../IconsList';

const CheckboxIcon = (props: IconProps) => {

  const { color = "#58B368", className, width = 17, height = 17, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2409_48033)">
        <circle cx="8.5" cy="8.5" r="8.5" fill={color} />
        <g filter="url(#filter0_d_2409_48033)">
          <circle cx="8.5" cy="8.5" r="3.5" fill="white" />
        </g>
      </g>
      <defs>
        <filter id="filter0_d_2409_48033" x="-15" y="-7" width="47" height="47" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2409_48033" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2409_48033" result="shape" />
        </filter>
        <clipPath id="clip0_2409_48033">
          <rect width="17" height="17" fill="white" />
        </clipPath>
      </defs>    </svg>
  );
};

export default CheckboxIcon;