import { gql } from "@apollo/client";

export const PAYMENT_REQUEST_AFFILIATE_FIELDS_FRAGMENT = gql`
	fragment PaymentRequestFields on PaymentRequestAffiliate {
		id
		userId
		affiliate
		requestedAmount
		status
		createdAt
		updatedAt
	}
`;
