import { useHistory } from "react-router-dom";
import i18n from "i18n-js";

export function useGoPremium() {
	const history = useHistory();

	const goPremium = () => {
		if (i18n.locale === "fa-IR") {
			history.push("/subscription-plans");
		} else if ((window as any).ReactNativeOpenPremuimModal) {
			(window as any).ReactNativeOpenPremuimModal();
		} else {
			alert("Please buy a subscription in your phone or tablet");
		}
	};

	return goPremium;
}
