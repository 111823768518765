import { gql } from "@apollo/client";

export const AFFILIATE_FIELDS_FRAGMENT = gql`
	fragment AffiliateFields on Affiliate {
		id
		user
		title
		code
		commissionPercentage
		bankInfo {
			bankName
			IBANNumber
			cardNumber
			accountHolder
		}
		totalEarnings
		totalWithdrawn
		invitedUsersCount
		referralCount
		status
		createdAt
		updatedAt
	}
`;
