import React from 'react';
import { IconProps } from '../IconsList';

const VideoFillIcon = (props: IconProps) => {

  const { color = "#58B368", className, width = 28, height = 28, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="9.5" y="8" width="7" height="6" fill="white" />
      <path d="M24.6746 7.19841C24.1963 6.94175 23.1929 6.67341 21.8279 7.63008L20.1129 8.84341C19.9846 5.21508 18.4096 3.79175 14.5829 3.79175H7.58293C3.59293 3.79175 2.04126 5.34341 2.04126 9.33341V18.6667C2.04126 21.3501 3.49959 24.2084 7.58293 24.2084H14.5829C18.4096 24.2084 19.9846 22.7851 20.1129 19.1567L21.8279 20.3701C22.5513 20.8834 23.1813 21.0467 23.6829 21.0467C24.1146 21.0467 24.4529 20.9184 24.6746 20.8017C25.1529 20.5567 25.9579 19.8917 25.9579 18.2234V9.77675C25.9579 8.10841 25.1529 7.44341 24.6746 7.19841ZM12.8329 13.2767C11.6313 13.2767 10.6396 12.2967 10.6396 11.0834C10.6396 9.87008 11.6313 8.89008 12.8329 8.89008C14.0346 8.89008 15.0263 9.87008 15.0263 11.0834C15.0263 12.2967 14.0346 13.2767 12.8329 13.2767Z" fill={color} />
    </svg>
  );
};

export default VideoFillIcon;