import { gql } from "@apollo/client";

export const ADMIN_COUPON_FIELDS_FRAGMENT = gql`
	fragment AdminCouponFields on AdminCoupon {
		id
		title
		code
		discount
		startDate
		expiryDate
		maxUsageCount
		currentUsageCount
		specificUser {
			id
			fullname
			email
			phoneNumber
		}
		specificAffiliate {
			id
			user
			title
			code
			commissionPercentage
			status
		}
		specificSubscriptionPlan {
			id
			name
			title
			amount
			duration
			unit
			status
		}
		type
		status
		discountType
		createdAt
		updatedAt
	}
`;
