import React from 'react';
import { IconProps } from '../IconsList';

const AvatarIcon = (props: IconProps) => {

  const { color = "#F7F7F7", className, width = 32, height = 36, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 32 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.00012207" y="0.757812" width="32" height="32" rx="16" fill="#CFCFCF" />
      <rect x="0.00012207" y="0.757812" width="32" height="32" rx="16" fill="url(#paint0_linear_2319_7369)" fillOpacity="0.2" />
      <g clipPath="url(#clip0_2319_7369)">
        <mask id="mask0_2319_7369" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="33">
          <rect x="0.00012207" y="0.757812" width="32" height="32" rx="16" fill={color} />
        </mask>
        <g mask="url(#mask0_2319_7369)">
          <path d="M16.0346 26.0165C19.7269 26.0165 22.7201 21.8707 22.7201 16.7565C22.7201 11.6424 19.7269 7.49664 16.0346 7.49664C12.3423 7.49664 9.34912 11.6424 9.34912 16.7565C9.34912 21.8707 12.3423 26.0165 16.0346 26.0165Z" fill="white" />
          <path d="M15.9285 25.4246C10.3311 25.4246 5.32827 26.7676 2.00012 28.8749C3.58064 31.114 5.674 32.9423 8.10535 34.2073C10.5367 35.4722 13.2354 36.137 15.9762 36.1461C18.7169 36.1552 21.42 35.5083 23.8596 34.2596C26.2993 33.0108 28.4048 31.1963 30.0001 28.9677C26.6826 26.8047 21.6081 25.4246 15.9285 25.4246Z" fill="white" />
          <path d="M17.0511 18.954H15.0128C13.6028 18.954 12.4597 20.0971 12.4597 21.5072V27.3169C12.4597 28.727 13.6028 29.8701 15.0128 29.8701H17.0511C18.4612 29.8701 19.6043 28.727 19.6043 27.3169V21.5072C19.6043 20.0971 18.4612 18.954 17.0511 18.954Z" fill="white" />
          <path d="M10.4027 17.2822C10.6044 18.7685 10.2727 20.0557 9.66756 20.1592C9.06244 20.2627 8.4069 19.1427 8.20519 17.6565C8.00348 16.1702 8.33524 14.8857 8.94301 14.7821C9.55078 14.6786 10.2037 15.7986 10.4027 17.2822Z" fill="white" />
          <path d="M21.6638 17.2822C21.4648 18.7685 21.7939 20.0557 22.4017 20.1592C23.0094 20.2627 23.6623 19.1427 23.8614 17.6565C24.0604 16.1702 23.7313 14.8857 23.1262 14.7821C22.5211 14.6786 21.8655 15.7986 21.6638 17.2822Z" fill="white" />
          <path d="M9.58002 16.393C9.35709 14.9102 9.13061 13.4275 8.90059 11.9448C8.81832 11.414 8.74135 10.8381 8.93775 10.3312C9.13415 9.82424 9.71538 9.41287 10.2276 9.60927C10.0232 8.5795 11.1645 7.8178 12.1597 7.48604C13.8192 6.87381 15.5972 6.65133 17.3563 6.83581C19.1251 7.03372 20.7662 7.85288 21.9876 9.14747C22.3705 9.55522 22.6667 10.0365 22.8581 10.5621C23.2005 11.5786 22.9829 12.6853 22.7626 13.7469L22.2318 16.2496C22.2071 16.4422 22.133 16.625 22.0168 16.7804C21.8098 17.0114 21.4595 17.0273 21.1489 17.0299L11.2043 17.0512C10.5806 17.0512 9.76581 16.8972 9.68353 16.2788" fill="white" />
        </g>
      </g>
      <mask id="mask1_2319_7369" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="33">
        <rect x="0.00012207" y="0.757812" width="32" height="32" rx="16" fill={color} />
      </mask>
      <g mask="url(#mask1_2319_7369)">
      </g>
      <defs>
        <linearGradient id="paint0_linear_2319_7369" x1="0.000122257" y1="1" x2="13.5001" y2="17" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_2319_7369">
          <rect width="30" height="31.4844" fill="white" transform="translate(0.00012207 3.75781)" />
        </clipPath>
      </defs>    
    </svg>
  );
};

export default AvatarIcon;