import React from 'react';
import { IconProps } from '../IconsList';

const UserVoiceIcon = (props: IconProps) => {

  const { color = "#263238", className, width = 22, height = 22, size } = props;

  return (
    <svg
      className={className}
      width={size || width}
      height={size || height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 7.78979V14.2249" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.5 5.64478V16.3548" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 3.5V18.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.5 5.64478V16.3548" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 7.78979V14.2249" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default UserVoiceIcon;