// Function to fetch user's country based on IP address
export async function getUserCountry() {
	try {
		// Fetch user's location based on IP
		const response = await fetch("https://get.geojs.io/v1/ip/country.json");
		const data = await response.json();

		/**
		 * {
				"ip": "2a01:4f9:c011:9fa7::1",
				"name": "Finland",
				"country": "FI",
				"country_3": "FIN"
			}
		 */

		return data.name;
	} catch (error) {
		console.error("Error fetching user country:", error);
		return null;
	}
}
